<script>
import { URL_PREVIEW } from '@dmant/ez-env-common';
import { mapState } from 'vuex';
import templateName from '@/filters/template-name';
import ezTempateList from '@/components/select-template/list.vue';

export default {
  name: 'EzfEmailTemplateSelect',
  props: {
    value: {},
    type: {
      type: Number,
      required: true,
    },
  },
  components: { ezTempateList },
  filters: { templateName },
  methods: {
    getPreviewURL(id) {
      const locale = this.funnel.locale.slice(0, 2);
      return `${URL_PREVIEW}/${id}/${locale}`;
    },
  },
  computed: {
    ...mapState('funnel', ['funnel']),
    query() {
      const { type } = this;
      return {
        // userId: this.currentUserId
        type,
        status: 1,
        sys: 1,
      };
    },
  },
};
</script>

<template>
  <ez-tempate-list :query="query" :limit="8" v-slot="{ templates }">
    <div class="add-email__templates">
      <div
        v-for="template in templates"
        :key="template.id"
        @click.self="$emit('input', template.id)"
        :title="template | templateName(funnel && funnel.locale)"
        class="add-email-card"
        :class="{
          'add-email-card--selected': value === template.id
        }"
      >
        <div
          class="add-email-card__img"
          :style="
            `background-image: url(${(template.meta && template.meta.preview) ||
              ''})`
          "
        ></div>
        <div class="add-email-card__footer">
          <a
            :href="getPreviewURL(template.id)"
            target="_blank"
            style="text-decoration: none;">
            <ez-button
              block
              icon="eye"
              size="small"
              type="primary"
            >
              {{ $t("builder.buttons.view") }}
            </ez-button>
          </a>
        </div>
      </div>
    </div>
  </ez-tempate-list>
</template>
