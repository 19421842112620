<script>
import { mapState } from 'vuex';
import templateNameFilter from '@/filters/template-name';
import webinarRoomPreview from '@/assets/webinar-room-preview.jpg';
import ezSplitTestingCreateVariation from './create-variation.vue';
import ezPageThumb from './thumb.vue';
import ezfBuilderItemSelectTemplate from './select-template.vue';

export default {
  name: 'EzfBuilderItemPageSingle',
  props: {
    canCreateVariation: Boolean,
    step: {
      type: Object,
      required: true,
    },
    isWebinarRooms: Boolean,
    editTitle: String,
  },
  filters: { templateNameFilter },
  components: {
    ezfBuilderItemSelectTemplate,
    ezSplitTestingCreateVariation,
    ezPageThumb,
  },
  computed: {
    ...mapState('funnel', ['funnel']),
    template() {
      return this.step.variations[0] && this.step.variations[0].template;
    },
    webinarRoomPreview() {
      return webinarRoomPreview;
    },
    thumb() {
      return (this.isWebinarRooms && this.webinarRoomPreview)
        || (this.template.meta && this.template.meta.preview)
        || '';
    },
  },
};
</script>

<template>
  <div class="page-object__body">
    <section v-if="template" class="test-item box box_bordered">
      <header class="test-item__header"
        v-if="$options.filters.templateNameFilter(funnel && funnel.locale)">
        <h3 class="test-item__title">
          {{ template | templateNameFilter(funnel && funnel.locale) }}
        </h3>
      </header>
      <div class="test-item__body">
        <ez-page-thumb
          :thumb="thumb"
          :is-webinar-rooms="isWebinarRooms"
          :edit-title="editTitle"
          @edit="$emit('edit-template')"
        />
      </div>
    </section>
    <ezf-builder-item-select-template
      v-else
      @select="$emit('select-template')"
      :step-type="step.attributes.type"
    />
    <ez-split-testing-create-variation v-if="canCreateVariation" :step="step" />
  </div>
</template>
