<script>
import InfiniteLoading from 'vue-infinite-loading';
import { mapState } from 'vuex';
import builderManager from '@/services/builderManager';
import templatePreview from '@/helpers/templatePreview';
import EzfEmpty from '@/components/empty/empty.vue';

export default {
  name: 'ezTempateList',
  props: {
    height: {
      type: Number,
      default: 172,
    },
    query: {
      type: Object,
      required: true,
    },
    infiniteId: {
      default: +new Date(),
    },
    limit: {
      type: Number,
      default: 9,
    },
  },
  components: { InfiniteLoading, EzfEmpty },
  data() {
    return {
      templates: [],
      error: null,
    };
  },
  computed: {
    ...mapState('funnel', ['funnel']),
    preloaderHeight() {
      if (this.templates.length) {
        return '100%';
      }
      return `${this.height - 100}px`;
    },
  },
  methods: {
    async loadMore($state) {
      const { limit } = this;
      const page = Math.floor(this.templates.length / limit) + 1;
      const result = await builderManager.getTemplatesByType(this.query, { limit, page });
      if (result.data.length) {
        for (const template of result.data) {
          if (!template.meta) {
            template.meta = {};
          }
          if (!template.meta.preview) {
            template.meta.preview = templatePreview(
              template.attributes,
              500,
              template.attributes['dt-update'],
              this.funnel && this.funnel.locale,
            );
          }
        }
        this.templates.push(...result.data);
      }
      if (result.data.length === limit) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
  watch: {
    infiniteId() {
      this.templates = [];
    },
  },
};
</script>

<template>
  <ez-scrollable
    :height="height"
    type="vertical"
    bar-position="right"
    class="scrollable-select-template"
  >
    <ez-message v-if="error" type="error" :text="error" />
    <template v-else>
      <slot v-bind:templates="templates" />
      <infinite-loading
        @infinite="loadMore"
        :identifier="infiniteId"
        force-use-infinite-wrapper=".scrollable-select-template"
      >
        <ez-preloader
          slot="spinner"
          :value="1"
          :stop="false"
          style="position: relative; margin-top: 40px; margin-bottom: 40px;"
          :style="{ height: preloaderHeight }"
        />
        <div slot="no-more"></div>
        <template slot="no-results">
          <span v-if="templates.length || !query.personal">&nbsp;</span>
          <ezf-empty v-else type="templates" style="margin-top: 75px">
            <template slot="title">
              {{ $t("builder.selectTemplate.empty.title") }}
            </template>
            <template slot="description">
              {{ $t("builder.selectTemplate.empty.description") }}
            </template>
          </ezf-empty>
        </template>
      </infinite-loading>
    </template>
  </ez-scrollable>
</template>
