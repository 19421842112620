import contactType from './contactType';
import currency from './currency';
import percent from './percent';
import transactionType from './transactionType';
import userTime from './userTime';

export default {
  'contact-type': contactType,
  currency,
  percent,
  'transaction-type': transactionType,
  'user-time': userTime,
};
