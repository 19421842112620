<script>
import dialog from './dialog.vue';

export default {
  name: 'EzfContactsOrderedProductsButton',
  props: ['value', 'id'],
  methods: {
    showOrderedProducts() {
      this.$modal.show(
        dialog,
        { id: this.id },
        {
          name: 'contacts-ordered-products',
          height: 'auto',
          width: 986,
          // draggable: true,
          // scrollable: true,
          classes: ['dialog', 'is-service-funnels'],
        },
      );
    },
  },
};
</script>

<template>
  <ez-button
    @click="showOrderedProducts"
    class="nowrap"
    v-if="value"
    :outline="true"
    type="default"
    block
  >
    <span> Show ({{ value }}) </span>
    <ez-icon name="eye" ratio="24" />
  </ez-button>
</template>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
