<script>
export default {
  name: 'EzfBuilderItemProductsNoProviderSelected',
};
</script>

<template>
  <div class="builder-list__select">
    <ez-select
      class="custom-product-select"
      :options="[]"
      disabled
      :placeholder="$t('builder.product.noProviderSelected')"
      :title="$t('builder.product.noProviderSelected')"
    />
  </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>
