<script>
import { createNamespacedHelpers } from 'vuex';
import { GET_TEMPLATE_URL } from '@/store/builder/getters.js';

const { mapGetters } = createNamespacedHelpers('builder');

export default {
  name: 'EzfTemplatePreviewButton',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([GET_TEMPLATE_URL]),
    previewUrl() {
      return this[GET_TEMPLATE_URL](this.id);
    },
  },
};
</script>

<template>
  <a :href="previewUrl" target="_blank">
    <ez-button icon="eye"></ez-button>
  </a>
</template>
