<script>
import { createNamespacedHelpers } from 'vuex';
import { CREATE_STEP, DELETE_STEP } from '@/store/builder/actions';
import { DOWNSELL } from '@/enums/stepTypes';
import showStepDialog from '../dialogs/step/dialog.js';
import ezBuilderStep from './step.vue';
import ezBuilderItemPage from '../items/page/page.vue';
import ezBuilderItemActions from '../items/actions/actions.vue';
import ezBuilderItemProduct from '../items/product/product-only.vue';
import ezBuilderItemFallback from '../items/fallback.vue';

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderStepUpsell',
  inject: ['isBuilder', 'isArchive'],
  props: {
    index: {
      type: Number,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    downsell: {
      type: Object,
      required: false,
    },
    plusBefore: {
      type: Boolean,
      default: false,
    },
    plusAfter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ezBuilderStep,
    ezBuilderItemPage,
    ezBuilderItemActions,
    ezBuilderItemProduct,
    ezBuilderItemFallback,
  },
  methods: {
    ...mapActions([CREATE_STEP, DELETE_STEP]),

    createDownsell() {
      showStepDialog({
        name: this.$t('builder.step.add.name.downsell'),
        slug: '',
        callback: (step) => this[CREATE_STEP]({ type: DOWNSELL, index: this.index + 1, ...step }),
      });
    },

    deleteDownsell() {
      return this[DELETE_STEP](this.downsell);
    },

    async deleteUpsell() {
      const { index, step } = this;
      await this[DELETE_STEP]({ index, step, сhild: this.downsell });
    },
  },
};
</script>

<template>
  <ez-builder-step
    :class="{
      'builder-col_with-button': isBuilder || isArchive
    }"
    :step-id="step.attributes.id"
  >
    <div
      v-if="isBuilder || isArchive"
      slot="plus"
      class="builder-col_with-button"
    >
      <div class="builder-col__side"></div>
    </div>
    <ez-builder-item-page
      name="upsell"
      :is-optional-step="true"
      :with-product="true"
      :step="step"
      @delete="deleteUpsell"
    />
    <ez-builder-item-actions
      v-if="isBuilder"
      :step="step"
      :index="index"
    />
    <ez-builder-item-product v-if="isBuilder" :step="step" />
    <template v-if="downsell">
      <ez-builder-item-page
        name="downsell"
        :is-optional-step="true"
        :with-product="true"
        :step="downsell.step"
        @delete="deleteDownsell"
      />
      <ez-builder-item-actions
        v-if="isBuilder"
        :step="downsell.step"
        :index="index"
      />
      <ez-builder-item-product v-if="isBuilder" :step="downsell.step" />

      <ez-builder-item-fallback
        v-if="isBuilder"
        :index="downsell.index"
        :step="downsell.step"
      />
    </template>
    <div class="builder__group-item" v-else-if="isBuilder">
      <ez-link
        class="builder-action builder-action_white"
        data-test="create-downsell"
        @click.prevent="createDownsell"
      >
        <span class="builder-action__hint">
          {{ $t("builder.step.create.downsell") }}
        </span>
        <ez-icon class="builder-action__icon" name="plus" size="20"></ez-icon>
      </ez-link>
    </div>
  </ez-builder-step>
</template>
