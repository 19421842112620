<script>
import { mapGetters } from 'vuex';
import webinarsManager from '@/services/webinarsManager';

export default {
  name: 'EzfSelectWebinar',
  props: ['label', 'value', 'readonly'],
  data() {
    return {
      webinars: [],
    };
  },
  computed: {
    ...mapGetters(['currentUserId']),
    webinar: {
      set(value) {
        this.$emit('input', value.id);
      },
      get() {
        return this.webinars.find((w) => w.id === this.value);
      },
    },
  },
  created() {
    this.loadWebinars();
  },
  methods: {
    async loadWebinars() {
      this.webinars = await webinarsManager.getWebinars(this.currentUserId);
    },
  },
};
</script>

<template>
  <ez-form-item :label="label">
    <ez-select
      :placeholder="$t('settings.webinarPlaceholder')"
      v-model="webinar"
      :options="webinars"
      :readonly="readonly"
      item-label="name"
    ></ez-select>
  </ez-form-item>
</template>
