const SINGLE_PAYMENT = 1;
const SUBSCRIPTION = 2;
const INSTALLMENT = 3;

export const types = {
  LEAD: 1,
  CUSTOMER: 2,
};

export default (value) => {
  if (value == null) {
    return null;
  }
  switch (value) {
    case SINGLE_PAYMENT:
      return 'Onetime payment';
    case SUBSCRIPTION:
      return 'Subscription';
    case INSTALLMENT:
      return 'Installment';
    default:
      return null;
  }
};
