<script>
import { createNamespacedHelpers } from 'vuex';
import { BRIDGE } from '@/enums/stepTypes';
import ezBuilderStep from './step.vue';
import ezBuilderStepPlus from './plus.vue';
import ezSortableSteps from './sortable.vue';
import ezStepBridge from './bridge.vue';

const { mapState } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderStepWithBridges',
  inject: ['isBuilder', 'isArchive'],
  props: {
    index: {
      type: Number,
      required: true,
    },
    stepId: {
      type: String,
      default: undefined,
    },
  },
  components: {
    ezSortableSteps,
    ezBuilderStep,
    ezBuilderStepPlus,
    ezStepBridge,
  },
  data() {
    return {
      ezStepBridge,
    };
  },
  computed: {
    ...mapState(['steps']),
    bridgePages() {
      const result = [];
      let index = this.index - 1;
      while (index >= 0) {
        const step = this.steps[index];
        if (BRIDGE === step.attributes.type) {
          result.unshift({ step, index });
          index--;
        } else {
          index = -1;
        }
      }
      return result;
    },
  },
};
</script>

<template>
  <div class="container-wrap">
    <template v-if="bridgePages.length">
      <ez-sortable-steps
        v-if="isBuilder"
        :steps="bridgePages"
        :component="ezStepBridge"
      />
      <ez-step-bridge
        v-else
        v-for="props in bridgePages"
        :key="props.step.attributes.id"
        v-bind="props"
      />
    </template>

    <ez-builder-step
      :class="{
        'builder-col_with-button': isBuilder || isArchive,
        'builder-col_with-bridges': true
      }"
      :step-id="stepId"
    >
      <template slot="plus">
        <ez-builder-step-plus v-if="isBuilder" :index="index" />
        <div v-else-if="isArchive" class="builder-col__side" />
      </template>
      <slot />
    </ez-builder-step>
  </div>
</template>

<style scoped>
.container-wrap {
  display: flex;
}
</style>
