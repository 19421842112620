<script>
import merge from 'lodash/merge';
import groupBy from 'lodash/groupBy';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
// import 'echarts/lib/component/toolbox';

import ezPreloader from '@dmant/ez-ui-components/src/components/preloader';
import EchartsVue from '@dmant/ez-lib/src/echarts/echartsVue.vue';
import EzfEmpty from '@/components/empty/empty.vue';

const defaultColors = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'];

function labelDataFormat(data) {
  const value = typeof data === 'object' ? data.value : data;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return '';
  return new Date(1000 * value).toISOString().substr(11, 8)
    .replace('0:0', '')
    .replace('00:00', '0') || 0;
}

export default {
  name: 'EzfChart',
  components: {
    EchartsVue,
    ezPreloader,
    EzfEmpty,
  },

  props: {
    data: {
      type: Object,
      default: () => ({
        line: [],
        timeline: [],
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    isZeroYgraph: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    scatter() {
      const { timeline } = this.data;
      const copy = JSON.parse(JSON.stringify(timeline));
      // grouping by the same coordinate [d[0], d[1]]
      const groupedDots = groupBy(copy, (d) => [d[0], d[1]]);
      const fixYDots = Object.values(groupedDots).reduce((res, groups) => {
        groups.forEach((item, i) => {
          item[1] += i * 0.4; // change Y coordinate in the same dots
          res.push(item);
        });
        return res;
      }, []);
      // grouping by type message
      const groupedTypes = groupBy(fixYDots, (d) => d[4][0]);
      return Object.entries(groupedTypes).map(([type, dots]) => ({
        name: `scatter-${type}`,
        dataType: type,
        type: 'scatter',
        z: 3,
        symbolSize(val) {
          if (val && !val[3]) {
            return 0;
          }
          return 15;
        },
        data: dots,
        animationDelay(idx) {
          return idx * 5;
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: this.options.color,
          color: dots[0][4][1],
          opacity: 1,
        },
      }));
    },
    defaultOptions() {
      return {
        tooltip: {
          axisPointer: {
            type: 'cross',
          },
          enterable: true,
          position(point) {
            return [point[0], point[1]];
          },
          padding: [15, 20, 25, 20],
          backgroundColor: 'rgba(255, 255, 255, 1);',
          textStyle: {
            color: '#5D6B7E',
          },
          extraCssText: 'box-shadow: 0px 2px 8px rgba(93, 107, 126, 0.24);min-height:88px;',
          formatter(params) {
            if (params.value && params.value[2]) {
              return `<span style="text-align:left;display:inline-block">
                ${params.value[2]}</span>`
                .replace(/(https?:\/\/[^\s]+)/g,
                  (url) => `<a target="_blank"
                    href="${url.replace(/<\/?[^>]+(>|$)/g, '')}">
                    ${url}
                    </a>`);
            }
            return '';
          },
        },
        grid: {
          left: 25,
          top: 25,
          right: 25,
          bottom: 45,
          containLabel: true,
        },
        // toolbox: {
        //   feature: {
        //     dataZoom: {
        //       yAxisIndex: 'none',
        //     },
        //     restore: {},
        //     saveAsImage: {},
        //   },
        // },
        xAxis: {
          type: 'value',
          boundaryGap: false,
          splitNumber: 10,
          axisLabel: {
            formatter: labelDataFormat,
          },
          axisPointer: {
            label: {
              formatter: labelDataFormat,
            },
          },
        },
        yAxis: {
          splitNumber: 20,
          minInterval: 1,
          boundaryGap: false,
          type: 'value',
          scale: true,
          axisPointer: {
            show: true,
            snap: true,
          },
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }, {
          type: 'slider',
          labelFormatter: labelDataFormat,
          left: '8%',
          right: '8%',
          start: 0,
          end: 100,
        }],
        series: [{
          data: this.data.line,
          type: 'line',
          // smooth: true,
          // smoothMonotone: 'x',
          sampling: 'average',
          lineStyle: {
            width: 3,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: this.options.color || defaultColors[0],
          },
          emphasis: {
            itemStyle: {
              borderWidth: 2,
              borderColor: this.options.color || defaultColors[0],
              color: this.options.color || defaultColors[0],
            },
          },
          animationDelay(idx) {
            return idx * 5;
          },
          symbolSize() {
            return 0;
          },
        },
        ...this.scatter,
        ],

        color: defaultColors,
        animation: false,
      };
    },

    GetOptions() {
      return merge(this.defaultOptions, this.options);
    },

    isShowChart() {
      return this.loading || !this.isZeroYgraph;
    },
  },
};
</script>

<template>
  <div class="chart">
    <slot name="header" />
    <echarts-vue v-if="isShowChart" ref="chart" :options="GetOptions" autoresize />
    <ezf-empty v-else type="chart" img-width="70" class="empty">
      <template slot="title">
        {{ $t("charts.empty.title") }}
      </template>
      <template slot="description">
        {{ $t("charts.empty.description") }}
      </template>
    </ezf-empty>
    <slot name="footer" />
    <ez-preloader v-if="loading" value :stop="false" class="preloader" />
  </div>
</template>

<style lang="scss" scoped>
@import "@dmant/ez-ui-components/src/components/core/src/_variables.scss";

.chart {
  position: relative;
  border-radius: $border-radius * 2;
  // box-shadow: 0px 5px 15px rgba(128, 149, 170, 0.25);
  // padding: $offset * 2;
  background-color: $color-white;
  min-height: 400px;

  .echarts {
    width: 100%;
  }

  .preloader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    // background: rgb(0, 0, 0, 0.3);
    width: auto;
    height: auto;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }
}
</style>
