<script>
import { hide } from '@/services/modal.js';
import { NAME } from './dialog.js';
import ezTempateSelect from './select.vue';

export default {
  name: 'EzfSelectTemplateDialog',
  components: { ezTempateSelect },
  props: {
    stepType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    close(template) {
      hide(NAME, template);
    },
  },
};
</script>

<template>
  <div clsas="choose-template-modal-test">
    <ez-dialog-header icon="file-invoice">
      {{ $t("builder.selectTemplate.dialogTitle") }}
    </ez-dialog-header>
    <ez-dialog-body @close="close" class="choose-template-modal-test__body">
      <ez-tempate-select @select="close" :step-type="stepType" />
    </ez-dialog-body>
  </div>
</template>
