<script>
import { URL_FUNNELS } from '@dmant/ez-env-common';
import { mapState } from 'vuex';

export default {
  name: 'EzfSettingsSectionShare',
  computed: {
    ...mapState('funnel', ['funnel']),
    url() {
      return `${URL_FUNNELS}/share/${this.funnel.shareHash}`;
    },
  },
  methods: {
    onCopy() {
      this.$notify({
        data: {
          type: 'success',
          content: this.$t('funnels.share.urlCopy.success'),
        },
      });
    },
    onError() {
      this.$notify({
        data: {
          type: 'error',
          content: this.$t('funnels.share.urlCopy.error'),
        },
      });
    },
  },
};
</script>

<template>
  <ez-row class="mb-4">
    <ez-col>
      <ez-box>
        <template slot="body">
          <div class="share-info mb-3">
            <ez-icon
              class="share-info__icon"
              name="share-alt"
              ratio="80"
              size="40"
              color="#555555"
            ></ez-icon>
            <div class="share-info__wrap">
              <div class="share-info__title">
                {{ $t("funnels.share.title") }}
              </div>
              <div class="share-info__description">
                {{ $t("funnels.share.description") }}
              </div>
            </div>
          </div>
          <ez-button-group>
            <ez-input :value="url" />
            <ez-button
              icon="clone"
              v-clipboard:copy="url"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            ></ez-button>
          </ez-button-group>
        </template>
      </ez-box>
    </ez-col>
  </ez-row>
</template>

<style>
</style>
