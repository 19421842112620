<script>
import stepComponents from '@/pages/funnel/step-components';
import props from './page-props';

export default {
  name: 'EzfBuilderItemPage',
  inject: ['stepComponent'],
  props,
  computed: {
    component() {
      return stepComponents.get(this.stepComponent);
    },
  },
};
</script>

<template>
  <component :is="component" v-bind="$props" v-on="$listeners" />
</template>
