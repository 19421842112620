import * as STEP_TYPES from '@/enums/stepTypes';
import TRIGGERS from '@/enums/actionTriggers';
import ACTION_TYPES from '@/enums/actionTypes';

export const TRIGGER_TITLES = {
  [TRIGGERS.USER_SUBMITTED_FORM]: 'userSubmittedForm',
  [TRIGGERS.KNOWN_USER_VISITED_PAGE]: 'knownUserVisitedPage',
  [TRIGGERS.ATTENDED_TO_WEBINAR]: 'attendedToWebinar',
  [TRIGGERS.REGISTERED_BUT_DID_NOT_ATTENDED]: 'registeredButDidNotAttended',
  [TRIGGERS.ATTENDED_AND_CLICK_ON_OFFER]: 'attendedAndClickOnOffer',
  [TRIGGERS.ATTENDED_AND_DID_NOT_CLICK_ON_OFFER]:
    'attendedAndDidNotClickOnOffer',
  [TRIGGERS.ATTENDED_AND_LEFT_BEFORE]: 'attendedAndLeftBefore',
  [TRIGGERS.ATTENDED_AND_STAY_UNTIL]: 'attendedAndStayUntil',
  [TRIGGERS.ATTENDED_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER]:
    'attendedAndStayUntilButDidNotClickOnOffer',
  [TRIGGERS.WATCHED_REPLAY]: 'watchedReplay',
  [TRIGGERS.WATCHED_REPLAY_AND_CLICK_ON_OFFER]: 'watchedReplayAndClickOnOffer',
  [TRIGGERS.WATCHED_REPLAY_AND_DID_NOT_CLICK_ON_OFFER]:
    'watchedReplayAndDidNotClickOnOffer',
  [TRIGGERS.WATCHED_REPLAY_AND_LEFT_BEFORE]: 'watchedReplayAndLeftBefore',
  [TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL]: 'watchedReplayAndStayUntil',
  [TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER]:
    'watchedReplayAndStayUntilButDidNotClickOnOffer',
  [TRIGGERS.USER_PURCHASED]: 'userPurchased',
  [TRIGGERS.KNOWN_USER_VISITED_PAGE_AND_DID_NOT_PURCHASED]:
    'knownUserVisitedPageAndDidNotPurchased',
  [TRIGGERS.USER_CLICKED_YES_LINK_PURCHASE]: 'userClickedYesLinkPurchase',
  [TRIGGERS.USER_CLICKED_NO_LINK]: 'userClickedNoLink',
  [TRIGGERS.USER_LEFT_PAGE]: 'userLeftPage', // AFTER LAUNCH
};

export const ACTION_TITLES = {
  [ACTION_TYPES.CONTACTS]: 'contacts',
  [ACTION_TYPES.MAIL_CHIMP]: 'Mailchimp',
  [ACTION_TYPES.GET_RESPONSE]: 'GetResponse',
  [ACTION_TYPES.QUENTIN]: 'Quentn',
  [ACTION_TYPES.KLICK_TIPP]: 'Klick-Tipp',
  [ACTION_TYPES.FOUR_LEADS]: '4Leads',
  [ACTION_TYPES.ACTIVE_CAMPAGIN]: 'ActiveCampaign',
  [ACTION_TYPES.EMAIL]: 'email',
};

// not used
// STEP_TYPES.BRIDGE_REDIRECT
// STEP_TYPES.WEBINAR_COUNTDOWN
// STEP_TYPES.OPTIN_AFTER_ORDERFORM_FORM
// STEP_TYPES.OPTIN_AFTER_FREE_WEBINAR_FORM

export const STEP_TRIGGERS_TABLE = {
  [STEP_TYPES.BRIDGE]: [TRIGGERS.KNOWN_USER_VISITED_PAGE],
  [STEP_TYPES.OPTINPAGE]: [
    TRIGGERS.USER_SUBMITTED_FORM,
    TRIGGERS.KNOWN_USER_VISITED_PAGE,
  ],
  [STEP_TYPES.WEBINAR_REGISTRATION]: [
    TRIGGERS.USER_SUBMITTED_FORM,
    TRIGGERS.KNOWN_USER_VISITED_PAGE,
  ],
  [STEP_TYPES.CONFIRMATION]: [TRIGGERS.KNOWN_USER_VISITED_PAGE],
  [STEP_TYPES.OPTIN_THANK_YOU]: [TRIGGERS.KNOWN_USER_VISITED_PAGE],
  [STEP_TYPES.WEBINAR_ROOM_CARD]: [
    TRIGGERS.ATTENDED_TO_WEBINAR,
    TRIGGERS.REGISTERED_BUT_DID_NOT_ATTENDED,
    TRIGGERS.ATTENDED_AND_CLICK_ON_OFFER,
    TRIGGERS.ATTENDED_AND_DID_NOT_CLICK_ON_OFFER,
    TRIGGERS.ATTENDED_AND_LEFT_BEFORE,
    TRIGGERS.ATTENDED_AND_STAY_UNTIL,
    TRIGGERS.ATTENDED_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
  ],
  [STEP_TYPES.WEBINAR_REPLAY]: [
    TRIGGERS.WATCHED_REPLAY,
    TRIGGERS.WATCHED_REPLAY_AND_CLICK_ON_OFFER,
    TRIGGERS.WATCHED_REPLAY_AND_DID_NOT_CLICK_ON_OFFER,
    TRIGGERS.WATCHED_REPLAY_AND_LEFT_BEFORE,
    TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL,
    TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
  ],
  [STEP_TYPES.FREE_WEBINAR_THANK_YOU]: [TRIGGERS.KNOWN_USER_VISITED_PAGE],
  [STEP_TYPES.SALES]: [TRIGGERS.KNOWN_USER_VISITED_PAGE],
  [STEP_TYPES.ORDER_FORM]: [
    TRIGGERS.KNOWN_USER_VISITED_PAGE,
    TRIGGERS.USER_PURCHASED,
    TRIGGERS.KNOWN_USER_VISITED_PAGE_AND_DID_NOT_PURCHASED,
  ],
  [STEP_TYPES.UPSELL]: [
    TRIGGERS.USER_CLICKED_YES_LINK_PURCHASE,
    TRIGGERS.USER_CLICKED_NO_LINK,
    // TRIGGERS.USER_LEFT_PAGE
  ],
  [STEP_TYPES.DOWNSELL]: [
    TRIGGERS.USER_CLICKED_YES_LINK_PURCHASE,
    TRIGGERS.USER_CLICKED_NO_LINK,
    // TRIGGERS.USER_LEFT_PAGE
  ],
  [STEP_TYPES.CANDITATUREFORM]: [
    TRIGGERS.USER_SUBMITTED_FORM,
    TRIGGERS.KNOWN_USER_VISITED_PAGE,
  ],
  [STEP_TYPES.ORDER_THANK_YOU]: [TRIGGERS.KNOWN_USER_VISITED_PAGE],
};

export const ACTIONS_WITH_AUTORESPONDER = [
  ACTION_TYPES.MAIL_CHIMP,
  ACTION_TYPES.GET_RESPONSE,
  ACTION_TYPES.QUENTIN,
  ACTION_TYPES.KLICK_TIPP,
  ACTION_TYPES.ACTIVE_CAMPAGIN,
  ACTION_TYPES.FOUR_LEADS,
];

const ACTIONS_ALL_CONTACTS = [
  ACTION_TYPES.CONTACTS,
  ...ACTIONS_WITH_AUTORESPONDER,
];

const ACTIONS_ALL = ACTIONS_ALL_CONTACTS; // [...ACTIONS_ALL_CONTACTS, ACTION_TYPES.EMAIL];

export const STEP_ACTIONS_TABLE = {
  [STEP_TYPES.BRIDGE]: ACTIONS_ALL,
  [STEP_TYPES.OPTINPAGE]: ACTIONS_ALL,
  [STEP_TYPES.WEBINAR_REGISTRATION]: ACTIONS_ALL,
  [STEP_TYPES.CONFIRMATION]: ACTIONS_ALL,
  [STEP_TYPES.OPTIN_THANK_YOU]: ACTIONS_ALL,
  [STEP_TYPES.WEBINAR_ROOM_CARD]: ACTIONS_ALL_CONTACTS,
  [STEP_TYPES.WEBINAR_REPLAY]: ACTIONS_ALL_CONTACTS,
  [STEP_TYPES.FREE_WEBINAR_THANK_YOU]: ACTIONS_ALL,
  [STEP_TYPES.SALES]: ACTIONS_ALL,
  [STEP_TYPES.ORDER_FORM]: ACTIONS_ALL,
  [STEP_TYPES.UPSELL]: ACTIONS_ALL,
  [STEP_TYPES.DOWNSELL]: ACTIONS_ALL,
  [STEP_TYPES.CANDITATUREFORM]: ACTIONS_ALL,
  [STEP_TYPES.ORDER_THANK_YOU]: ACTIONS_ALL,
};

export const TRIGGERS_WITH_OFFER = [
  TRIGGERS.ATTENDED_AND_CLICK_ON_OFFER,
  TRIGGERS.ATTENDED_AND_DID_NOT_CLICK_ON_OFFER,
  TRIGGERS.ATTENDED_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
  TRIGGERS.WATCHED_REPLAY_AND_CLICK_ON_OFFER,
  TRIGGERS.WATCHED_REPLAY_AND_DID_NOT_CLICK_ON_OFFER,
  TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
];

export const TRIGGERS_WITH_TIME = [
  TRIGGERS.ATTENDED_AND_LEFT_BEFORE,
  TRIGGERS.ATTENDED_AND_STAY_UNTIL,
  TRIGGERS.ATTENDED_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
  TRIGGERS.WATCHED_REPLAY_AND_LEFT_BEFORE,
  TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL,
  TRIGGERS.WATCHED_REPLAY_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
];
