<script>
import { mapState } from 'vuex';
import EzfTemplatePreviewButton from '@/components/template-preview-button.vue';
import templateNameFilter from '@/filters/template-name';

export default {
  name: 'EzfTemplateItem',
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('funnel', ['funnel']),
  },
  components: { EzfTemplatePreviewButton },
  filters: { templateNameFilter },
  methods: {
    select(template) {
      this.$emit('select', template);
    },
  },
};
</script>

<template>
  <div class="card-use">
    <div class="card-use__wrap">
      <div class="card-use__image"
           :style="`background-image: url(${(template.meta && template.meta.preview) || ''})`">
      </div>
      <div class="card-use__text-wrap">
        <div class="card-use__title">
          {{ template | templateNameFilter(funnel && funnel.locale) }}
        </div>
        <!-- <div class="card-use__description">Easiest way to sell products.</div> -->
      </div>
      <ez-button-group>
        <ez-button
          @click="select(template)"
          type="primary"
          block
          data-test="use-template"
        >
          {{ $t("builder.selectTemplate.useTemplate") }}
        </ez-button>
        <!-- <ez-button icon="eye"></ez-button> -->
        <ezf-template-preview-button
          class="split-template__set-visibility"
          :id="template.id"
          data-test="view"
        />
      </ez-button-group>
    </div>
  </div>
</template>
