<script>
/* eslint-disable import/no-webpack-loader-syntax */
import light from '!!raw-loader!@/assets/tooltips/tooltip-light.svg';
import dark from '!!raw-loader!@/assets/tooltips/tooltip-dark.svg';

const MAP = new Map([
  ['light', light],
  ['dark', dark],
]);

export default {
  props: {
    theme: {
      type: String,
      validate: (value) => MAP.has(value),
    },
    html: {
      type: String,
      required: true,
    },
  },
  computed: {
    tooltipElement() {
      const el = document.createElement('div');
      el.innerHTML = this.html;
      return el;
    },
    svg() {
      return MAP.get(this.theme);
    },
  },
};
</script>

<template>
  <ez-tooltip :html="tooltipElement" v-html="svg" />
</template>
