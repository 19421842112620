export default [
  {
    name: 'contact',
    sort: 'email',
    displayName: 'Contact',
    visible: false,
    sortable: true,
  },
  {
    name: 'funnel-completed',
    sort: 'completed',
    displayName: 'Completed funnel',
    visible: false,
    sortable: false,
  },
  {
    name: 'funnel-purchases',
    sort: 'purchases',
    displayName: 'Ordered Products',
    visible: true,
    sortable: true,
  },
  {
    name: 'dt-create',
    displayName: 'Signed Up',
    sort: 'dt-create',
    visible: true,
    sortable: true,
    formatter: 'user-time',
  },
  {
    name: 'first-name',
    displayName: 'First name',
    visible: false,
    sortable: true,
  },
  {
    name: 'last-name',
    displayName: 'Last name',
    visible: false,
    sortable: true,
  },
  {
    name: 'email',
    displayName: 'E-mail address',
    visible: false,
    sortable: true,
  },
  {
    name: 'phone',
    displayName: 'Phone',
    visible: false,
    sortable: true,
  },
  {
    name: 'country',
    displayName: 'Country',
    visible: false,
    sortable: true,
  },
  {
    name: 'page-visits',
    displayName: 'Page visits',
    visible: false,
    sortable: true,
  },
  {
    name: 'unique-page-visits',
    displayName: 'Unique page visits',
    visible: false,
    sortable: true,
  },
  {
    name: 'webinars-registered',
    displayName: 'Webinars registered (unique)',
    visible: false,
    sortable: true,
  },
  {
    name: 'funnel-purchases',
    displayName: 'Purchases',
    visible: false,
    sortable: true,
  },
  {
    name: 'memberships',
    displayName: 'Memberships',
    visible: false,
    sortable: true,
  },
  {
    name: 'completed-lessons',
    displayName: 'Completed lessons',
    visible: false,
    sortable: true,
  },
  {
    name: 'completed-courses',
    displayName: 'Completed courses',
    visible: false,
    sortable: true,
  },
  {
    name: 'activity-level',
    displayName: 'Activity level',
    visible: false,
    sortable: true,
    formatter: 'percent',
  },
  {
    name: 'type',
    displayName: 'Contact type',
    visible: false,
    sortable: true,
    formatter: 'contact-type',
  },
  {
    name: 'logins',
    displayName: 'Number of logins',
    visible: false,
    sortable: true,
  },
  {
    name: 'sessions',
    displayName: 'Number of sessions',
    visible: false,
    sortable: true,
  },
  {
    name: 'mails-received',
    displayName: 'Mails received',
    visible: false,
    sortable: true,
  },
  {
    name: 'webinars-attended',
    displayName: 'Webinars attended',
    visible: false,
    sortable: true,
  },
  {
    name: 'revenue',
    displayName: 'Revenue',
    visible: false,
    sortable: true,
    formatter: 'currency',
  },
  {
    name: 'optin-date',
    displayName: 'Optin date',
    visible: false,
    sortable: false,
    formatter: 'user-time',
  },
];
