<script>
export default {
  name: 'EzfBuilderPageTitle',
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<template>
  <div class="page-object__header-text">
    <h3 class="page-object__title" data-test="bridge-test-name">{{ title }}</h3>
    <div class="page-object__subtitle" data-test="bridge">{{ subtitle }}</div>
  </div>
</template>
