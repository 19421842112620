<template>
  <div>
    <ez-form-item
      v-for="(email, index) in emails"
      :key="index"
      solid
      :type="errors.has(`${name}-${index}`, dataVvScope) ? 'error' : null"
      :message="errors.first(`${name}-${index}`, dataVvScope)"
    >
      <ez-input
        :value="email"
        type="text"
        @input="input(index, $event)"
        :name="`${name}-${index}`"
        v-validate="`min:2|email${value ? '|required' : ''}`"
        :data-vv-as="dataVvAs"
        :data-vv-scope="dataVvScope"
        ref="input"
      />
      <ez-button
        v-if="index === emails.length - 1 && email"
        type="secondary"
        icon="plus"
        @click="plus(index + 1)"
      />
      <ez-button
        v-else-if="emails.length > 1"
        type="secondary"
        icon="minus"
        @click="minus(index)"
      />
    </ez-form-item>
  </div>
</template>

<script>
export default {
  name: 'EzfInputMultipleEmails',
  inject: ['$validator'],
  $_veeValidate: {
    validator: 'new',
  },
  props: ['name', 'value', 'dataVvAs', 'dataVvScope'],
  data() {
    return {
      emails: [],
    };
  },
  watch: {
    value: {
      handler(value) {
        this.emails = (value || '').split(',');
      },
      immediate: true,
    },
  },
  methods: {
    input(index, email) {
      this.emails[index] = email;
      this.$emit('input', this.emails.join(','));
    },
    minus(index) {
      this.emails.splice(index, 1);
      this.$emit('input', this.emails.join(','));
    },
    plus(index) {
      this.emails.splice(index, 0, '');
      this.$emit('input', this.emails.join(','));
      this.$nextTick(() => {
        this.$refs.input[index].focus();
      });
    },
  },
};
</script>

<style>
</style>
