<script>
import { mapState, mapActions } from 'vuex';
import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';
import { CREATE_STEP_PRODUCT, LOAD_PRODUCT_LIST } from '@/store/builder/actions';

export default {
  name: 'EzfBuilderItemProductSelect',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('builder', ['paymentProviderId', 'productList']),
    ...mapWaitingGetters({ loading: 'productList' }),
    options() {
      for (const row of this.productList || []) {
        if (row.id === this.paymentProviderId) {
          return row.data.map(({ id, name }) => ({
            id: String(id),
            label: name,
          }));
        }
      }
      return [];
    },
  },
  methods: {
    ...mapActions('builder', [CREATE_STEP_PRODUCT]),
    ...mapWaitingActions('builder', {
      load: { action: LOAD_PRODUCT_LIST, loader: 'productList' },
    }),
    async onChangeProduct(id) {
      await this[CREATE_STEP_PRODUCT]({
        step: this.step,
        product: {
          // id,
          // type: 'products',
          attributes: {
            'payment-provider-id': this.paymentProviderId,
            'ext-product-id': id,
            name: this.options.find((o) => o.id === id).label,
            price: 0,
            currency: 'EUR',
            // id,
            // name,
            // 'update-thank-you-page': update,
            // 'orderform-id': payment && payment['orderform-id'],
            // price: 123,
            // currency: 'EUR',
          },
        },
      });
    },
  },
  created() {
    if (!this.productList && !this.loading) {
      this.load();
    }
  },
};
</script>

<template>
  <div class="builder-list__select">
    <ez-select
      v-if="loading"
      class="custom-product-select"
      disabled
      :options="[]"
      :placeholder="$t('Loading…')"
    />
    <ez-select
      v-else
      class="custom-product-select"
      :placeholder="$t('builder.product.select')"
      track-by="id"
      item-label="label"
      :options="options"
      :allow-empty="false"
      @input="onChangeProduct"
      is-single
      :prop="{ openDirection: 'top' }"
    />
  </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>
