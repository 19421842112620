<script>
// groups
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupSales from '../groups/sales.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezStepSales from '../steps/sales.vue';

import ezStepOptinSales from '../steps/optin-sales.vue';

export default {
  name: 'EzfBuilderOptinSalespage',
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupSales,
    ezBuilderGroupThankYou,
    ezStepOptinSales,
    ezStepSales,
  },
};
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-step-optin-sales />
      <ez-step-sales />
    </ez-builder-group-funnel-core>

    <ez-builder-group-sales />

    <ez-builder-group-thank-you />
  </div>
</template>
