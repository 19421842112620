<script>
import { URL_REDIRECT } from '@dmant/ez-env-common';
import { mapState } from 'vuex';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

import tabRegIcon from '@/assets/webinar-links/tabs/registration.svg';
import tabReplayIcon from '@/assets/webinar-links/tabs/replay.svg';
import webinarsManager from '@/services/webinarsManager';
import NAME from './name.json';

export default {
  name: 'EzfFunnelWebinarLinksDialog',
  components: {},
  props: {
    webinar: Object,
  },
  data() {
    const tabs = [
      {
        id: 'registration',
        name: this.$t('webinarLinksDialog.tabs.registrationPage'),
        active: true,
        disabled: false,
        bg: tabRegIcon,
      },
      {
        id: 'replay',
        name: this.$t('webinarLinksDialog.tabs.webinarReplay'),
        active: false,
        disabled: !this.webinar.replay,
        bg: tabReplayIcon,
      },
    ];
    return {
      tabs,
      currentTab: tabs[0],

      box1: false,
      box2: false,

      allSessions: [],
      allReplays: [],
      selectedSession: {},
      selectedReplay: {},
    };
  },
  computed: {
    ...mapState('funnel', { selectedFunnel: 'funnel' }),

    funnelsList() {
      return [this.selectedFunnel];
    },

    directInvitationLink() {
      if (!this.selectedFunnel.id || !this.selectedSession.hash) {
        return '';
      }

      return `${URL_REDIRECT}/rw/${this.selectedFunnel.id}/${this.webinar.id}/ss/${this.selectedSession.hash}`;
    },
    emergencyLink() {
      return this.selectedFunnel.id ? `${URL_REDIRECT}/rw/${this.selectedFunnel.id}/${this.webinar.id}/now` : '';
    },
    testingLink() {
      return `${this.emergencyLink}?ezf=webinar-preview&debug=true`;
    },
    registrationLink() {
      return this.selectedFunnel.id
        ? `${URL_REDIRECT}/rw/${this.selectedFunnel.id}/${this.webinar.id}/register`
        : '';
    },
    webinarReplayLink() {
      if (!this.selectedFunnel.id || !this.selectedReplay.hash) {
        return '';
      }

      return `${URL_REDIRECT}/rw/${this.selectedFunnel.id}/${this.webinar.id}/replay/${this.selectedReplay.hash}`;
    },
    footerLink() {
      /*eslint-disable*/
      switch(this.currentTab.id) {
        case 'registration': return this.registrationLink;
        case 'replay': return this.webinarReplayLink;
      };
      /* eslint-enable */

      return '';
    },
  },
  mounted() {
  },
  created() {
    [this.currentTab] = this.tabs;
    this.getAllSessions();
  },
  methods: {
    getSessionOptions(sessions) {
      return sessions
        .filter((session) => session.schedule[0])
        .map((session) => ({
          hash: session.hash,
          label: moment(session['datetime-iso'])
            .tz(session.schedule[0].timezone)
            .format(this.$t('webinarLinksDialog.schedules.dateTimeFormat')),
          // datetimeIso: session['datetime-iso'],
          // datetimeLocal: moment(session['datetime-iso']).format(
          //   `${this.$t('webinarLinksDialog.schedules.dateTimeFormat')} (Z)`,
          // ),
        }));
    },
    async getAllSessions() {
      try {
        const sessions = await webinarsManager.getWebinarsSessions({
          webinarId: this.webinar.id,
          isReplay: false,
          limit: 5,
        });

        this.allSessions = this.getSessionOptions(sessions);
        if (this.allSessions.length > 0) {
          [this.selectedSession] = this.allSessions;
        }
      } catch (error) {
        console.log('Got error while fetching sessions hashes'); // eslint-disable-line no-console
        console.log(error.message); // eslint-disable-line no-console
      }
    },
    async getReplaysList() {
      if (this.allReplays.length > 0) {
        return false;
      }

      try {
        const sessions = await webinarsManager.getWebinarsSessions({
          webinarId: this.webinar.id,
          isReplay: true,
          limit: 5,
        });

        this.allReplays = this.getSessionOptions(sessions);
        if (this.allReplays.length > 0) {
          [this.selectedReplay] = this.allReplays;
        }
      } catch (error) {
        console.log('Got error while fetching replays hashes'); // eslint-disable-line no-console
        console.log(error.message); // eslint-disable-line no-console
      }

      return true;
    },
    close() {
      this.$modal.hide(NAME);
    },
    setActiveTab(tab) {
      if (tab.active || tab.disabled) {
        return false;
      }

      if (tab.id === 'replay') {
        this.getReplaysList();
      }

      this.currentTab = tab;

      this.tabs = this.tabs.map((ttab) => {
        ttab.active = ttab.id === tab.id;
        return ttab;
      });

      return true;
    },
    onTabClick() {
      /* eslint-disable-next-line */
      this.tabs.forEach((tab) => tab.active ? (this.currentTab = tab) : '');
    },
    onCopy() {
      this.$notify({
        data: {
          type: 'success',
          content: this.$t('funnels.notifications.urlCopy.success'),
        },
      });
    },
    onError() {
      this.$notify({
        data: {
          type: 'error',
          content: this.$t('funnels.notifications.urlCopy.error'),
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <ez-dialog-header>
      {{ $t("webinarLinksDialog.title") }}
    </ez-dialog-header>

    <ez-dialog-body @close="close">
      <div class="links-box">
        <div class="links-box__cards-wrap">
          <div
            class="links-box__card"
            :class="[
              tab.active ? 'is-active' : '',
              tab.disabled ? 'is-disabled' : '',
              `links-box__card_${tab.id}`
            ]"
            v-for="tab in tabs"
            :key="tab.id"
            :style="{ backgroundImage: `url(${tab.bg})` }"
            @click="setActiveTab(tab)"
          ></div>
        </div>
        <ez-tabs class="links-box__tabs" :options="tabs" @click="onTabClick" />
      </div>
      <div class="tabs-content">
        <div
          class="tabs-content__item"
          v-show="currentTab.id === 'registration'"
        >
          <ez-form-item :label="$t('webinarLinksDialog.funnel')">
            <ez-select
              v-model="selectedFunnel"
              class="select-readonly"
              :readonly="true"
              :options="funnelsList"
              :allow-empty="false"
              track-by="id"
              item-label="name"
            />
          </ez-form-item>
          <div class="panel-collapse">
            <ez-collapse v-model="box1" :time="0.3">
              <label
                class="panel-collapse__header direct-invitation-switch"
                slot="header"
                @click="box1 != box1"
              >
                <div class="panel-collapse__title">
                  {{ $t("webinarLinksDialog.directInvitation") }}
                </div>
                <ez-switch v-model="box1"></ez-switch>
              </label>
              <div class="panel-collapse__content" slot="content">
                <ez-form-item :label="$t('webinarLinksDialog.session')">
                  <ez-select
                    v-model="selectedSession"
                    :options="allSessions"
                    :allow-empty="false"
                    track-by="hash"
                    item-label="label"
                    :placeholder="$t('webinarLinksDialog.selectSession')"
                  />
                </ez-form-item>
                <ez-button-group>
                  <ez-input
                    v-model="directInvitationLink"
                    size="small"
                    @keypress.prevent
                  ></ez-input>
                  <ez-button
                    type="secondary"
                    icon="clone"
                    size="small"
                    v-clipboard:copy="directInvitationLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  />
                  <a
                    class="direct-invitation-link"
                    :href="directInvitationLink"
                    target="_blank"
                  >
                    <ez-button
                      type="secondary"
                      icon="external-link"
                      size="small"
                    ></ez-button>
                  </a>
                </ez-button-group>
              </div>
            </ez-collapse>
          </div>
          <div class="panel-collapse">
            <ez-collapse v-model="box2" :time="0.3">
              <label
                class="panel-collapse__header emergency-switch"
                slot="header"
              >
                <div class="panel-collapse__title">
                  {{ $t("webinarLinksDialog.emergencyLink") }}
                </div>
                <ez-switch v-model="box2"></ez-switch>
              </label>
              <div class="panel-collapse__content" slot="content">
                <ez-button-group>
                  <ez-input v-model="emergencyLink" size="small"></ez-input>
                  <ez-button
                    type="secondary"
                    icon="clone"
                    size="small"
                    v-clipboard:copy="emergencyLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  />
                  <a
                    class="emergency-link"
                    :href="emergencyLink"
                    target="_blank"
                  >
                    <ez-button
                      type="secondary"
                      icon="external-link"
                      size="small"
                    ></ez-button>
                  </a>
                </ez-button-group>
              </div>
            </ez-collapse>
          </div>

          <!-- TODO: make it sexy -->
          <br />
          <!-- Link to test the webinar -->
          <ez-form-item :label="$t('webinarLinksDialog.testingLinkLabel')">
            <ez-button-group class="align-items-end">
              <ez-input v-model="testingLink" size="small"></ez-input>
              <ez-button
                type="secondary"
                icon="clone"
                size="small"
                v-clipboard:copy="testingLink"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></ez-button>
              <a class="footer-link" :href="testingLink" target="_blank">
                <ez-button
                  type="secondary"
                  icon="external-link"
                  size="small"
                ></ez-button>
              </a>
            </ez-button-group>
          </ez-form-item>
          <div class="links-webinar-dialog-hint">
            {{ $t('webinarLinksDialog.testingLinkNotice') }}
          </div>
          <!-- /TODO -->

        </div>
        <div class="tabs-content__item" v-show="currentTab.id === 'replay'">
          <ez-form-item :label="$t('webinarLinksDialog.funnel')">
            <ez-select
              v-model="selectedFunnel"
              class="select-readonly"
              :readonly="true"
              :options="funnelsList"
              :allow-empty="false"
              track-by="id"
              item-label="name"
            ></ez-select>
          </ez-form-item>
          <ez-form-item :label="$t('webinarLinksDialog.session')">
            <ez-select
              v-model="selectedReplay"
              :options="allReplays"
              :allow-empty="false"
              track-by="hash"
              item-label="label"
              :placeholder="$t('webinarLinksDialog.selectSession')"
            />
          </ez-form-item>
        </div>
      </div>
    </ez-dialog-body>

    <ez-dialog-footer>
      <ez-form-item :label="$t('webinarLinksDialog.testingLinkLabelFooter')">
        <ez-button-group>
          <ez-input v-model="footerLink" size="small"></ez-input>
          <ez-button
            type="secondary"
            icon="clone"
            size="small"
            v-clipboard:copy="footerLink"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          ></ez-button>
          <a class="footer-link" :href="footerLink" target="_blank">
            <ez-button
              type="secondary"
              icon="external-link"
              size="small"
            ></ez-button>
          </a>
        </ez-button-group>
      </ez-form-item>
    </ez-dialog-footer>
  </div>
</template>

<style lang="scss">
@import "~@dmant/ez-ui-components/src/components/core/src/variables.scss";

.ez-select .is-readonly .multiselect__tags {
  background: #e4e9ed;
}

.links-webinar-dialog-hint {
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 19px;
  color: $color-gray-700;
  background-color: $color-background;
}

.links-box {
  &__card {
    &.is-disabled {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color-gray-200, 0.5);
        border-radius: $border-radius;
        cursor: not-allowed;
      }
    }
  }

  &__cards-wrap {
    display: flex;
    align-items: center;
    margin: -10px;
  }

  &__card {
    width: calc(100% / 3);
    height: 146px;
    margin: 10px;
    box-shadow: inset 0 0 0 1px $color-gray-200;
    border-radius: $border-radius;
    transition: box-shadow 0.2s;

    &.is-active {
      @each $name, $color in $brand-colors {
        .is-service-#{$name} & {
          box-shadow: inset 0 0 0 3px $color;
        }
      }
    }
  }

  &__tabs {
    margin-top: 16px;

    ::v-deep .tabs {
      &__nav {
        width: auto;
        margin: -10px;
      }

      &__item {
        width: calc(100% / 3);
        margin: 10px;
      }
    }
  }
}

.dialog-links {
  .dialog {
    &__footer {
      margin: 0 (-$offset * 2);
      padding: $offset * 2;
      border-top: none;
      background-color: $color-gray-100;
      border-radius: 0 0 $border-radius * 2 $border-radius * 2;
    }
  }

  .links-box {
    &__card {
      width: 50%;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__tabs {
      .tabs {
        &__item {
          width: 50%;
        }

        &__link {
          width: auto;
        }
      }
    }
  }

  .tabs-content {
    margin: 0 (-$offset * 2);
    border-top: 1px solid $color-gray-200;

    &__item {
      margin: 0 ($offset * 2);
      padding: $offset * 2 0 0;
    }
  }
}
</style>
