<script>
import settingsSectionFactory from '../../mixins/settings-section-factory';

export default {
  name: 'EzfSettingsSectionRedirect',
  mixins: [
    settingsSectionFactory('redirectUrl'),
  ],
};
</script>

<template>
  <ez-settings-fieldset
    :legend="$t('funnels.redirect.title')"
    :is-changed="isChanged"
    @discard="discard"
    @save="save"
  >
    <ez-row class="mb-4">
      <ez-col>
        <p class="panel-collapse__description">
          {{ $t("funnels.redirect.description") }}
        </p>
        <ez-input v-model="redirectUrl" />
      </ez-col>
    </ez-row>
  </ez-settings-fieldset>
</template>

<style>
</style>
