<script>
import TYPES from '@/enums/webinar_notifications';
import EzfFunnelWebinarNotificationsByType from './notifications-by-type';

export default {
  name: 'EzfFunnelWebinarNotifications',
  props: ['loading'],
  components: {
    EzfFunnelWebinarNotificationsByType,
  },
  data() {
    return {
      TYPES,
    };
  },
};
</script>

<template>
  <section class="webinar__notifications-list box box_bordered titled-section">
    <header class="titled-section__header">
      <h2 class="titled-section__title">
        {{ $t("builder.webinarNotificiations") }}
      </h2>
    </header>
    <ez-preloader v-if="loading" :value="true" :stop="false" />
    <div v-else class="titled-section__body">
      <section class="notification-group">
        <header class="notification-group__header">
          <div class="notification-group__icon">
            <ez-icon name="user-plus"></ez-icon>
          </div>
          <h3 class="notification-group__title">
            {{ $t("builder.webinarReminders") }}
          </h3>
        </header>
        <div class="notification-group__body">
          <ezf-funnel-webinar-notifications-by-type :type="TYPES.WELCOME">
            <template slot="title">
              {{ $t("builder.notification.welcomeMessage.title") }}
            </template>
            <template slot="subtitle">
              {{ $t("builder.notification.welcomeMessage.description") }}
            </template>
            <template slot="time">
              {{ $t("builder.notification.welcomeMessage.time") }}
            </template>
          </ezf-funnel-webinar-notifications-by-type>
          <ezf-funnel-webinar-notifications-by-type
            :type="TYPES.PRE_WEBINAR"
            time="01:00"
          >
            <template slot="title">
              {{ $t("builder.notification.pre.title") }}
            </template>
            <template slot="subtitle">
              {{ $t("builder.notification.pre.description") }}
            </template>
            <template slot="time" slot-scope="{ timeText }">
              {{ $t("builder.notification.pre.time", { time: timeText }) }}
            </template>
          </ezf-funnel-webinar-notifications-by-type>
          <ezf-funnel-webinar-notifications-by-type
            :type="TYPES.LAST_MINUTE"
            :with-time="true"
            :time-readonly="true"
            time="00:15"
          >
            <template slot="title">
              {{ $t("builder.notification.lastMinute.title") }}
            </template>
            <template slot="subtitle">
              {{ $t("builder.notification.lastMinute.description") }}
            </template>
            <template slot="time">
              {{ $t("builder.notification.lastMinute.time") }}
            </template>
          </ezf-funnel-webinar-notifications-by-type>
        </div>
      </section>
      <section class="notification-group">
        <header class="notification-group__header">
          <div class="notification-group__icon">
            <ez-icon name="video"></ez-icon>
          </div>
          <h3 class="notification-group__title">Live/Webinar</h3>
        </header>
        <div class="notification-group__body">
          <ezf-funnel-webinar-notifications-by-type
            :type="TYPES.POST_WEBINAR"
            :descending="true"
            time="00:00"
          >
            <template slot="title">
              {{ $t("builder.notification.post.title") }}
            </template>
            <template slot="subtitle">
              {{ $t("builder.notification.post.description") }}
            </template>
            <template slot="time" slot-scope="{ timeText }">
              {{ $t("builder.notification.post.time", { time: timeText }) }}
            </template>
          </ezf-funnel-webinar-notifications-by-type>
        </div>
      </section>
      <section class="notification-group">
        <header class="notification-group__header">
          <div class="notification-group__icon">
            <ez-icon name="video"></ez-icon>
          </div>
          <h3 class="notification-group__title">Replay</h3>
        </header>
        <div class="notification-group__body">
          <ezf-funnel-webinar-notifications-by-type
            :type="TYPES.LAST_CONNECTION"
            :descending="true"
            time="00:00"
          >
            <template slot="title">
              {{ $t("builder.notification.last.title") }}
            </template>
            <template slot="subtitle">
              {{ $t("builder.notification.last.description") }}
            </template>
            <template slot="time" slot-scope="{ timeText }">
              {{ $t("builder.notification.last.time", { time: timeText }) }}
            </template>
          </ezf-funnel-webinar-notifications-by-type>
        </div>
      </section>
    </div>
  </section>
</template>
