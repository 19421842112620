<template>
  <div
    class="errors-overview"
    :class="classList"
  >
    <div class="errors-overview__inner">
      <!-- header -->
      <div class="errors-overview-header">
        <div class="errors-overview-header__icon">
          <ez-icon
            name="exclamation-triangle"
            :ratio="48"
            :size="24"
          />
        </div>
        <div class="errors-overview-header__title">
          ERRORS
        </div>
        <button class="errors-overview-header__close" @click="onClose">
          <ez-icon
            name="times"
            :ratio="48"
            :size="16"
          />
        </button>
      </div>

      <!-- list -->
      <div class="errors-overview-list">
        <div
          v-for="(item, index) in builderErrors"
          :key="index"
          class="errors-overview-list__item"
          @mouseover="toggleClass(item, true)"
          @mouseleave="toggleClass(item, false)"
          @click.prevent="onItemClick(item)"
        >
          <!-- <pre>{{ item.step.attributes.id }}</pre><br> -->
          {{ item.message }}
          <span v-if="item.step">
            (Step – {{ item.step.attributes.name }})
          </span>
        </div>
      </div>
    </div>

    <!-- fixed-button -->
    <button class="errors-overview-button" @click="onOpenToggle">
      <ez-icon
        name="bell"
        :size="24"
        class="errors-overview-button__icon"
      />
      <div class="errors-overview-button__count">{{ builderErrors.length }}</div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GET_ERRORS } from '@/store/builder/getters';
import { SET_VISIBLE_ERROR_BUTTON } from '@/store/builder/mutations';

import scrollTo, { scroller } from 'vue-scrollto/src/scrollTo';

export default {
  name: 'EzfErrorsOverview',
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    ...mapGetters('builder', { builderErrors: GET_ERRORS }),
    classList() {
      return {
        'is-opened': this.isOpened,
      };
    },
  },
  methods: {
    ...mapMutations('builder', { setVisibleErrorButton: SET_VISIBLE_ERROR_BUTTON }),
    onClose() {
      this.isOpened = false;
    },
    onOpenToggle() {
      this.isOpened = !this.isOpened;
    },
    toggleClass({ selector }, active) {
      if (active) {
        document.querySelector(selector).classList.add('on-hover');
      } else {
        document.querySelector(selector).classList.remove('on-hover');
      }
    },
    onItemClick({ type, selector }) {
      switch (type) {
        case 'webinar':
          this.scrollToWebinar();
          break;
        case 'step':
          this.scrollToStep(selector);
          break;
        default:
          break;
      }
    },
    scrollToWebinar() {
      const { element, duration, options } = this.getScrollOptions('.layout', 'body');
      scrollTo(element, duration, options);
    },
    scrollToStep(selector) {
      const bodyScroll = scroller();
      const builderScroll = scroller();
      const {
        element: bodyEl, duration: bodyDur, options: bodyOpt,
      } = this.getScrollOptions(selector, 'body');
      const {
        element: builderEl, duration: builderDur, options: builderOpt,
      } = this.getScrollOptions(
        selector,
        '.ps-container.scrollable.builder__scrollable',
        { x: true, y: false },
      );
      bodyScroll(bodyEl, bodyDur, bodyOpt);
      builderScroll(builderEl, builderDur, builderOpt);
    },
    getScrollOptions(elementSelector, containerSelector, scrollOptions) {
      const element = document.querySelector(elementSelector);
      const container = document.querySelector(containerSelector);
      const windowCenter = document.documentElement.clientWidth / 2;
      const elementCenter = element.getBoundingClientRect().width / 2;
      const duration = 500;
      const options = {
        container,
        cancelable: false,
        offset: -(windowCenter - elementCenter - 140), // 140 = (width / 2) of sidebar
        ...scrollOptions,
      };
      return { element, duration, options };
    },
  },
  created() {
    this.setVisibleErrorButton(true);
  },
  destroyed() {
    this.setVisibleErrorButton(false);
  },
};
</script>

<style lang="scss" scoped>
.errors-overview {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  width: 280px;
  height: 100vh;
  transform: translateX(280px);
  transition: transform 0.3s;

  &__inner {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(128, 149, 170, 0.5);
    background-color: #fff;
  }

  &.is-opened {
    transform: translateX(0);
  }
}

.errors-overview-button {
  position: absolute;
  right: 100%;
  top: 50%;
  z-index: 2;
  display: flex;
  outline: none;
  border: none;
  border-radius: 10px 0 0 10px;
  width: 56px;
  height: 56px;
  box-shadow: 0 0 10px rgba(128, 149, 170, 0.5);
  background-color: #fff;
  transition: background-color 0.2s;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: darken(#fff, 2%);
  }

  &:active {
    background-color: darken(#fff, 5%);
  }

  &__icon {
    margin: auto;
    color: $color-danger;
    transform: rotate(10deg);
  }

  &__count {
    position: absolute;
    left: 33px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding-top: 2px;
    width: 12px;
    height: 12px;
    box-shadow: 0 0 0 1px #fff;
    font-weight: 600;
    font-size: 8px;
    color: #fff;
    background-color: $color-danger;
  }
}

.errors-overview-header {
  display: flex;
  align-items: center;

  &__icon {
    flex-shrink: 0;
    color: $color-danger;
  }

  &__title {
    flex-grow: 1;
    font-weight: 500;
    font-size: 18px;
  }

  &__close {
    flex-shrink: 0;
    margin: 0;
    outline: none;
    border: none;
    border-bottom-left-radius: 4px;
    padding: 0;
    color: $color-text;
    background-color: transparent;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.errors-overview-list {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;

  &__item {
    border-radius: 4px;
    padding: 12px;
    font-size: 13px;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
