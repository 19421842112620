<template>
  <div class="panel-collapse__info">
    <div class="panel-collapse__title">
      {{ title }}
    </div>
    <div class="panel-collapse__description">
      {{ description }}
    </div>
    <div class="card-use mt-2">
      <div class="card-use__wrap">
        <div class="card-use__image">
          <img
            class="split-template__img"
            :src="image"
            alt
          />
        </div>
        <div class="card-use__text-wrap">
          <div class="card-use__title">
            {{ name }}
          </div>
          <div v-if="date" class="card-use__description">
            {{ date }}
          </div>
        </div>
        <ez-button-group>
          <ezf-template-edit-button
            :id="templateId"
            class="edit-template__button"
          />
          <ezf-template-preview-button
            :id="templateId"
          />
          <ezf-template-reset-button
            v-if="type === 'webinarLoginTemplate'"
          />
        </ez-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import EzfTemplatePreviewButton from '@/components/template-preview-button.vue';
import EzfTemplateEditButton from '@/components/template-edit-button.vue';
import EzfTemplateResetButton from '@/components/template-reset-button.vue';

export default {
  name: 'EzfSpecialPage',
  components: { EzfTemplatePreviewButton, EzfTemplateEditButton, EzfTemplateResetButton },
  props: {
    type: String,
    templateId: String,
    title: String,
    description: String,
    image: String,
    name: String,
    date: String,
  },
};
</script>
