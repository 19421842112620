<script>
import { createNamespacedHelpers } from 'vuex';

import { IS_INVALID_FUNEL } from '@/store/builder/getters';

// builders
import * as builderComponents from './builders';
import BuilderDefault from './builders/default.vue';

import BuilderLoaderSteps from './loader-steps.vue';
import BuilderInvalidFunnel from './invalid-funnel.vue';

const { mapState, mapGetters } = createNamespacedHelpers('builder');
const { mapState: funnelMapState } = createNamespacedHelpers('funnel');

export default {
  name: 'ezfFunnelBuilderMap',
  props: {
    stepComponent: {
      type: String,
      required: true,
    },
  },
  provide() {
    const isArchive = this.stepComponent === 'archive';
    return {
      stepComponent: this.stepComponent,
      isBuilder: this.stepComponent === 'builder',
      isArchive,
      withBuilderGroupHeaders: !isArchive,
    };
  },
  computed: {
    ...funnelMapState(['funnel']),
    ...mapState(['init', 'steps']),
    ...mapGetters([IS_INVALID_FUNEL]),
    funnelType() {
      return this.funnel && this.funnel.type;
    },
    component() {
      if (!this.funnelType) {
        return;
      }
      if (this.init !== this.$route.path || !this.steps) {
        return BuilderLoaderSteps;
      }
      if (this[IS_INVALID_FUNEL]) {
        return BuilderInvalidFunnel;
      }
      return builderComponents[this.funnelType] || BuilderDefault;
    },
  },
};
</script>

<template>
  <component
    class="builder__main"
    v-if="component"
    :is="component"
    :funnel="funnel"
    :steps="steps"
    v-on="$listeners"
  />
</template>
