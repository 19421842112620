<script>
import { mapState } from 'vuex';
import SelectProduct from './select.vue';
import SelectedProduct from './selected.vue';
import NoProviderSelected from './no-provider-selected.vue';

export default {
  name: 'EzfBuilderItemProductOnly',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    SelectProduct,
    SelectedProduct,
    NoProviderSelected,
  },
  computed: {
    ...mapState('builder', ['paymentProviderId']),
  },
  methods: {
  },
};
</script>

<template>
  <div class="builder__group-item">
    <div class="builder-list builder-list_yellow incompatible"
      :class="`step-products-${step.attributes.id}`">
      <div class="builder-list__header">
        <div class="builder-list__hint">
          {{ $t("builder.product.item") }}
        </div>
      </div>

      <div class="builder-list__body">
        <div class="builder-list__items">
          <selected-product v-if="step.product" :step="step" />
          <select-product v-else-if="paymentProviderId" :step="step" />
          <no-provider-selected v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>
<style lang="scss" src="../../incompatible.scss" scoped></style>
