<script>
import { createNamespacedHelpers } from 'vuex';
import { CREATE_UPSELL } from '@/store/builder/actions';
import showStepDialog from '../dialogs/step/dialog.js';

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderStepCreateUpsell',
  methods: {
    ...mapActions([CREATE_UPSELL]),
    createUpsellStep() {
      showStepDialog({
        name: this.$t('builder.step.add.name.upsell'),
        slug: '',
        callback: (step) => this[CREATE_UPSELL](step),
      });
    },
  },
};
</script>

<template>
  <div class="builder-col">
    <div class="builder__group-item">
      <ez-link
        class="builder-action builder-action_white"
        data-test="create-an-upsell"
        @click.prevent="createUpsellStep"
      >
        <span class="builder-action__hint">
          {{ $t("builder.step.create.upsell") }}
        </span>
        <ez-icon class="builder-action__icon" name="plus" size="20"></ez-icon>
      </ez-link>
    </div>
  </div>
</template>
