<script>
export default {
  name: 'EzfBuilderItemPageSelectTemplate',
};
</script>

<template>
  <ez-link
    class="builder-action builder-action_white builder-action_bordered"
    data-test="select-a-template"
    @click.prevent="$emit('select')"
  >
    <span class="builder-action__hint">
      {{ $t("builder.selectTemplate.button") }}
    </span>
    <ez-icon class="builder-action__icon" name="plus" size="20"></ez-icon>
  </ez-link>
</template>
