<script>
import { createNamespacedHelpers } from 'vuex';
import * as headerMutations from '@/store/header/mutations';
import * as funnelActions from '@/store/funnel/actions';
import { UNSET_FUNNEL } from '@/store/funnel/mutations';
import EzfFunnelArchived from './archived.vue';

const { mapMutations: headerMapMutations } = createNamespacedHelpers('header');
const { mapActions, mapMutations, mapState } = createNamespacedHelpers(
  'funnel',
);

export default {
  name: 'Funnel',
  components: {
    EzfFunnelArchived,
  },
  created() {
    // this[headerMutations.SET_HEADER_TABS_VISIBILITY](true);
    this[headerMutations.SET_ACTIVE_NAV_LINK](undefined);

    // this[headerMutations.SET_HEADER_CREATE_FUNNEL_VISIBILITY](false);

    const id = this.$route.params.funnel_id;
    this[funnelActions.LOAD_FUNNEL](id);
  },
  destroyed() {
    this[UNSET_FUNNEL]();
  },
  computed: {
    ...mapState(['funnel']),
    component() {
      if (this.funnel) {
        return this.funnel.archive ? 'ezf-funnel-archived' : 'router-view';
      }
      return undefined;
    },
  },
  methods: {
    ...headerMapMutations([
      headerMutations.SET_HEADER_TITLE,
      // headerMutations.SET_HEADER_TABS_VISIBILITY,
      headerMutations.SET_HEADER_TABS,
      headerMutations.SET_ACTIVE_NAV_LINK,
      headerMutations.SET_ACTIVE_NAV_TAB,
      // headerMutations.SET_HEADER_CREATE_FUNNEL_VISIBILITY
    ]),
    ...mapActions([funnelActions.LOAD_FUNNEL]),
    ...mapMutations([UNSET_FUNNEL]),
  },
  watch: {
    'funnel.name': {
      handler(name) {
        if (name) {
          this[headerMutations.SET_HEADER_TITLE]([
            { name: 'navbar.funnels', href: '/funnels' },
            {
              translated: true,
              name,
              // href: `/funnel/${this.funnel.id}/builder`
            },
          ]);
        }
      },
      immediate: true,
    },
    'funnel.archive': {
      handler(archive) {
        this[headerMutations.SET_HEADER_TABS](!this.funnel || archive ? [] : [
          {
            id: 'funnelBuilder', // route name
            name: 'funnel.tabs.build',
            route: {
              name: 'funnelBuilder',
              params: { funnel_id: this.$route.params.funnel_id },
            },
            params: 'build',
            dataTestAttr: 'build',
          },
          {
            id: 'funnelStatistics', // route name
            name: 'funnel.tabs.statistics',
            route: {
              name: 'funnelStatistics',
              params: { funnel_id: this.$route.params.funnel_id },
            },
            params: 'statistics',
          },
          {
            id: 'funnelContacts', // route name
            name: 'funnel.tabs.contacts',
            route: {
              name: 'funnelContacts',
              params: { funnel_id: this.$route.params.funnel_id },
            },
            params: 'contacts',
          },
          {
            id: 'funnelSettings', // route name
            name: 'funnel.tabs.settings',
            route: {
              name: 'funnelSettings',
              params: { funnel_id: this.$route.params.funnel_id },
            },
            params: 'settings',
            dataTestAttr: 'settings',
          },
        ]);
      },
      immediate: true,
    },
    '$route.name': {
      handler(value) {
        this[headerMutations.SET_ACTIVE_NAV_TAB](value);
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <component :is="component" />
</template>
