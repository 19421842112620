<script>
import settingsSectionFactory from './settings/mixins/settings-section-factory';

export default {
  name: 'EzfFunnelArchived',
  mixins: [
    settingsSectionFactory('archive'),
  ],
  methods: {
    restore() {
      this.archive = false;
      return this.save();
    },
  },
};
</script>

<template>
  <ez-info-box full-height>
    <div slot="image" class="error-image"></div>
    <template slot="title">
      {{ $t("funnel.archived.title") }}
    </template>
    <template slot="description">
      {{ $t("funnel.archived.description") }}
    </template>
    <template slot="button">
      <ez-button
        size="large"
        type="primary"
        @click.prevent="restore"
        :disabled="isSaving"
        :preloader="isSaving"
      >
        {{ $t("funnel.archived.restore") }}
      </ez-button>
    </template>
  </ez-info-box>
</template>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.error-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}
.error-image {
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 221px;
  &__svg {
    width: 100%;
    height: 100%;
  }
}
</style>
