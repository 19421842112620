export const ALL_REGISTRANTS = 1;
export const WHO_ATTENDED = 2;
export const WHO_DID_NOT_ATTEND = 3;
export const WHO_ATTENDED_AND_ORDERED_YOUR_PRODUCT = 4;
export const WHO_ATTENDED_AND_DID_NOT_ORDER_YOUR_PRODUCT = 5;
export const WHO_ATTENDED_BUT_LEFT_BEFORE_TIME = 6;
export const WHO_ATTENDED_STAYED_TO_TIME = 7;
export const WHO_ATTENDED_STAYED_TO_TIME_BUT_DID_NOT_PURCHASE = 8;

export const WATCHED_REPLAY = 9;
export const WATCHED_REPLAY_AND_PURCHASED = 10;
export const WATCHED_REPLAY_AND_DID_NOT_PURCHASED = 11;
export const WATCHED_REPLAY_AND_LEFT_BEFORE_TIME = 12;
export const WATCHED_REPLAY_AND_STAYED_UNTIL_TIME = 13;
export const WATCHED_REPLAY_AND_STAYED_UNTIL_TIME_BUT_NOT_PURCHASED = 14;

export const REPLAY_EVENTS = {
  WATCHED_REPLAY,
  WATCHED_REPLAY_AND_PURCHASED,
  WATCHED_REPLAY_AND_DID_NOT_PURCHASED,
  WATCHED_REPLAY_AND_LEFT_BEFORE_TIME,
  WATCHED_REPLAY_AND_STAYED_UNTIL_TIME,
  WATCHED_REPLAY_AND_STAYED_UNTIL_TIME_BUT_NOT_PURCHASED,
};

const EVENTS = {
  ALL_REGISTRANTS,
  WHO_ATTENDED,
  WHO_DID_NOT_ATTEND,
  WHO_ATTENDED_AND_ORDERED_YOUR_PRODUCT,
  WHO_ATTENDED_AND_DID_NOT_ORDER_YOUR_PRODUCT,
  WHO_ATTENDED_BUT_LEFT_BEFORE_TIME,
  WHO_ATTENDED_STAYED_TO_TIME,
  WHO_ATTENDED_STAYED_TO_TIME_BUT_DID_NOT_PURCHASE,
  WATCHED_REPLAY,
  WATCHED_REPLAY_AND_PURCHASED,
  WATCHED_REPLAY_AND_DID_NOT_PURCHASED,
  WATCHED_REPLAY_AND_LEFT_BEFORE_TIME,
  WATCHED_REPLAY_AND_STAYED_UNTIL_TIME,
  WATCHED_REPLAY_AND_STAYED_UNTIL_TIME_BUT_NOT_PURCHASED,
};

export default EVENTS;
