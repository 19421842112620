<script>
// import ezFilterExport from "@/components/filter-export";
// import filters from "./filters";
export default {
  name: 'EzfFunnelContactsHeader',
  // components: { ezFilterExport },
  props: {
    search: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    query: null,
  }),
  created() {
    this.query = this.search;
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.query);
    },
    handleExport() {
      this.$emit('export');
    },
  },
};
</script>

<template>
  <ez-sub-header class="mb-4">
    <template slot="title">
      <i18n path="showMe.text" tag="div">
        <span>{{ $t("showMe.contacts") }}</span>
      </i18n>
    </template>

    <template slot="custom">
      <ez-row align="center">
        <ez-col :size="[24, {md: 14}]" class="mt-4">
          <ez-form-item direction="end">
            <ez-input
              v-model="query"
              :placeholder="$t('Search')"
              type="search"
              @input="handleSearch"
              @change="handleSearch"
            />
          </ez-form-item>
        </ez-col>
        <ez-col :size="[24, {md: 10}]" class="mt-4">
          <ez-button-group justify="end">
            <ez-button icon="download" size="small" outline @click="handleExport">
              {{ $t("Export Contacts") }}
            </ez-button>
          </ez-button-group>
        </ez-col>
      </ez-row>
    </template>

<!--    <template slot="export">-->
<!--      <ez-filter-export-->
<!--        v-if="false"-->
<!--        title=""-->
<!--        colors="contacts"-->
<!--        :filters="filters"-->
<!--      />-->
<!--    </template>-->
  </ez-sub-header>
</template>
