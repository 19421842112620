<script>
import { ORDER_FORM } from '@/enums/stepTypes';
import ezBuilderStepWithBridges from './step-with-bridges.vue';
import ezBuilderItemPage from '../items/page/page.vue';
import ezBuilderItemActions from '../items/actions/actions.vue';
import ezBuilderItemProduct from '../items/product/product-with-provider.vue';
import stepMixinFactory from './mixins/step-factory';

export default {
  name: 'EzfBuilderStepOrderFrom',
  mixins: [stepMixinFactory(ORDER_FORM)],
  components: {
    ezBuilderStepWithBridges,
    ezBuilderItemPage,
    ezBuilderItemActions,
    ezBuilderItemProduct,
  },
};
</script>

<template>
  <ez-builder-step-with-bridges
    :index="index"
    :step-id="step.attributes.id"
  >
    <ez-builder-item-page name="orderform" :with-product="true" :step="step" />
    <ez-builder-item-actions
      v-if="isBuilder"
      :step="step"
      :index="index"
    />
    <ez-builder-item-product v-if="isBuilder" :step="step" />
  </ez-builder-step-with-bridges>
</template>
