<script>
// groups
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupSales from '../groups/sales.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezStepSales from '../steps/sales.vue';

export default {
  name: 'EzfBuilderSalesPage',
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupSales,
    ezBuilderGroupThankYou,
    ezStepSales,
  },
};

// salespage:
// S   15  Sales
// OF   8  Order Form
// US  16  Upsell:
// DS  17  Downsell
// TOF 12  Order Thank You
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-step-sales />
    </ez-builder-group-funnel-core>

    <ez-builder-group-sales />

    <ez-builder-group-thank-you />
  </div>
</template>
