<script>
import { createNamespacedHelpers } from 'vuex';
import ezSettingsSectionBasicInformation from './components/sections/basic-information.vue';
import ezSettingsSectionSmtpSettings from './components/sections/smtp-settings.vue';
import ezSettingsSectionTrackingSettings from './components/sections/tracking-settings.vue';
import ezSettingsSectionShare from './components/sections/share.vue';
import ezSettingsSectionRedirect from './components/sections/redirect.vue';
import ezSettingsSectionSpecialPages from './components/sections/special-pages.vue';

const { mapState } = createNamespacedHelpers('funnel');

export default {
  components: {
    ezSettingsSectionBasicInformation,
    ezSettingsSectionSmtpSettings,
    ezSettingsSectionTrackingSettings,
    ezSettingsSectionShare,
    ezSettingsSectionRedirect,
    ezSettingsSectionSpecialPages,
  },
  computed: mapState(['funnel']),
};
</script>

<template>
  <ez-content>
    <ez-main>
      <ez-container v-if="funnel">
        <ez-settings-section-basic-information />
        <ez-settings-section-smtp-settings />
        <ez-settings-section-tracking-settings />
        <ez-settings-section-share />
        <ez-settings-section-redirect />
        <ez-settings-section-special-pages />
      </ez-container>
    </ez-main>
  </ez-content>
</template>
