<script>
export default {
  name: 'ezfChangeTemplateButton',
};
</script>

<template>
  <a class="change-template-button" v-on="$listeners">
    {{ $t("builder.buttons.changeTemplate") }}
  </a>
</template>

<style scoped>
.change-template-button {
  margin-top: 0.625em;
  cursor: pointer;
  color: #ee5253;
  text-align: right;
  text-decoration-line: underline;
}
.change-template-button:hover {
  text-decoration-line: none;
}
</style>
