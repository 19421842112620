export const KNOWN_USER_VISITED_PAGE = 1;
export const USER_SUBMITTED_FORM = 2;
export const USER_PURCHASED = 3;
export const KNOWN_USER_VISITED_PAGE_AND_DID_NOT_PURCHASED = 4;
export const USER_CLICKED_YES_LINK_PURCHASE = 5;
export const USER_CLICKED_NO_LINK = 6;
export const USER_LEFT_PAGE = 7;
export const ATTENDED_TO_WEBINAR = 8;
export const REGISTERED_BUT_DID_NOT_ATTENDED = 9;
export const ATTENDED_AND_CLICK_ON_OFFER = 10;
export const ATTENDED_AND_DID_NOT_CLICK_ON_OFFER = 11;
export const ATTENDED_AND_LEFT_BEFORE = 12;
export const ATTENDED_AND_STAY_UNTIL = 13;
export const ATTENDED_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER = 14;
export const WATCHED_REPLAY = 15;
export const WATCHED_REPLAY_AND_CLICK_ON_OFFER = 16;
export const WATCHED_REPLAY_AND_DID_NOT_CLICK_ON_OFFER = 17;
export const WATCHED_REPLAY_AND_LEFT_BEFORE = 18;
export const WATCHED_REPLAY_AND_STAY_UNTIL = 19;
export const WATCHED_REPLAY_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER = 20;

export default {
  KNOWN_USER_VISITED_PAGE,
  USER_SUBMITTED_FORM,
  USER_PURCHASED,
  KNOWN_USER_VISITED_PAGE_AND_DID_NOT_PURCHASED,
  USER_CLICKED_YES_LINK_PURCHASE,
  USER_CLICKED_NO_LINK,
  USER_LEFT_PAGE,
  ATTENDED_TO_WEBINAR,
  REGISTERED_BUT_DID_NOT_ATTENDED,
  ATTENDED_AND_CLICK_ON_OFFER,
  ATTENDED_AND_DID_NOT_CLICK_ON_OFFER,
  ATTENDED_AND_LEFT_BEFORE,
  ATTENDED_AND_STAY_UNTIL,
  ATTENDED_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
  WATCHED_REPLAY,
  WATCHED_REPLAY_AND_CLICK_ON_OFFER,
  WATCHED_REPLAY_AND_DID_NOT_CLICK_ON_OFFER,
  WATCHED_REPLAY_AND_LEFT_BEFORE,
  WATCHED_REPLAY_AND_STAY_UNTIL,
  WATCHED_REPLAY_AND_STAY_UNTIL_BUT_DID_NOT_CLICK_ON_OFFER,
};
