<script>
import { slugify } from 'transliteration';
import { hide } from '@/services/modal.js';
import { NAME } from './dialog.js';

const MAX_SLUG_LEN = 100;

export default {
  name: 'EzfStepDialog',
  inject: ['$validator'],
  $_veeValidate: {
    validator: 'new',
  },
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    const { name, slug } = this;
    return {
      saving: false,
      form: {
        name,
        slug,
      },
      MAX_SLUG_LEN,
    };
  },
  methods: {
    close() {
      hide(NAME);
    },
    async submit() {
      const isValid = await this.$validator.validate();
      if (isValid) {
        try {
          this.saving = true;
          await this.callback(this.form);
          hide(NAME, this.form);
          this.$notify({
            data: {
              type: 'success',
              content: this.$t('builder.step.notify.succes'),
            },
          });
        } catch (e) {
          const { message, attributes = {} } = e;
          const msg = this.$t(`builder.step.errors.${message}`) || message;
          for (const [key, value] of Object.entries(attributes)) {
            this.errors.add({
              field: key,
              msg: this.$t(`builder.step.errors.${value}`) || value,
            });
          }
          this.$notify({
            data: {
              type: 'error',
              content: this.$t('notify.fail', {
                message: msg,
              }),
            },
          });
        } finally {
          this.saving = false;
        }
      }
    },
    slugify(value) {
      return slugify(value, { allowedChars: 'a-zA-Z0-9_' });
    },
    slugInput({ target }) {
      const { value, selectionStart, selectionEnd } = target;
      const slug = this.slugify(`${value}a`).slice(0, -1); // hack with "a" to allow enter "-" to end
      target.value = slug;
      const { length } = slug;
      target.selectionStart = Math.min(length, selectionStart);
      target.selectionEnd = Math.min(length, selectionEnd);
      this.form.slug = slug;
    },
  },
  mounted() {
    if (!this.isEdit) {
      const { name } = this.$refs.form.$el;
      name.select();
      name.focus();
    }
  },
  watch: {
    'form.name': {
      handler(value, oldValue) {
        const currentSlug = this.slugify(this.form.slug);
        if (currentSlug === '' || currentSlug === this.slugify(oldValue)) {
          this.form.slug = this.slugify(value).slice(0, MAX_SLUG_LEN);
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-form ref="form" @submit.native.prevent="submit" data-test="step-dialog">
    <ez-dialog-header>
      {{
        title ||
          (isEdit
            ? $t("builder.step.dialog.editTitle")
            : $t("builder.step.dialog.addTitle"))
      }}
    </ez-dialog-header>

    <ez-dialog-body @close="close">
      <ez-form-item
        :label="$t('builder.page.dialog.nameLabel')"
        :type="errors.has('name') ? 'error' : null"
        :message="errors.first('name')"
      >
        <ez-input
          name="name"
          :placeholder="$t('builder.step.add.name.placeholder')"
          v-model="form.name"
          v-validate="'required|max:255'"
          data-test="step-input-name"
        />
      </ez-form-item>
      <ez-form-item
        :label="$t('builder.page.dialog.slugLabel')"
        :type="errors.has('slug') ? 'error' : null"
        :message="errors.first('slug')"
      >
        <ez-input
          name="slug"
          v-model="form.slug"
          @input.native="slugInput"
          v-validate="{
            required: true,
            regex: /^[-a-z0-9_]+$/,
            max: MAX_SLUG_LEN,
          }"
          data-test="step-input-slug"
        />
      </ez-form-item>
    </ez-dialog-body>

    <ez-dialog-footer>
      <ez-button-group justify="end">
        <ez-button
          type="secondary"
          data-test="discard"
          @click="close"
        >
          {{ $t("builder.buttons.discard") }}
        </ez-button>
        <ez-button
          type="success"
          native-type="submit"
          data-test="create"
          @click="submit"
          :disabled="saving"
          :preloader="saving"
        >
          {{
            isEdit
              ? $t("builder.buttons.saveChanges")
              : $t("builder.buttons.create")
          }}
        </ez-button>
      </ez-button-group>
    </ez-dialog-footer>
  </ez-form>
</template>
