<script>
import { createNamespacedHelpers } from 'vuex';
import ezfSelectTempate from '@/components/select-template/select.vue';
import { DIALOG_SPLIT_TEST } from '@/store/builder/state';
import { SHOW_DIALOG, HIDE_DIALOG } from '@/store/builder/mutations';
import { ADD_VARIATION } from '@/store/builder/actions';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  'builder',
);

export default {
  name: 'EzfDialogSplitTest',
  components: { ezfSelectTempate },
  methods: {
    ...mapMutations([SHOW_DIALOG, HIDE_DIALOG]),
    ...mapActions([ADD_VARIATION]),
    setTemplate(template) {
      this[ADD_VARIATION]({ template, step: this.dialogStep });
      this.visible = false;
    },
  },
  computed: {
    ...mapState([DIALOG_SPLIT_TEST, 'dialogStep']),
    templates() {
      if (!this.dialogStep) {
        return [];
      }
      if (!this.dialogStep.variations) {
        return [this.dialogStep.template];
      }
      return this.dialogStep.variations.map((variation) => variation.template);
    },
    visible: {
      get() {
        return this[DIALOG_SPLIT_TEST];
      },
      set(value) {
        if (value) {
          this[SHOW_DIALOG](DIALOG_SPLIT_TEST);
        } else {
          this[HIDE_DIALOG](DIALOG_SPLIT_TEST);
          this.fromExistsVariations = true;
        }
      },
    },
  },
  data() {
    return { fromExistsVariations: true };
  },
};
</script>

<template>
  <ez-dialog
    class="new-split-test"
    :open.sync="visible"
    :size="fromExistsVariations ? 'big' : 'xbig'"
  >
    <template slot="header">
      <div class="modal-header">
        <ez-icon class="modal-header-icon" name="envelope"></ez-icon>
        <h2 class="modal-title">
          {{ $t("builder.createVariation.dialog.title") }}
        </h2>
      </div>
    </template>
    <div class="new-split-test__body">
      <div class="new-split-test__step" v-if="fromExistsVariations">
        <h3 class="new-split-test__title">
          {{ $t("builder.createVariation.dialog.how") }}
        </h3>
        <div class="new-split-test__elements">
          <div class="new-split-test__element">
            <div class="new-split-test__new-template">
              <p class="new-split-test__new-template-hint">
                {{ $t("builder.createVariation.dialog.new.text") }}
              </p>
              <ez-button
                class="new-split-test__new-template-select"
                type="success"
                size="small"
                data-test="select-new-template"
                @click.prevent="fromExistsVariations = false"
              >
                {{ $t("builder.createVariation.dialog.new.button") }}
              </ez-button>
            </div>
          </div>
          <div
            class="new-split-test__element"
            v-for="(template, index) in templates"
            :key="index"
          >
            <div class="new-split-test__template">
              <img class="new-split-test__template-thumb" :src="template.meta.preview" alt />
              <ez-button
                class="new-split-test__template-copy"
                size="small"
                data-test="copy"
                @click="setTemplate(template)"
              >
                {{ $t("builder.createVariation.dialog.copy.button") }}
              </ez-button>
            </div>
          </div>
        </div>
      </div>
      <ezf-select-tempate
        v-else
        @select="setTemplate"
        :step-type="dialogStep.attributes.type"
      >
        <template v-slot:header="{ stepTitle }">
          <ez-row class="split-templates__header-row" style="width: 100%">
            <ez-col :size="6">
              <ez-button
                icon="chevron-left"
                outline
                data-test="back"
                @click.prevent="fromExistsVariations = true"
              >
                {{ $t("builder.createVariation.dialog.back.button") }}
              </ez-button>
            </ez-col>
            <ez-col :size="12">
              <h3 class="split-templates__title">
                {{ $t("builder.selectTemplate.title", { type: stepTitle }) }}
              </h3>
            </ez-col>
            <ez-col :size="6"></ez-col>
          </ez-row>
        </template>
      </ezf-select-tempate>
    </div>
  </ez-dialog>
</template>
