<script>
import usersManager from '@/services/usersManager';

export default {
  name: 'EzfBuilderItemProductPaymentProvider',
  props: {
    step: {
      type: Object,
      required: true,
    },
    value: {},
  },
  data() {
    return {
      loading: true,
      paymentList: [],
    };
  },
  computed: {
    title() {
      if (this.value) {
        const product = this.paymentList.find((row) => row.id === this.value);
        if (product) {
          return product.name;
        }
        return this.$t('builder.product.selectedPaymentProviderIsNotAvailable');
      }
      return this.$t('builder.product.selectPaymentProvider');
    },
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const paymentList = [];
        const { data } = await usersManager.getPaymentList(this.$store.state.currentUserId);
        for (const row of data) {
          const { id } = row;
          const { name, type } = row.attributes;
          paymentList.push({
            id,
            name,
            type,
            'orderform-id': row.attributes['orderform-id'],
          });
        }
        this.paymentList = paymentList;
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: e.message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="builder-list__select">
    <ez-select
      v-if="loading"
      class="custom-product-select"
      disabled
      :options="[]"
      :placeholder="$t('Loading…')"
    />
    <ez-select
      v-else
      class="custom-product-select"
      track-by="id"
      item-label="name"
      :options="paymentList"
      :value="value"
      :searchable="false"
      :allow-empty="true"
      is-single
      :placeholder="$t('builder.product.selectPaymentProvider')"
      :title="title"
      v-on="$listeners"
      :prop="{ openDirection: 'top' }"
    />
  </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>
