<script>
import { createNamespacedHelpers } from 'vuex';
import { LOAD } from '@/store/notifications/actions.js';
import EzfFunnelWebinarHeader from './header.vue';
import EzfFunnelWebinarChange from './change.vue';
import EzfFunnelWebinarNotifications from './notifications/notifications.vue';

const { mapActions } = createNamespacedHelpers('notifications');

export default {
  name: 'EzfWebinarDetails',
  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },
  components: {
    EzfFunnelWebinarHeader,
    EzfFunnelWebinarChange,
    EzfFunnelWebinarNotifications,
  },
  data() {
    return {
      webinarPanelExpanded: Boolean(
        window.localStorage && localStorage.webinarPanelExpanded,
      ),
      loading: true,
    };
  },
  methods: {
    ...mapActions([LOAD]),
    async load() {
      try {
        this.loading = true;
        await this[LOAD]();
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('builder.notification.notify.failToLoad'),
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.load();
  },
};
</script>

<template>
  <ez-collapse
    class="webinar"
    :time="0.3"
    :class="{ webinar_expanded: webinarPanelExpanded }"
    v-model="webinarPanelExpanded"
  >
    <div
      class="funnel-webinar-box incompatible"
      slot="header"
    >
      <ezf-funnel-webinar-header
        header
        class="funnel-webinar-header"
        @toggle="webinarPanelExpanded = !webinarPanelExpanded"
        :loading="loading"
      />
    </div>
    <template slot="content">
      <div class="webinar__body box">
        <ezf-funnel-webinar-change :loading="loading" />
        <ezf-funnel-webinar-notifications :loading="loading" />
      </div>
    </template>
  </ez-collapse>
</template>

<style lang="scss" src="../incompatible.scss" scoped></style>
