<script>
// groups
import { FREE_WEBINAR_THANK_YOU } from '@/enums/stepTypes';
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezBuilderStepWebinarRegistration from '../steps/webinar-registration.vue';
import ezBuilderStepOptinThankYou from '../steps/optin-thank-you.vue';
import ezBuilderStepWebinar from '../steps/webinar.vue';
import ezBuilderStepThankYou from '../steps/thank-you.vue';

export default {
  name: 'EzfBuilderFreeWebinar',
  data() {
    return {
      FREE_WEBINAR_THANK_YOU,
    };
  },
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupThankYou,
    ezBuilderStepWebinarRegistration,
    ezBuilderStepOptinThankYou,
    ezBuilderStepWebinar,
    ezBuilderStepThankYou,
  },
};
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-builder-step-webinar-registration />
      <ez-builder-step-optin-thank-you />
      <ez-builder-step-webinar />
    </ez-builder-group-funnel-core>

    <ez-builder-group-thank-you>
      <ez-builder-step-thank-you :by-type="FREE_WEBINAR_THANK_YOU" />
    </ez-builder-group-thank-you>
  </div>
</template>
