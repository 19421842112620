import { show } from '@/services/modal';

export default function exportContactsDialog(funnelId) {
  return new Promise(((resolve) => {
    show(
      () => import(/* webpackChunkName: "contacts" */ './EzExportContacts.vue'),
      { funnelId },
      {
        name: 'export-contacts-dialog',
        clickToClose: false,
        height: 'auto',
        classes: ['dialog', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
