<script>
import { createNamespacedHelpers } from 'vuex';
import { BRIDGE } from '@/enums/stepTypes';
import { CREATE_STEP } from '@/store/builder/actions';
import showStepDialog from '../dialogs/step/dialog.js';

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderStepPlus',
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions([CREATE_STEP]),
    addBridgePage() {
      showStepDialog({
        name: this.$t('builder.step.add.name.bridge'),
        slug: '',
        callback: (step) => this[CREATE_STEP]({ type: BRIDGE, index: this.index, ...step }),
      });
    },
  },
};
</script>

<template>
  <div class="builder-col__side no-dnd">
    <button
      class="add-builder-col"
      @click="addBridgePage"
      :data-test="`step-add-${index}`"
    >
      <ez-icon name="plus" size="24" color="#999"></ez-icon>
    </button>
  </div>
</template>
