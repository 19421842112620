<script>
import { mapState } from 'vuex';
import { getStepTypeByNum } from '@/enums/stepTypes';
import ezTempateList from './list.vue';
import ezTempateItem from './item.vue';

export default {
  name: 'EzfTemplateSelect',
  props: {
    stepType: {
      type: Number,
      required: true,
      validator: (num) => Boolean(getStepTypeByNum(num)),
    },
  },
  components: { ezTempateList, ezTempateItem },
  data() {
    return {
      params: 'system',
      infiniteId: +new Date(),
      chooseTemplateTabs: [
        {
          name: this.$t('builder.selectTemplate.system'),
          active: true,
          params: 'system',
          dataTestAttr: 'default-template',
        },
        {
          name: this.$t('builder.selectTemplate.persanal'),
          active: false,
          params: 'persanal',
          dataTestAttr: 'saved-template',
        },
      ],
    };
  },
  computed: {
    ...mapState(['currentUserId']),
    query() {
      if (this.params === 'system') {
        return {
          status: 1,
          type: this.stepType,
          sys: 1,
        };
      }
      return {
        type: this.stepType,
        personal: 1,
        userId: this.currentUserId,
      };
    },
    stepTitle() {
      const { title } = getStepTypeByNum(this.stepType);
      return title;
    },
  },
  methods: {
    select(...args) {
      this.$emit('select', ...args);
    },
    onClickTab(item) {
      this.params = item.params;
      this.infiniteId += 1;
    },
  },
};
</script>

<template>
  <div class="choose-template-modal">
    <section class="choose-template-modal">
      <header class="choose-template-modal__header">
        <div class="choose-template-modal__subheader">
          <slot name="header" :step-title="stepTitle">
            <div class="choose-template-modal__title">
              {{ $t("builder.selectTemplate.title", { type: stepTitle }) }}
            </div>
          </slot>
          <div class="choose-template-modal__tabs">
            <ez-tabs :options="chooseTemplateTabs" @click="onClickTab" />
          </div>
        </div>
      </header>
      <div class="choose-template-modal__body">
        <ez-tempate-list
          :query="query"
          :height="600"
          :infinite-id="infiniteId"
          v-slot="{ templates }"
        >
          <ez-row class="pt-4" :gutter="16">
            <ez-col size="8" v-for="template in templates" :key="template.id">
              <ez-tempate-item :template="template" @select="select" />
            </ez-col>
          </ez-row>
        </ez-tempate-list>
      </div>
    </section>
  </div>
</template>
