<script>
import { mapGetters } from 'vuex';
import usersManager from '@/services/usersManager';

export default {
  name: 'EzfSelectSMTP',
  props: ['value'],
  computed: {
    ...mapGetters(['currentUserId']),
    selected: {
      set(value) {
        this.$emit('input', value && value.id);
      },
      get() {
        return this.options.find((row) => row.id === this.value);
      },
    },
  },
  data() {
    return {
      loading: true,
      options: [],
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const result = await usersManager.getSMTPList(this.currentUserId);
        this.options = result.data.map((row) => ({
          id: row.id,
          name: row.attributes.name,
        }));
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('smtp.failToLoad', { message: e.message }),
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.load();
  },
};
</script>

<template>
  <ez-select
    class="no-dnd"
    v-model="selected"
    :readonly="loading"
    :placeholder="loading ? $t('Loading…') : $t('smtp.placeholder')"
    item-label="name"
    :options="options"
  />
</template>
