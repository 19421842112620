<script>
import { ADD_ERROR, REMOVE_ERROR } from '@/store/builder/mutations';
import { mapMutations } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    selector: {
      type: String,
    },
  },
  computed: {
    errorId() {
      return `${this.step.attributes?.id}:${this.title}`;
    },
  },
  name: 'EzfBuilderItemIncomplete',
  methods: {
    ...mapMutations('builder', [ADD_ERROR, REMOVE_ERROR]),
  },
  mounted() {
    const { attributes } = this.step;
    this[ADD_ERROR]({
      message: this.title,
      type: 'step',
      id: attributes.id,
      errorId: this.errorId,
      selector: this.selector || `.step-page-${attributes.id}`,
    });
  },
  beforeDestroy() {
    this[REMOVE_ERROR](this.errorId);
  },
};
</script>

<template>
  <div class="builder-action builder-action_error" :id="`incomplete-step-${step.attributes.id}`">
    <div class="builder-action_error__wrap">
      <span class="builder-action__hint">{{ title }}</span>
      <ez-icon
        class="builder-action__icon"
        name="exclamation-triangle"
        size="20"
      ></ez-icon>
    </div>
  </div>
</template>

<style lang="scss">
.builder-action_error {
  $this: &;

  position: relative;
  transform: translateZ(0);
  transition: transform 0.2s;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.2s;
  }

  &__wrap {
    z-index: 5;
    display: flex;
    width: 100%;
  }

  &.on-hover {
    &::before {
      box-shadow: 0 0 0 8px rgba($color-danger, 0.6);
    }
  }

  .builder-action__hint {
    padding-top: 1px;
  }
}
</style>
