<script>
import { createNamespacedHelpers } from 'vuex';
import { DIALOG_SPLIT_TEST } from '@/store/builder/state';
import { SHOW_DIALOG_STEP } from '@/store/builder/mutations';

const { mapMutations } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderSplitTestingCreateVariation',
  props: {
    step: Object,
  },
  methods: {
    ...mapMutations([SHOW_DIALOG_STEP]),
    showSplitTestDialog() {
      this[SHOW_DIALOG_STEP]({ dialog: DIALOG_SPLIT_TEST, step: this.step });
    },
  },
};
</script>

<template>
  <div class="add-split-testing-elements">
    <ez-link
      class="add-split-testing-elements__link"
      data-test="create-a-variation"
      @click.prevent="showSplitTestDialog"
    >
      {{ $t("builder.createVariation.button") }}
    </ez-link>
  </div>
</template>
