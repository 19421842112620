<script>
import { mapState } from 'vuex';
import { getBuilderUrl } from '@/services/urls';

export default {
  name: 'EzfTemplateEditButton',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('funnel', ['funnel']),
    builderUrl() {
      return getBuilderUrl(this.funnel.id, this.id);
    },
  },
};
</script>

<template>
  <ez-link target="_blank" :href="builderUrl">
    <ez-button type="primary" block>
      {{ $t("settings.editPage") }}
    </ez-button>
  </ez-link>
</template>
