<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { CONFIRMATION } from '@/enums/stepTypes';
import {
  CREATE_STEP,
  DELETE_STEP,
  SAVE_STEP_ATTRIBUTES,
} from '@/store/builder/actions.js';
import EzfTooltip from '@/components/tooltip.vue';
import showStepDialog from '../dialogs/step/dialog.js';
import showConfirmationMailDialog from '../dialogs/confirmation-mail/dialog.js';
import ezBuilderItemPage from './page/page.vue';
import ezBuilderItemActions from './actions/actions.vue';
import ezBuilderItemIncomplete from './incomplete.vue';

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderConfirmation',
  props: {
    parentIndex: {
      type: Number,
      required: true,
    },
  },
  inject: ['isBuilder'],
  components: {
    ezBuilderItemPage,
    ezBuilderItemActions,
    ezBuilderItemIncomplete,
    EzfTooltip,
  },
  computed: {
    ...mapGetters(['lang']),
    index() {
      return this.$store.state.builder.steps.findIndex(
        (step) => Number(step.attributes.type) === CONFIRMATION,
      );
    },
    step() {
      return this.$store.state.builder.steps[this.index];
    },
    tooltipArticle() {
      const hrefs = {
        en: 'https://support.ezfunnels.com/en/support/solutions/articles/6000235923-double-optin-logic-with-klick-tipp/preview',
        de: 'https://support.ezfunnels.com/de/support/solutions/articles/6000235923-double-optin-logik-mit-klick-tipp',
      };
      return hrefs[this.lang] || hrefs.de;
    },
    incomplete() {
      return !this.step.attributes['mail-template-id'];
    },
  },
  methods: {
    ...mapActions([CREATE_STEP, DELETE_STEP, SAVE_STEP_ATTRIBUTES]),

    create() {
      showStepDialog({
        title: this.$t('builder.confirmation.addDialog.title'),
        name: this.$t('builder.confirmation.addDialog.defaultName'),
        slug: '',
        callback: (step) => this[CREATE_STEP]({
          type: CONFIRMATION,
          index: this.parentIndex + 1,
          ...step,
        }),
      });
    },
    deleteStep() {
      const { index, step } = this;
      this[DELETE_STEP]({ index, step });
    },
    editConfirmationMail() {
      const PROP_ATTR_MAP = [
        ['subject', 'mail-subject'],
        ['smtp', 'smtp-id'],
        ['template', 'mail-template-id'],
      ];
      const { step } = this;
      const props = {
        callback: (fields) => {
          const attributes = {};
          for (const [prop, attr] of PROP_ATTR_MAP) {
            const value = fields[prop] || '';
            attributes[attr] = value;
          }
          return this[SAVE_STEP_ATTRIBUTES]({ step, attributes });
        },
      };
      for (const [prop, attr] of PROP_ATTR_MAP) {
        props[prop] = step.attributes[attr] || '';
      }
      showConfirmationMailDialog(props);
    },
  },
};
</script>

<template>
  <div>
    <div v-if="step">
      <ez-builder-item-page
        class="page"
        name="confirmation"
        :is-optional-step="true"
        :step="step"
        @delete="deleteStep"
      />

      <ez-builder-item-actions
        v-if="isBuilder"
        :step="step"
        :index="index"
      />

      <div v-if="isBuilder" class="builder__group-item">
        <ez-builder-item-incomplete
          v-if="incomplete"
          class="mb-2"
          :step="step"
          :title="$t('builder.incomplete.noMailTemplateConnected')"
          :selector="`.step-mail-${step.attributes.id}`"
          data-test="no-mail-template-connected"
        />
        <ez-link
          :class="`step-mail-${step.attributes.id}`"
          class="builder-action builder-action_white builder-action_bordered incompatible"
          data-test="edit-confirmation"
          @click.prevent="editConfirmationMail"
        >
          <span class="builder-action__hint">
            {{ $t("builder.confirmation.editButton") }}
          </span>
          <ez-icon
            class="builder-action__icon"
            name="envelope"
            size="20"
          ></ez-icon>
        </ez-link>
      </div>
    </div>
    <div v-else-if="isBuilder" class="builder__group-item add">
      <ez-link
        class="builder-action builder-action_white"
        data-test="add-confirmation"
        @click.prevent="create"
      >
        <span class="builder-action__hint">
          {{ $t("builder.confirmation.addButton") }}
        </span>
        <span class="builder-action__hint-question">
          <ezf-tooltip
            :html="$t('builder.confirmation.tooltip', { href: tooltipArticle })"
            theme="dark"
          />
        </span>
        <ez-icon class="builder-action__icon" name="plus" size="20"></ez-icon>
      </ez-link>
    </div>
  </div>
</template>

<style lang="scss" src="../incompatible.scss" scoped></style>
<style lang="scss" scoped>
.page {
  margin-top: 24px;
}
.add {
  margin-top: 16px;
}
.builder-action {
  &__hint {
    width: 150px;
  }
}
</style>
