<script>
export default {
  name: 'EzfSettingsFieldset',
  props: {
    legend: {
      type: String,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isChanged: {
      type: Boolean,
    },
  },
};
</script>

<template>
  <ez-row class="mb-4">
    <ez-col>
      <ez-box>
        <template slot="header">
          <div class="box__header-group">
            <div class="legend">{{ legend }}</div>
          </div>
          <ez-button-group class="ml-auto" justify="end" v-if="isChanged">
            <ez-button
              type="secondary"
              @click="$emit('discard')"
              :disabled="isSaving"
              data-test="discard"
            >
              {{ $t("settings.discardButton") }}
            </ez-button>
            <ez-button
              type="success"
              @click="$emit('save')"
              :disabled="isSaving"
              :preloader="isSaving"
              data-test="save"
            >
              {{ $t("settings.saveButton") }}
            </ez-button>
          </ez-button-group>
          <ez-button-group class="ml-auto" justify="end" v-else>
            <ez-button type="secondary" outline disabled>
              {{ $t("settings.savedButton") }}
            </ez-button>
          </ez-button-group>
        </template>
        <template slot="body">
          <slot></slot>
        </template>
      </ez-box>
    </ez-col>
  </ez-row>
</template>
