<script>
import orderBy from 'lodash/orderBy';
import { createNamespacedHelpers } from 'vuex';
import contactsManager from '@/services/contactsManager';
import formatters from '@/services/formatters';

const { mapState } = createNamespacedHelpers('funnel');

export default {
  name: 'EzfContactsOrderedProductsDialog',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      loading: true,
      sort: {},
      purchases: [],
      columns: [
        {
          name: 'dt-create',
          displayName: 'Time',
          visible: true,
          sortable: true,
          formatter: 'user-time',
          formatterOptions: { timeFormat: 'DD.MM.YYYY LT' },
        },
        {
          name: 'product-name',
          displayName: 'Product',
          visible: true,
          sortable: true,
        },
        {
          name: 'amount',
          displayName: 'Amount',
          visible: true,
          sortable: true,
          formatter: 'currency',
        },
        {
          name: 'transaction-type',
          displayName: 'Transaction Type',
          formatter: 'transaction-type',
          width: 155,
          visible: true,
          sortable: true,
        },
        {
          name: 'transaction-id',
          displayName: 'Transaction ID',
          visible: true,
          sortable: true,
        },
        {
          name: 'source',
          displayName: 'Source',
          visible: true,
          sortable: true,
        },
      ],
      localizedFieldNames: {},
    };
  },
  computed: {
    ...mapState(['funnel']),
    sortedPurchases() {
      const ORDER = {
        ascending: 'asc',
        descending: 'desc',
      };
      const { order, prop } = this.sort;
      if (!prop) {
        return this.purchases;
      }
      return orderBy(this.purchases, `attributes.${prop}`, ORDER[order]);
    },
  },
  methods: {
    close() {
      this.$modal.hide('contacts-ordered-products');
    },
    async load() {
      this.loading = true;
      try {
        const { data } = await contactsManager.getStatisticPurchases({
          contactId: this.id,
          funnelId: this.funnel.id,
        });
        this.purchases = data;
      } catch {
        this.$notify({
          data: {
            type: 'error',
            content: 'Failed to load ordered products',
          },
        });
      } finally {
        this.loading = false;
      }
    },

    getContactFieldValue(attributes, field) {
      if (field.formatter && formatters[field.formatter]) {
        const formatter = formatters[field.formatter];
        return formatter(attributes[field.name], field.formatterOptions);
      }
      return attributes[field.name];
    },

    onSortChange(sort) {
      this.sort = sort;
    },

    getProductHref(attributes) {
      const paymentUrls = {
        ds24: `https://www.digistore24.com/product/${attributes['product-id']}`,
        elopage: `https://elopage.com/cabinet/transfers/${attributes['transaction-id']}`,
      };
      return paymentUrls[attributes['payment-type']];
    },
  },
  created() {
    this.load();
  },
};
</script>

<template>
  <div>
    <ez-dialog-header icon="shopping-cart">
      Ordered Products
    </ez-dialog-header>
    <ez-dialog-body @close="close">
      <ez-row v-if="loading">
        <ez-preloader
          :value="1"
          :stop="false"
          style="position: relative; margin-top: 40px; margin-bottom: 40px;"
        />
      </ez-row>
      <div v-else-if="!purchases || purchases.length === 0">
        {{ $t("orderedProducts.empty") }}
      </div>
      <div v-else>
        <ez-scrollable :height="600" type="vertical" bar-position="right">
          <ez-table
            class="table"
            :data="sortedPurchases"
            row-key="attributes.id"
            stripe
            @sort-change="onSortChange"
          >
            <ez-table-column
              v-for="(item, index) in columns"
              :sortable="item.sortable ? 'custom' : null"
              :min-width="item.width || 100"
              :key="index"
              :prop="item.name"
              :label="localizedFieldNames[item.name] || item.displayName"
            >
              <template slot-scope="scope">
                <template v-if="'product-name' === item.name">
                  <ez-link
                    target="_blank"
                    :href="getProductHref(scope.row.attributes)"
                  >
                    {{ getContactFieldValue(scope.row.attributes, item) }}
                  </ez-link>
                </template>
                <template v-else>
                  {{ getContactFieldValue(scope.row.attributes, item) }}
                </template>
              </template>
            </ez-table-column>
          </ez-table>
        </ez-scrollable>
      </div>
    </ez-dialog-body>
    <ez-dialog-footer>
      <ez-button-group justify="end">
        <ez-button type="primary" @click="close">
          Done
        </ez-button>
      </ez-button-group>
    </ez-dialog-footer>
  </div>
</template>
