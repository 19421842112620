<script>
/* eslint prefer-const: ["off"] */
import { createNamespacedHelpers } from 'vuex';
import debounce from 'lodash/debounce';
import confirmDelete from '@/components/dialogs/confirmDelete';
import exportContacts from '@/components/dialogs/exportContacts';
import EzfEmpty from '@/components/empty/empty.vue';
import contactsManager from '@/services/contactsManager';

import { UNSUBSCRIBED, CONFIRMED } from '@/enums/contactsSubscriptionStatus';

import { LIMITS, PAGINATION_LIMIT, PAGINATION_RANGE } from '@/enums/options';
import FunnelContactsTable from './table.vue';
import FunnelContactsHeader from './header.vue';

const { mapState } = createNamespacedHelpers('funnel');

export default {
  name: 'EzfFunnelContacts',
  components: {
    FunnelContactsHeader,
    FunnelContactsTable,
    EzfEmpty,
  },
  data() {
    return {
      loading: true,
      contacts: [],
      search: this.$route.query?.search,
      pageRange: PAGINATION_RANGE,
      limitOptions: LIMITS,
      totalPages: 1,
    };
  },
  computed: {
    ...mapState(['funnel']),
    query() {
      let {
        page, limit, order, sort,
      } = this.$route.query;

      page = page ? parseInt(page, 10) : 1;
      limit = limit ? parseInt(limit, 10) : PAGINATION_LIMIT;
      sort = sort || 'dt-create';
      order = order || (sort === 'dt-create' ? 'descending' : 'ascending');

      return {
        order, sort, limit, page,
      };
    },
  },
  methods: {
    async load() {
      this.$Progress.start();
      // this.loading = true;
      try {
        const { search } = this;
        const result = await contactsManager.getFunnelContacts({
          funnelId: this.funnel.id,
          ...this.query,
          search,
        });

        const { data, included } = result;
        for (const row of data) {
          const rel = row.relationships.funnels.data[0];
          const inc = included.find((r) => r.id === rel.id && r.type === rel.type);
          row.funnel = inc;
        }

        this.contacts = data;
        if (result.meta) {
          this.totalPages = result.meta.pages;
        }
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: e.message || e,
          },
        });
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }
    },
    async onExport() {
      exportContacts(this.funnel.id);
    },
    onSearch: debounce(function (value) {
      this.search = value;
      const query = { ...this.query, search: value };
      this.$router.replace({ query }).catch(() => ({}));
      this.load();
    }, 500),
    onSortChange(args) {
      const order = args.order || (this.query.order === 'ascending' ? 'descending' : 'ascending');
      const sort = args.sort || this.query.sort;
      const { search } = this;

      if (order !== this.query.order || sort !== this.query.sort) {
        const query = {
          ...this.query,
          order,
          sort,
          search,
        };
        this.$router.replace({ query }).catch(() => ({}));
        this.load();
      }
    },
    onPagesChange({ page, limit }) {
      const { search } = this;
      const query = {
        ...this.query,
        page,
        limit,
        search,
      };
      if (page !== this.query.page || limit !== this.query.limit) {
        this.$router.replace({ query }).catch(() => ({}));
        this.load();
      }
    },
    onColumnsMenuDisplayChange() {
    },

    async deleteSingleUserClick(item) {
      const isConfirmed = await confirmDelete('contact', item.attributes.email);
      if (!isConfirmed) {
        return;
      }

      try {
        await contactsManager.deleteContact(item.id);
        const index = this.contacts.indexOf(item);
        if (index === -1) {
          this.load();
        } else {
          this.contacts.splice(index, 1);
          const { page, limit } = this.query;
          if (this.contacts.length === 0 && page > 1) {
            this.onChange({ limit, page: page - 1 });
          }
        }
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('notify.fail', {
              message: e.message,
            }),
          },
        });
        console.error(e); // eslint-disable-line no-console
      } finally {
        this.$notify({
          data: {
            type: 'success',
            content: this.$t('contacts.notify.remove-success'),
          },
        });
      }
    },

    async subscribeSingleUserClick(item) {
      try {
        await contactsManager.subscribeContacts([item.id]);
        this.$set(item.attributes, 'subscribe-status', CONFIRMED);
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('notify.fail', {
              message: e.message,
            }),
          },
        });
        console.error(e); // eslint-disable-line no-console
      } finally {
        this.$notify({
          data: {
            type: 'success',
            content: this.$t('contacts.notify.subscribe-success'),
          },
        });
      }
    },

    async unsubscribeSingleUserClick(item) {
      try {
        await contactsManager.unsubscribeContacts([item.id]);
        this.$set(item.attributes, 'subscribe-status', UNSUBSCRIBED);
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('notify.fail', {
              message: e.message,
            }),
          },
        });
        console.error(e); // eslint-disable-line no-console
      } finally {
        this.$notify({
          data: {
            type: 'success',
            content: this.$t('contacts.notify.unsubscribe-success'),
          },
        });
      }
    },
  },
  watch: {
    'funnel.id': {
      handler(value) {
        if (value) {
          this.load();
        } else {
          this.ready = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-content>
    <ez-main>
      <ez-container>
        <ez-row class="mb-4">
          <ez-col>
            <ez-box>
              <ez-preloader
                v-if="loading"
                :value="true"
                :stop="false"
              />
              <ezf-empty
                v-else-if="!totalPages && !contacts.length && !search"
                type="contacts"
                style="margin-top: 50px;"
              >
                <template slot="title">
                  {{ $t("contacts.empty.title") }}
                </template>
                <template slot="description">
                  {{ $t("contacts.empty.description") }}
                </template>
              </ezf-empty>
              <template slot="body" v-else>
                <funnel-contacts-header
                  :search="search"
                  @search="onSearch"
                  @export="onExport"
                />
                <funnel-contacts-table
                  :contacts="contacts"
                  :sort="{ sort: query.sort, order: query.order }"
                  @sort-change="onSortChange"
                  @on-columns-menu-display-change="onColumnsMenuDisplayChange"
                  @subscribe-single-user-click="subscribeSingleUserClick"
                  @unsubscribe-single-user-click="unsubscribeSingleUserClick"
                  @delete-single-user-click="deleteSingleUserClick"
                />
                <ez-pagination
                  v-if="totalPages"
                  :page="query.page"
                  :page-count="totalPages"
                  :page-range="pageRange"
                  :limit="query.limit"
                  :limit-options="limitOptions"
                  @change="onPagesChange"
                />
              </template>
            </ez-box>
          </ez-col>
        </ez-row>
      </ez-container>
    </ez-main>
  </ez-content>
</template>
