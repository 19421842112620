<script>
export default {
  name: 'EzfBuilderStep',
  props: {
    stepId: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<template>
  <div class="builder-col">
    <div class="builder-col__items">
      <slot name="plus" />
      <div
        class="builder-col__main"
        :class="`step-${stepId}`"
        :data-id="stepId"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
