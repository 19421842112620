<script>
import { createNamespacedHelpers } from 'vuex';
import { slugify } from 'transliteration';
import { INIT, CREATE_STEP } from '@/store/builder/actions';
import {
  NOT_SUPPORTED_FUNNEL_TYPE,
  MISSING_STEPS,
} from '@/store/builder/getters';
import { getStepTypeByNum, DEFAULT_STEPS } from '@/enums/stepTypes.js';

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('builder');

export default {
  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isNotSupportedType: NOT_SUPPORTED_FUNNEL_TYPE,
      MISSING_STEPS,
    }),
    ...mapState(['steps']),
    missingSteps() {
      return this[MISSING_STEPS].map((type) => getStepTypeByNum(type));
    },
  },
  methods: {
    ...mapActions([INIT, CREATE_STEP]),
    async fix() {
      const { funnel, steps } = this;

      if (funnel && funnel.type && DEFAULT_STEPS[funnel.type]) {
        let index = 0;
        for (const type of DEFAULT_STEPS[funnel.type]) {
          const foundIndex = steps.findIndex(
            (step) => type === step.attributes.type,
          );
          if (foundIndex !== -1) {
            index = foundIndex + 1;
          } else {
            const name = getStepTypeByNum(type).title;
            const slug = slugify(name, { allowedChars: 'a-zA-Z0-9_' });
            // eslint-disable-next-line no-await-in-loop
            await this[CREATE_STEP]({
              index, type, name, slug,
            });
            index++;
          }
        }
      }
      await this[INIT](this.funnel);
      this.$router.replace({ query: {} });
    },
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'fix')) {
      this.fix();
    }
  },
};
</script>

<template>
  <ez-row class="invalid-funnel">
    <ez-col v-if="isNotSupportedType">
      <ez-message
        type="error"
        :text="
          $t('builder.error.notSupportedFunnelType', { type: funnel.type })
        "
      />
    </ez-col>
    <ez-col v-else>
      <ez-message
        type="error"
        :text="$t('builder.error.noRequiredStepsInTheFunnel')"
      />
      <ul v-for="step in missingSteps" :key="step.num">
        <li>{{ step.title }}</li>
      </ul>
    </ez-col>
  </ez-row>
</template>

<style scoped>
.invalid-funnel {
  min-width: 450px;
}
</style>
