<script>
// groups
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupSales from '../groups/sales.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezBuilderStepWebinarRegistration from '../steps/webinar-registration.vue';
import ezBuilderStepOptinThankYou from '../steps/optin-thank-you.vue';
import ezBuilderStepWebinar from '../steps/webinar.vue';

export default {
  name: 'BuilderWebinar',
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupSales,
    ezBuilderGroupThankYou,
    ezBuilderStepWebinarRegistration,
    ezBuilderStepOptinThankYou,
    ezBuilderStepWebinar,
  },
};
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-builder-step-webinar-registration />
      <ez-builder-step-optin-thank-you />
      <ez-builder-step-webinar />
    </ez-builder-group-funnel-core>

    <ez-builder-group-sales />

    <ez-builder-group-thank-you />
  </div>
</template>
