import { createNamespacedHelpers } from 'vuex';

import i18n from '@/i18n';

import * as funnelMutations from '@/store/funnel/mutations';
import * as funnelActions from '@/store/funnel/actions';
import * as funnelGetters from '@/store/funnel/getters';
import ezSettingsFieldset from '../components/fieldset';

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers('funnel');

export default function settingsSectionFactory(...attrs) {
  const computed = {
    attrs() {
      return attrs;
    },
    ...mapState(['funnel']),
    ...mapGetters([
      funnelGetters.GET_FUNNEL_CHANGED_ATTRIBUTE,
      funnelGetters.HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE,
    ]),
    isChanged() {
      return this[funnelGetters.HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE](this.attrs);
    },
  };
  for (const attr of attrs) {
    computed[attr] = {
      set(value) {
        this[funnelMutations.CHANGE_ATTRIBUTE]({
          attr,
          value,
        });
      },
      get() {
        return this[funnelGetters.GET_FUNNEL_CHANGED_ATTRIBUTE](attr);
      },
    };
  }

  const mixin = {
    data: () => ({
      isSaving: false,
    }),
    components: {
      ezSettingsFieldset,
    },
    methods: {
      ...mapMutations([
        funnelMutations.CHANGE_ATTRIBUTE,
        funnelMutations.RESET_ATTRIBUTES,
      ]),
      ...mapActions([funnelActions.SAVE_FUNNEL_ATTRS]),
      async save() {
        this.isSaving = true;
        try {
          await this[funnelActions.SAVE_FUNNEL_ATTRS](this.attrs);

          this.$notify({
            data: {
              type: 'success',
              content: i18n.t('notify.success'),
            },
          });
        } catch (e) {
          this.$notify({
            data: {
              type: 'error',
              content: i18n.t('notify.fail', {
                message: e.message,
              }),
            },
          });
          // show /data/attribute/* errors
          for (const error of e.response.data.errors) {
            const { pointer } = error.source;
            const PREFIX = '/data/attribute/';
            const msg = error.detail || error.title;
            if (pointer.startsWith(PREFIX)) {
              let field = pointer.substr(PREFIX.length);
              if (field === 'builder') {
                field = 'template-id';
              }

              this.errors.add({
                field,
                msg,
              });

              this.$notify({
                data: {
                  type: 'error',
                  content: `${field}: ${msg}`,
                },
              });
            } else {
              this.$notify({
                data: {
                  type: 'error',
                  content: `${pointer}: ${msg}`,
                },
              });
            }
          }
        } finally {
          this.isSaving = false;
        }
      },
      discard() {
        this[funnelMutations.RESET_ATTRIBUTES](attrs);
      },
    },
    computed,
  };

  return mixin;
}
