export const KLICK_TIPP = 1;
export const MAIL_CHIMP = 2;
export const GET_RESPONSE = 3;
export const ACTIVE_CAMPAGIN = 4;
export const QUENTIN = 5;
export const AWEBER = 6;
export const WEBINAR = 7;
export const EMAIL = 8;
export const CONTACTS = 9;
export const FOUR_LEADS = 10;

export default {
  KLICK_TIPP,
  MAIL_CHIMP,
  GET_RESPONSE,
  FOUR_LEADS,
  ACTIVE_CAMPAGIN,
  QUENTIN,
  AWEBER,
  WEBINAR,
  EMAIL,
  CONTACTS,
};

export const TYPES_MAP = new Map([
  [FOUR_LEADS, '4leads'],
  [KLICK_TIPP, 'klick_tipp'],
  [MAIL_CHIMP, 'mail_chimp'],
  [GET_RESPONSE, 'get_response'],
  [ACTIVE_CAMPAGIN, 'active_campagin'],
  [QUENTIN, 'quentin'],
]);
