<script>
import { createNamespacedHelpers } from 'vuex';
import selectTemplateDialog from '@/components/select-template/dialog.js';
import editTemplateDialog from '@/components/edit-template/dialog.js';
import {
  SAVE_STEP_ATTRIBUTES,
  ADD_VARIATION,
  SET_WINNER,
  ARCHIVE_VARIATION,
  SAVE_VARIATION_TEMPLATE,
  DETACH_VARIATION_TEMPLATE,
} from '@/store/builder/actions';
import { GET_STEP_URL } from '@/store/builder/getters.js';
import { WEBINAR_ROOM_CARD, WEBINAR_REPLAY, WEBINAR_COUNTDOWN } from '@/enums/stepTypes';
import showStepDialog from '../../dialogs/step/dialog.js';
import ezBuilderItemIncomplete from '../incomplete.vue';
import ezBuilderPageTitle from './title.vue';
import ezBuilderItemPageSplitTesting from './split-testing.vue';
import ezBuilderItemPageSingle from './single.vue';
import props from './page-props';

const { mapGetters, mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderItemPageContent',
  props,
  components: {
    ezBuilderItemIncomplete,
    ezBuilderPageTitle,
    ezBuilderItemPageSplitTesting,
    ezBuilderItemPageSingle,
  },
  computed: {
    ...mapGetters([GET_STEP_URL]),
    title() {
      if (this.step.attributes && this.step.attributes.name) {
        return this.step.attributes.name;
      }
      return this.name ? this.$t(`builder.step.types.${this.name}.title`) : '';
    },
    subtitle() {
      return this.name
        ? this.$t(`builder.step.types.${this.name}.subtitle`)
        : '';
    },
    editTitle() {
      switch (this.webinarRoomsType) {
        case WEBINAR_ROOM_CARD:
          return this.$t('builder.page.thumb.webinarroom');
        case WEBINAR_REPLAY:
          return this.$t('builder.page.thumb.webinarreplay');
        default:
          return undefined;
      }
    },
    webinarRoomsType() {
      const { type } = this.step.attributes;
      return [WEBINAR_ROOM_CARD, WEBINAR_REPLAY].includes(type) && type;
    },
    previewUrl() {
      return this[GET_STEP_URL](this.step);
    },
    hasVariations() {
      return this.step.variations.length > 1;
    },
    hasBaseVariation() {
      return this.step.variations[0] && this.step.variations[0].template;
    },
    allowCreateVariation() {
      const { type } = this.step.attributes;
      return this.hasBaseVariation
        && !this.webinarRoomsType
        && type !== WEBINAR_COUNTDOWN
        && this.canCreateVariation;
    },
    incomplete() {
      const { variations } = this.step;
      if (variations.length === 0) {
        return true;
      }
      return variations.some((variation) => !variation.template);
    },
    incompleteProduct() {
      return this.withProduct && !this.step.product;
    },
  },
  methods: {
    ...mapActions([
      ADD_VARIATION,
      ARCHIVE_VARIATION,
      SET_WINNER,
      SAVE_STEP_ATTRIBUTES,
      SAVE_VARIATION_TEMPLATE,
      DETACH_VARIATION_TEMPLATE,
    ]),
    async showSelectTemplateDialog({ variation }) {
      const { step } = this;
      const template = await selectTemplateDialog({
        stepType: step.attributes.type,
      });
      if (template) {
        if (variation) {
          this[SAVE_VARIATION_TEMPLATE]({ variation, template, step });
        } else {
          this[ADD_VARIATION]({ template, step });
        }
      }
    },
    async showEditTemplateDialog(params) {
      const { step, title, subtitle } = this;
      if (this.webinarRoomsType) { return; }
      const withArchive = step.variations.length > 1;
      const result = await editTemplateDialog({
        ...params,
        withArchive,
        title,
        subtitle,
      });
      const { variation, index } = params;
      switch (result && result.action) {
        case 'detachTemplate':
          await this[DETACH_VARIATION_TEMPLATE]({ step, variation });
          break;
        case 'archive':
          await this[ARCHIVE_VARIATION]({ step, variation, index });
          break;
        default:
      }
    },
    async setWinner({ variation, index }) {
      await this[SET_WINNER]({ step: this.step, variation, index });
    },
    showEditStepDialog() {
      const { step } = this;
      const { name, slug } = step.attributes;
      showStepDialog({
        name,
        slug,
        isEdit: true,
        callback: (attributes) => this[SAVE_STEP_ATTRIBUTES]({ step, attributes }),
      });
    },
  },
};
</script>

<template>
  <div
    class="builder__group-item"
    :class="{ 'builder__split-testing': hasVariations }"
  >
    <ez-builder-item-incomplete
      v-if="incomplete"
      :step="step"
      ref="error-view"
      :title="$t('builder.incomplete.noTemplateConnected')"
      :selector="`.step-page-${step.attributes.id}`"
      data-test="no-template-connected"
    />
    <ez-builder-item-incomplete
      v-if="incompleteProduct"
      v-show="!incomplete"
      :step="step"
      :selector="`.step-products-${step.attributes.id}`"
      :title="$t('builder.incomplete.missingProduct')"
      data-test="missing-product"
    />
    <section class="page-object box incompatible" :class="`step-page-${step.attributes.id}`">
      <header class="page-object__header">
        <ez-builder-page-title :title="title" :subtitle="subtitle" />
        <ez-dropdown class="page-object__header-dropdown" type="solid">
          <ez-button
            class="page-object__header-dropdown-trigger"
            type="text"
            icon="ellipsis-v"
            :icon-size="20"
            data-test="icon"
          ></ez-button>
          <ez-dropdown-menu slot="menu" direction="right">
            <ez-dropdown-item
              class="no-break"
              icon="eye"
              v-if="previewUrl"
              :href="previewUrl"
              target="_blank"
              data-test="preview"
            >
              {{ $t("builder.page.….preview") }}
            </ez-dropdown-item>
            <ez-dropdown-item
              icon="pen"
              data-test="edit"
              @click="showEditStepDialog">
              {{ $t("builder.page.….edit") }}
            </ez-dropdown-item>
            <ez-dropdown-item
              v-if="isOptionalStep"
              class="color-danger"
              icon="trash"
              data-test="delete"
              @click="$emit('delete')"
            >
              {{ $t("builder.page.….delete") }}
            </ez-dropdown-item>
          </ez-dropdown-menu>
        </ez-dropdown>
      </header>
      <ez-builder-item-page-split-testing
        v-if="hasVariations"
        :step="step"
        @edit-template="showEditTemplateDialog"
        @select-template="showSelectTemplateDialog"
        @set-winner="setWinner"
      />
      <ez-builder-item-page-single
        v-else
        :step="step"
        :edit-title="editTitle"
        :is-webinar-rooms="Boolean(webinarRoomsType)"
        :can-create-variation="allowCreateVariation"
        @edit-template="
          showEditTemplateDialog({
            variation: step.variations[0],
            index: 0,
            detachOnly: true
          })
        "
        @select-template="
          showSelectTemplateDialog({ variation: step.variations[0], index: 0 })
        "
      />
      <div v-if="canCreateVariation && webinarRoomsType" class="add-split-testing-elements__plug" />
    </section>
  </div>
</template>

<style lang="scss" src="../../incompatible.scss" scoped></style>
