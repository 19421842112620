import { show } from '@/services/modal';
import EzfConfirmationMailDialog from './dialog.vue';
import NAME from './name.json';

export default function selectTemplateDialog(params) {
  return new Promise(((resolve) => {
    show(
      EzfConfirmationMailDialog,
      params,
      {
        name: NAME,
        height: 'auto',
        // width: 820 /* "is-xbig" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'is-service-funnels', 'add-email'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
