<script>
import { createNamespacedHelpers } from 'vuex';
import { hide } from '@/services/modal.js';
import { GET_TEMPLATE_URL } from '@/store/builder/getters.js';

import EzfTemplateEditButton from '@/components/template-edit-button.vue';
import { NAME } from './dialog.js';

const { mapGetters } = createNamespacedHelpers('builder');

export default {
  name: 'EzfEditTemplateDialog',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    thumb: {
      type: String,
      required: true,
    },
    withDetach: {
      type: Boolean,
      default: true,
    },
    withArchive: {
      type: Boolean,
      default: true,
    },
    archiveOnly: {
      type: Boolean,
      default: false,
    },
    detachOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: { EzfTemplateEditButton },
  computed: {
    ...mapGetters([GET_TEMPLATE_URL]),
    previewUrl() {
      return this[GET_TEMPLATE_URL](this.id);
    },
  },
  methods: {
    close() {
      hide(NAME);
    },
    detachTemplate() {
      hide(NAME, { action: 'detachTemplate' });
    },
    archive() {
      hide(NAME, { action: 'archive' });
    },
  },
};
</script>

<template>
  <div clsas="new-split-test">
    <ez-dialog-header icon="window">
      {{ title }}
    </ez-dialog-header>

    <ez-dialog-body @close="close" class="edit-template__body">
      <img
        v-if="thumb"
        class="edit-template__thumb"
        :src="thumb"
        :alt="title"
      />
      <div class="edit-template__thumb-placeholder" v-else></div>
    </ez-dialog-body>

    <ez-dialog-footer class="edit-template__footer">
      <ezf-template-edit-button
        v-if="id"
        :id="id"
        class="edit-template__button"
        data-test="edit-page"
      />
      <ez-button
        v-if="archiveOnly"
        type="default"
        icon="archive"
        @click="archive"
        :title="$t('builder.page.….archive')"
      />
      <ez-button
        v-else-if="detachOnly && withDetach"
        type="default"
        icon="unlink"
        @click="detachTemplate"
        :title="$t('builder.page.….detachTemplate')"
        data-test="detach-template"
      />
      <ez-dropdown class="dark-dropdown" v-else>
        <ez-button
          class="edit-template__dropdown-trigger"
          icon="ellipsis-h"
        ></ez-button>
        <ez-dropdown-menu slot="menu">
          <ez-dropdown-item
            icon="eye"
            :href="previewUrl"
            target="_blank"
            class="preview"
          >
            {{ $t("builder.page.….preview") }}
          </ez-dropdown-item>
          <ez-dropdown-item
            v-if="withDetach"
            icon="minus"
            @click="detachTemplate"
          >
            {{ $t("builder.page.….detachTemplate") }}
          </ez-dropdown-item>
          <ez-dropdown-item v-if="withArchive" icon="archive" @click="archive">
            {{ $t("builder.page.….archive") }}
          </ez-dropdown-item>
        </ez-dropdown-menu>
      </ez-dropdown>
    </ez-dialog-footer>
  </div>
</template>

<style scoped>
.edit-template__thumb {
  max-width: 100%;
  width: auto !important;
}
</style>
