<script>
import { mapState } from 'vuex';
import { props } from './step-components';

export default {
  name: 'ezfFunnelStepStatistics',
  props,
  computed: {
    ...mapState('funnelStatistic', { statisticSteps: 'steps', statisticVariations: 'variations' }),
    statistic() {
      const { id } = this.step.attributes;
      return this.statisticSteps.find((row) => row.id === id);
    },
    variations() {
      return this.step.variations.map((variation) => ({
        ...variation,
        statistic: this.statisticVariations.find((v) => variation.id === v.variationid) || {
          id: '',
          sessions: 0,
          leads: 0,
          rate: '0',
          variationid: variation.id,
        },
      }));
    },
    hasVariations() {
      return this.variations.length > 1;
    },
  },
};
</script>

<template>
  <div class="builder-col__item" v-if="statistic">
    <h3 class="builder-col__item-title">
      {{ $t(`step_type_stats.${statistic.typeId}.title`) }}
    </h3>
    <div class="builder-col__item-body">
      <section class="builder-card">
        <header class="builder-card__header">
          <h4 class="builder-card__title">
            {{ statistic.name }}
          </h4>
        </header>
        <div class="builder-card__body">
          <div class="builder-card__data">
            <div
              class="builder-card__data-item"
              v-for="(val, name) in statistic.stats"
              :key="name"
            >
              <span class="builder-card__data-hint">
                {{ $t(`step_type_stats.${statistic.typeId}.stats.${name}`) }}
              </span>
              <span class="builder-card__data-value">{{ val }}</span>
            </div>
          </div>
        </div>
      </section>
      <template v-if="hasVariations">
        <section
          class="builder-card"
          v-for="(v, index) in variations"
          :key="v.id"
        >
          <header class="builder-card__header">
            <h4 class="builder-card__title">
              {{ $t(`builder.splitTest.names.${index}`) }}
            </h4>
          </header>
          <div class="builder-card__body">
            <div class="builder-card__data">
              <div
                class="builder-card__data-item"
                v-for="stat in ['sessions', 'rate', 'leads']"
                :key="stat"
              >
                <span class="builder-card__data-hint">
                  {{ $t(`step_variation_stats.${stat}.title`) }}
                </span>
                <span class="builder-card__data-value">
                  {{
                    $tc(`step_variation_stats.${stat}.value`, v.statistic[stat])
                  }}
                </span>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>
