<script>
import ezBuilderGroup from './group.vue';

export default {
  components: { ezBuilderGroup },
  name: 'EzfBuilderGroupFunnelCore',
};
</script>

<template>
  <ez-builder-group :name="$t('builder.groups.core')" color="blue">
    <slot />
  </ez-builder-group>
</template>
