<script>
// groups
import { OPTIN_THANK_YOU } from '@/enums/stepTypes';
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezStepOptinSales from '../steps/optin-sales.vue';
import ezBuilderStepThankYou from '../steps/thank-you.vue';

export default {
  name: 'EzfBuilderOptin',
  data() {
    return {
      OPTIN_THANK_YOU,
    };
  },
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupThankYou,
    ezStepOptinSales,
    ezBuilderStepThankYou,
  },
};

// optin:
// B  1  Bridge
// O  2  Optinpage
// T  5  Optin Thank You
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-step-optin-sales />
    </ez-builder-group-funnel-core>

    <ez-builder-group-thank-you>
      <ez-builder-step-thank-you :by-type="OPTIN_THANK_YOU" />
    </ez-builder-group-thank-you>
  </div>
</template>
