<script>
import { mapActions } from 'vuex';
import { REMOVE_STEP_PRODUCT } from '@/store/builder/actions';

export default {
  name: 'EzfBuilderItemProductSelected',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hoverToDelete: false,
    };
  },
  computed: {
    invalid() {
      return this.step.product.status === 'invalid';
    },
    text() {
      if (this.hoverToDelete) {
        return this.$t('builder.product.disconnect');
      }
      if (this.invalid) {
        return this.$t('builder.product.invalid');
      }
      return this.step.product.attributes.name;
    },
  },
  methods: {
    ...mapActions('builder', { removeProduct: REMOVE_STEP_PRODUCT }),
  },
};
</script>

<template>
  <div class="builder-list__select">
    <div class="custom-product-item"
      :class="{'custom-product-item_invalid': invalid}">
      <ez-icon
        name="times"
        ratio="40"
        size="12"
        color="#fff"
        class="custom-product-item__icon"
        @mouseover.native="hoverToDelete = true"
        @mouseout.native="hoverToDelete = false"
        @click.native="removeProduct({ step })"
        title=""
      />
      <div class="custom-product-item__text" :title="text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>
