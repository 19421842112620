<script>
// groups
import ezBuilderGroupSales from '../groups/sales.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

export default {
  name: 'EzfBuilderOrderform',
  components: {
    ezBuilderGroupSales,
    ezBuilderGroupThankYou,
  },
};
</script>

<template>
  <div>
    <ez-builder-group-sales />
    <ez-builder-group-thank-you />
  </div>
</template>
