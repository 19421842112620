<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { HAS_ANY_RRODUCTS } from '@/store/builder/getters';
import { CHANGE_PAYMENT_PROVIDER_ID } from '@/store/builder/actions';
import Tooltip from '@/components/tooltip.vue';
import SelectPaymentProvider from './payment-provider.vue';
import SelectProduct from './select.vue';
import SelectedProduct from './selected.vue';

export default {
  name: 'EzfBuilderItemProducts',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    Tooltip,
    SelectPaymentProvider,
    SelectProduct,
    SelectedProduct,
  },
  data() {
    return {
      newPaymentProvider: undefined,
    };
  },
  computed: {
    ...mapState('builder', ['paymentProviderId']),
    ...mapGetters('builder', [HAS_ANY_RRODUCTS]),
    showConfirmChangeProvider() {
      return this.newPaymentProvider && this.newPaymentProvider !== this.paymentProviderId;
    },
  },
  methods: {
    ...mapActions('builder', { changePaymentProvider: CHANGE_PAYMENT_PROVIDER_ID }),
    onSelectNewPaymentProvider(id) {
      if (this[HAS_ANY_RRODUCTS]) {
        this.newPaymentProvider = id;
      } else {
        this.changePaymentProvider(id);
      }
    },
  },
};
</script>

<template>
  <div class="builder__group-item">
    <div class="builder-list builder-list_yellow incompatible"
      :class="`step-products-${step.attributes.id}`">
      <div class="builder-list__header" data-test="product">
        <div class="builder-list__hint">
          {{ $t("builder.product.item") }}
        </div>
        <span class="builder-list__hint-question">
          <tooltip theme="light" :html="$t('builder.product.tooltip')" />
        </span>
      </div>

      <div class="builder-list__body">
        <div class="builder-list__items">
          <select-payment-provider
            :step="step"
            :value="newPaymentProvider || paymentProviderId"
            data-test="select-payment-provider"
            @input="onSelectNewPaymentProvider"
          />
          <selected-product
            v-if="step.product"
            :step="step"
            data-test="select-product"
          />
          <select-product
            v-else-if="paymentProviderId"
            :step="step"
            data-test="select-product"
          />
          <div class="builder-list__question" v-if="showConfirmChangeProvider">
            <div class="builder-list-question">
              <div class="builder-list-question__text">
                {{ $t("builder.product.diffpay") }}
              </div>
              <ez-button-group class="mt-1">
                <ez-button
                  type="secondary"
                  block
                  @click="newPaymentProvider = paymentProviderId"
                >
                  {{ $t("builder.splitTest.change.no") }}
                </ez-button>
                <ez-button
                  type="success"
                  block
                  @click="changePaymentProvider(newPaymentProvider)"
                >
                  {{ $t("builder.splitTest.change.yes") }}
                </ez-button>
              </ez-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>
<style lang="scss" src="../../incompatible.scss" scoped></style>
