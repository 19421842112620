<script>
import ezBuilderGroup from './group.vue';
import ezBuilderStepThankYou from '../steps/thank-you.vue';

export default {
  name: 'EzfBuilderGroupThankYou',
  components: {
    ezBuilderGroup,
    ezBuilderStepThankYou,
  },
};
</script>

<template>
  <ez-builder-group :name="$t('builder.groups.thankYou')" color="gray">
    <slot>
      <ez-builder-step-thank-you />
    </slot>
  </ez-builder-group>
</template>
