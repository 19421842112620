<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import templatePreview from '@/helpers/templatePreview';
import { hide } from '@/services/modal';
import builderManager from '@/services/builderManager';
import { getBuilderUrl } from '@/services/urls';
import TYPES from '@/enums/webinar_notifications';
import EVENTS from '@/enums/webinar_events';
import EzfEmailTemplateSelect from '@/components/select-template/email-template-select.vue';
import EzfInputMultipleEmails from '@/components/ezf-input-multiple-emails.vue';
import EzfChangeTemplateButton from '@/components/change-template-button.vue';

import * as EMAIL_TYPES from '@/enums/emailTypes';

import { CREATE, UPDATE } from '@/store/notifications/actions';
import NAME from './name.json';

const { mapActions } = createNamespacedHelpers('notifications');

export default {
  name: 'EzfFunnelWebinarNotificationDialog',
  inject: ['$validator'],
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    EzfEmailTemplateSelect,
    EzfInputMultipleEmails,
    EzfChangeTemplateButton,
  },
  props: {
    title: {
      type: String,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      changeTemplate: false,
      form: {
        ...this.notification.attributes,
      },
      emailTemplate: null,
    };
  },
  computed: {
    ...mapState(['currentUserId']),
    ...mapState('funnel', ['funnel']),
    isEdit() {
      return Boolean(this.notification.id);
    },
    editTemplateHref() {
      return getBuilderUrl(
        this.funnel.id,
        this.notification.attributes['template-id'],
      );
    },
    type() {
      return this.notification.attributes.type;
    },
    emailType() {
      if (TYPES.WELCOME === this.type) {
        return EMAIL_TYPES.WEBINAR_REGISTRATION;
      }
      if (TYPES.LAST_MINUTE === this.type) {
        return EMAIL_TYPES.WEBINAR_REMINDER;
      }
      return EMAIL_TYPES.NOTIFICATION;
    },
    withTime() {
      return [
        TYPES.PRE_WEBINAR,
        TYPES.POST_WEBINAR,
        TYPES.LAST_CONNECTION,
      ].includes(this.type);
    },
    timeReadonly() {
      return this.type === TYPES.LAST_MINUTE;
    },
    eventOptions() {
      const MAP = {
        [TYPES.POST_WEBINAR]: [
          {
            value: EVENTS.ALL_REGISTRANTS,
            label: this.$t('builder.notification.events.all_registrants'),
          },
          {
            value: EVENTS.WHO_ATTENDED,
            label: this.$t('builder.notification.events.who_attended'),
          },
          {
            value: EVENTS.WHO_DID_NOT_ATTEND,
            label: this.$t('builder.notification.events.who_did_not_attend'),
          },
          {
            value: EVENTS.WHO_ATTENDED_AND_ORDERED_YOUR_PRODUCT,
            label: this.$t(
              'builder.notification.events.who_attended_and_ordered_your_product',
            ),
          },
          {
            value: EVENTS.WHO_ATTENDED_AND_DID_NOT_ORDER_YOUR_PRODUCT,
            label: this.$t(
              'builder.notification.events.who_attended_and_did_not_order_your_product',
            ),
          },
          {
            value: EVENTS.WHO_ATTENDED_BUT_LEFT_BEFORE_TIME,
            label: this.$t(
              'builder.notification.events.who_attended_but_left_before_time',
            ),
          },
          {
            value: EVENTS.WHO_ATTENDED_STAYED_TO_TIME,
            label: this.$t(
              'builder.notification.events.who_attended_stayed_to_time',
            ),
          },
          {
            value: EVENTS.WHO_ATTENDED_STAYED_TO_TIME_BUT_DID_NOT_PURCHASE,
            label: this.$t(
              'builder.notification.events.who_attended_stayed_to_time_but_did_not_purchase',
            ),
          },
        ],
        [TYPES.LAST_CONNECTION]: [
          {
            value: EVENTS.WATCHED_REPLAY,
            label: this.$t('builder.notification.events.watched_replay'),
          },
          {
            value: EVENTS.WATCHED_REPLAY_AND_PURCHASED,
            label: this.$t(
              'builder.notification.events.watched_replay_and_purchased',
            ),
          },
          {
            value: EVENTS.WATCHED_REPLAY_AND_DID_NOT_PURCHASED,
            label: this.$t(
              'builder.notification.events.watched_replay_and_did_not_purchased',
            ),
          },
          {
            value: EVENTS.WATCHED_REPLAY_AND_LEFT_BEFORE_TIME,
            label: this.$t(
              'builder.notification.events.watched_replay_and_left_before_time',
            ),
          },
          {
            value: EVENTS.WATCHED_REPLAY_AND_STAYED_UNTIL_TIME,
            label: this.$t(
              'builder.notification.events.watched_replay_and_stayed_until_time',
            ),
          },
          {
            value:
              EVENTS.WATCHED_REPLAY_AND_STAYED_UNTIL_TIME_BUT_NOT_PURCHASED,
            label: this.$t(
              'builder.notification.events.watched_replay_and_stayed_until_time_but_not_purchased',
            ),
          },
        ],
      };
      return MAP[this.type];
    },
    hasVisitTime() {
      return (
        this.eventOptions
        && [
          EVENTS.WHO_ATTENDED_BUT_LEFT_BEFORE_TIME,
          EVENTS.WHO_ATTENDED_STAYED_TO_TIME,
          EVENTS.WHO_ATTENDED_STAYED_TO_TIME_BUT_DID_NOT_PURCHASE,
          EVENTS.WATCHED_REPLAY_AND_LEFT_BEFORE_TIME,
          EVENTS.WATCHED_REPLAY_AND_STAYED_UNTIL_TIME,
          EVENTS.WATCHED_REPLAY_AND_STAYED_UNTIL_TIME_BUT_NOT_PURCHASED,
        ].includes(this.form.event)
      );
    },
    timeLabel() {
      const MAP = {
        [TYPES.PRE_WEBINAR]: this.$t(
          'builder.notification.pre_webinar.description',
        ),
        [TYPES.POST_WEBINAR]: this.$t(
          'builder.notification.post_webinar.description',
        ),
        [TYPES.LAST_CONNECTION]: this.$t(
          'builder.notification.last_connection.description',
        ),
      };
      return MAP[this.type];
    },
    previewEmailTemplate() {
      if (this.emailTemplate) {
        const dtUpdate = this.emailTemplate.data[0].attributes['dt-update'];
        const previewUrl = templatePreview(
          this.emailTemplate.data[0].attributes,
          700,
          dtUpdate,
          this.funnel.locale,
        );

        return {
          backgroundImage: `url(${previewUrl})`,
        };
      }

      return false;
    },
    subtitle() {
      const MAP = {
        [TYPES.WELCOME]: this.$t('builder.notification.welcome.modalTitle'),
        [TYPES.PRE_WEBINAR]: this.$t(
          'builder.notification.pre_webinar.modalTitle',
        ),
        [TYPES.LAST_MINUTE]: this.$t(
          'builder.notification.last_minute.modalTitle',
        ),
        [TYPES.POST_WEBINAR]: this.$t(
          'builder.notification.post_webinar.modalTitle',
        ),
        [TYPES.LAST_CONNECTION]: this.$t(
          'builder.notification.last_connection.modalTitle',
        ),
      };
      return MAP[this.type];
    },
  },
  methods: {
    ...mapActions([CREATE, UPDATE]),
    close() {
      hide(NAME);
    },
    async getTemplatesById(id) {
      const result = await builderManager.getTemplatesByIds([id]);
      this.emailTemplate = result;
    },
    openLinkWindow(link) {
      return window.open(link, '_blank');
    },
    async submit() {
      const isValid = await this.$validator.validate('notification.*');
      if (!isValid) {
        return;
      }
      try {
        this.saving = true;
        const { isEdit, form } = this;
        const notification = {};
        if (isEdit) {
          notification.id = this.notification.id;
          notification.attributes = {};
          for (const [key, oldValue] of Object.entries( // eslint-disable-line no-unused-vars
            this.notification.attributes,
          )) {
            const newValue = form[key];
            if (newValue !== oldValue) {
              notification.attributes[key] = newValue;
            }
          }
        } else {
          const event = this.eventOptions ? form.event : EVENTS.ALL_REGISTRANTS;
          notification.attributes = { ...form, event };
        }
        await this[isEdit ? UPDATE : CREATE](notification);
        hide(NAME);
        const { subject } = form;
        this.$notify({
          data: {
            type: 'success',
            content: isEdit
              ? this.$t('builder.notification.notify.updated', { subject })
              : this.$t('builder.notification.notify.created', { subject }),
          },
        });
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: e.message || e,
          },
        });
        // show /data/attribute/* errors
        for (const error of e.response.data.errors) { // eslint-disable-line no-unused-vars
          const { pointer } = error.source;
          const PREFIX = '/data/attribute/';
          const msg = error.detail || error.title;
          if (pointer.startsWith(PREFIX)) {
            let field = pointer.substr(PREFIX.length);
            if (field === 'builder') {
              field = 'template-id';
            }

            this.errors.add({
              field,
              msg,
            });

            this.$notify({
              data: {
                type: 'error',
                content: `${field}: ${msg}`,
              },
            });
          } else {
            this.$notify({
              data: {
                type: 'error',
                content: `${pointer}: ${msg}`,
              },
            });
          }
        }
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    await this.getTemplatesById(this.form['template-id']);
  },
  watch: {
    hasVisitTime: {
      handler(value) {
        if (value) {
          if (!this.form['visit-time']) {
            this.$set(this.form, 'visit-time', '00:00:00');
          }
        } else {
          this.$delete(this.form, 'visit-time');
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-form ref="form" @submit.prevent="submit">
    <ez-dialog-header>
      <div class="add-email__header">
        <h2 class="add-email__title">
          {{
            title ||
              (isEdit
                ? $t("builder.notification.modalTitle.edit")
                : $t("builder.notification.modalTitle.add"))
          }}
        </h2>
        <span v-if="subtitle" class="add-email__subtitle">
          {{ subtitle }}
        </span>
      </div>
    </ez-dialog-header>

    <ez-dialog-body @close="close">
      <ez-form-group>
        <ez-form-item
          v-if="withTime"
          :label="timeLabel"
          :type="errors.has('time', 'notification') ? 'error' : null"
          :message="errors.first('time', 'notification')"
        >
          <ez-row>
            <ez-col size="4">
              <ez-time-picker2
                name="time"
                v-model="form.time"
                v-validate="'required'"
                data-vv-scope="notification"
                :readonly="timeReadonly"
                :show-seconds="false"
              />
            </ez-col>
          </ez-row>
        </ez-form-item>

        <ez-form-item
          v-if="eventOptions"
          :label="$t('builder.notification.label.who')"
          :type="errors.has('event', 'notification') ? 'error' : null"
          :message="errors.first('event', 'notification')"
        >
          <ez-select
            name="event"
            v-validate="'required'"
            data-vv-scope="notification"
            v-model="form.event"
            item-label="label"
            track-by="value"
            :options="eventOptions"
            :searchable="false"
            :allow-empty="true"
            is-single
            :placeholder="
              $t('builder.notification.placeholder.selectTheRecipient')
            "
          />
        </ez-form-item>

        <ez-form-item
          v-if="hasVisitTime"
          :label="''"
          :type="errors.has('visit-time', 'notification') ? 'error' : null"
          :message="errors.first('visit-time', 'notification')"
        >
          <ez-row>
            <ez-col size="5">
              <ez-time-picker
                name="visit-time"
                v-model="form['visit-time']"
                v-validate="'required'"
                data-vv-scope="notification"
                :show-seconds="true"
              />
            </ez-col>
          </ez-row>
        </ez-form-item>

        <ez-form-item
          :label="$t('builder.notification.label.subject')"
          :type="errors.has('m-subject', 'notification') ? 'error' : null"
          :message="errors.first('m-subject', 'notification')"
        >
          <ez-input
            name="m-subject"
            v-model="form.subject"
            v-validate="'required|min:2'"
            data-vv-scope="notification"
            :data-vv-as="$t('builder.notification.label.subject')"
          />
        </ez-form-item>
        <ez-form-item
          :label="$t('builder.notification.label.cc')"
          :type="errors.has('cc', 'notification') ? 'error' : null"
          :message="errors.first('cc', 'notification')"
        >
          <ezf-input-multiple-emails
            v-model="form.cc"
            name="cc"
            :data-vv-as="$t('builder.notification.label.cc')"
            data-vv-scope="notification"
          />
        </ez-form-item>
        <ez-form-item
          :label="$t('builder.notification.label.bc')"
          :type="errors.has('bc', 'notification') ? 'error' : null"
          :message="errors.first('bc', 'notification')"
        >
          <ezf-input-multiple-emails
            v-model="form.bc"
            name="bc"
            :data-vv-as="$t('builder.notification.label.bc')"
            data-vv-scope="notification"
          />
        </ez-form-item>
        <ez-form-item
          :label="$t('builder.notification.label.template')"
          :type="errors.has('m-template-id', 'notification') ? 'error' : null"
          :message="errors.first('m-template-id', 'notification')"
        >
          <template v-if="isEdit && !changeTemplate && emailTemplate">
            <div class="add-email__change-template"
              :style="previewEmailTemplate">
              <ez-button @click="openLinkWindow(editTemplateHref)">
                {{ $t("builder.buttons.editTemplate") }}
              </ez-button>
            </div>
            <ezf-change-template-button
              @click.prevent="changeTemplate = true"
            />
          </template>
          <ezf-email-template-select
            v-else
            name="m-template-id"
            :type="emailType"
            v-model="form['template-id']"
            v-validate="'required'"
            data-vv-scope="notification"
            :data-vv-as="$t('builder.notification.label.template')"
          />
        </ez-form-item>
      </ez-form-group>
    </ez-dialog-body>

    <ez-dialog-footer>
      <ez-button-group justify="end">
        <ez-button
          type="secondary"
          :disabled="saving"
          native-type="button"
          @click="close"
        >
          {{ $t("builder.buttons.discard") }}
        </ez-button>
        <ez-button
          type="success"
          :disabled="saving"
          :preloader="saving"
          native-type="submit"
          @click.prevent="submit"
        >
          {{
            isEdit
              ? $t("builder.buttons.saveChanges")
              : $t("builder.buttons.create")
          }}
        </ez-button>
      </ez-button-group>
    </ez-dialog-footer>
  </ez-form>
</template>
