<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { IS_INVALID_FUNEL } from '@/store/builder/getters';

// webinar notifications
import { WEBINAR_TYPES } from '@/enums/funnelsType';
import EzfWebinarDetails from './components/webinar/details.vue';

// import ezBuilderRevisions from "./components/revisions.vue";
import EzfBuilderMap from './components/map.vue';

// dialogs
import EzfDialogSplitTest from './components/dialogs/split-test.vue';

import stepComponents from '../step-components.js';
import builderStepComponent from './components/items/page/page-builder.vue';
import archiveStepComponent from './components/archive/step.vue';
import EzfErrorsOverview from './components/errors-overview.vue';

stepComponents.set('builder', builderStepComponent);
stepComponents.set('archive', archiveStepComponent);

export default {
  name: 'ezfFunnelBuilderPage',
  components: {
    EzfWebinarDetails,
    // ezBuilderRevisions,
    EzfBuilderMap,
    EzfDialogSplitTest,
    EzfErrorsOverview,
  },
  computed: {
    ...mapFields('builder', ['webinarBoxExpanded']),
    ...mapState('funnel', ['funnel']),
    ...mapState('builder', ['archiveLength', 'steps']),
    ...mapGetters('builder', [IS_INVALID_FUNEL]),
    isWebinarType() {
      return this.funnel && WEBINAR_TYPES.includes(this.funnel.type);
    },
    showWebinarDetails() {
      return this.isWebinarType && this.steps && !this[IS_INVALID_FUNEL];
    },
  },
  created() {
    this.webinarBoxExpanded = true;
  },
  mounted() {
    this.$watch(
      () => this.archiveLength,
      () => this.$nextTick(() => {
        if (!this.$el) {
          return;
        }

        const xRailEl = this.$el.querySelector('.ps__rail-x');
        if (!xRailEl) {
          return;
        }
        const { archive } = this.$refs;
        const archiveHeight = archive && archive.offsetHeight;

        if (archiveHeight) {
          xRailEl.style.setProperty('height', `calc(100% - ${Math.max(0, archiveHeight - 30)}px)`, 'important');
        } else {
          xRailEl.style.setProperty('height', '100%');
        }
      }),
      { immediate: true },
    );
  },
};
</script>

<template>
  <ez-content>
    <ez-container>
      <div class="builder">
        <ezf-errors-overview />

        <ezf-webinar-details v-if="showWebinarDetails" :funnel="funnel" />

        <ez-scrollable
          class="builder__scrollable doubled-scroll"
          type="horizontal"
          bar-position="top"
          update-on-resize
        >
          <!-- <ez-builder-revisions/> -->
          <ezf-builder-map step-component="builder" />

          <div
            class="builder__archive"
            ref="archive"
            v-show="archiveLength"
          >
            <div class="builder-archive">
              <h2 class="builder-archive__title">
                {{ $t("funnels.tabs.archive") }}
              </h2>
              <ezf-builder-map
                class="builder-archive__items"
                step-component="archive"
              />
            </div>
          </div>
        </ez-scrollable>
      </div>
    </ez-container>

    <ezf-dialog-split-test />
  </ez-content>
</template>

<style lang="scss" scoped>
.builder-archive {
  padding-bottom: 0;
}

.builder-archive__items {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.is-service-funnels {
  .builder {
    .ps__rail-x {
      margin: 0 calc((100% - 940px) / 2) !important;
    }

    &__scrollable {
      margin-top: 0 !important;

      @media (max-width: 1200px) {
        margin-left: calc((100vw - 940px) / -2);
      }
    }
  }
}

.ez-collapse.webinar {
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(128, 149, 170, 0.25);

  ::v-deep {
    .webinar__header,
    .webinar__body {
      box-shadow: none;
    }

    .ez-collapse__wrap {
      padding-top: 0;
    }
  }
}

.builder {
  &__main {
    padding-top: 40px;
  }
}
</style>
