<script>
import enLocale from '@/locales/en_EN.json';

export default {
  name: 'EzfBuilderGroup',
  inject: ['withBuilderGroupHeaders'],
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    headerColorClass() {
      return `builder__group-header_${this.color}`;
    },
    dataTestAttr() {
      const { core, sales, thankYou } = enLocale?.builder?.groups || {};
      const map = {
        [core]: 'lead-processing',
        [sales]: 'sales-process',
        [thankYou]: 'funnel-end',
      };
      return map[this.name];
    },
  },
};
</script>

<template>
  <div class="builder__group">
    <div
      class="builder__group-header"
      v-if="withBuilderGroupHeaders"
      :class="headerColorClass"
      :data-test="dataTestAttr"
    >
      {{ name }}
    </div>
    <div class="builder__group-body">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.builder__group-header {
  white-space: nowrap;
}
.builder__group-header_white {
  color: #555;
}
</style>
