<script>
// dnd
import { Container, Draggable } from 'vue-smooth-dnd';

import { createNamespacedHelpers } from 'vuex';
import { MOVE_STEPS } from '@/store/builder/actions';

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderSortableSteps',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    component: {
      required: true,
    },
  },
  components: {
    Container,
    Draggable,
  },
  methods: {
    ...mapActions([MOVE_STEPS]),

    async onDrop(params) {
      if (!params) {
        return;
      }
      const { removedIndex, addedIndex } = params;
      const soruce = this.steps[removedIndex];
      const sourceIndex = soruce.index;
      const target = this.steps[addedIndex];
      let targetIndex = target.index;
      let count = 1;
      if (soruce.downsell) {
        count++;
      }
      if (sourceIndex < targetIndex) {
        if (target.downsell) {
          targetIndex++;
        }
        if (soruce.downsell) {
          targetIndex--;
        }
      }
      this[MOVE_STEPS]({ sourceIndex, targetIndex, count });
    },
  },
};
</script>

<template>
  <Container
    @drop="onDrop"
    orientation="horizontal"
    non-drag-area-selector=".no-dnd"
    class="container-wrap"
  >
    <Draggable v-for="props in steps" :key="props.step.attributes.id">
      <component
        :class="{ 'no-dnd': steps.length === 1 }"
        :is="component"
        v-bind="props"
      />
    </Draggable>
  </Container>
</template>

<style scoped>
.container-wrap {
  display: flex;
}
</style>
