import { show } from '@/services/modal.js';
import templateName from '@/filters/template-name.js';
import ezEditTemplateDialog from './dialog.vue';

export const NAME = 'edit-template';

export default function editTemplateDialog(params) {
  const { template } = params.variation;
  const props = {
    title: templateName(template) || `Edit ${params.title}`,
    id: template.id,
    thumb: (template.meta && template.meta.preview) || '',
    withArchive: params.withArchive,
    detachOnly: params.detachOnly,
    archiveOnly: params.archiveOnly,
    withDetach: params.withDetach,
  };
  return new Promise(((resolve) => {
    show(
      ezEditTemplateDialog,
      props,
      {
        name: NAME,
        height: 'auto',
        width: 390,
        // width: 820 /* "is-xbig" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
