<script>
// groups
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupSales from '../groups/sales.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezBuilderStepWebinarRegistration from '../steps/webinar-registration.vue';
import ezBuilderStepOptinThankYou from '../steps/optin-thank-you.vue';
import ezBuilderStepWebinar from '../steps/webinar.vue';
import ezBuilderStepCanditatureform from '../steps/canditatureform.vue';

import ezBuilderStepThankYou from '../steps/thank-you.vue';

export default {
  name: 'BuilderWebinarCanditature',
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupSales,
    ezBuilderGroupThankYou,
    ezBuilderStepWebinarRegistration,
    ezBuilderStepOptinThankYou,
    ezBuilderStepWebinar,
    ezBuilderStepCanditatureform,
    ezBuilderStepThankYou,
  },
};
</script>

<template>
  <div>
    <template>
      <ez-builder-group-funnel-core>
        <ez-builder-step-webinar-registration />
        <ez-builder-step-optin-thank-you />
        <ez-builder-step-webinar />
      </ez-builder-group-funnel-core>

      <ez-builder-group-sales />

      <ez-builder-group-thank-you>
        <ez-builder-step-canditatureform />
        <ez-builder-step-thank-you />
      </ez-builder-group-thank-you>
    </template>
  </div>
</template>
