<script>
export default {
  name: 'EzfBuilderDefault',
  props: ['funnel'],
};
</script>

<template>
  <div>
    {{ $t("builder.error.notSupportedFunnelType", { type: funnel.type }) }}
  </div>
</template>
