<script>
import { mapState, mapActions } from 'vuex';
import {
  SAVE_STEP_FALLBACK,
  REMOVE_STEP_FALLBACK,
} from '@/store/builder/actions';
import { UPSELL, DOWNSELL, getStepTypeByNum } from '@/enums/stepTypes';
import templateNameFilter from '@/filters/template-name';
import ezBuilderItemIncomplete from './incomplete.vue';

export default {
  name: 'EzfBuilderItemFallback',
  props: {
    index: {
      type: Number,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    ezBuilderItemIncomplete,
  },
  data() {
    return {
      saving: false,
      fallback: this.step.fallback,
    };
  },
  computed: {
    ...mapState('builder', ['steps']),
    ...mapState('funnel', ['funnel']),
    options() {
      // all upsell steps after the step and first after sell block
      const result = [];
      const stepsAfterCurrent = this.steps.slice(
        this.index + 1,
        this.steps.length,
      );
      for (const step of stepsAfterCurrent) {
        const { type } = step.attributes;
        if (type === DOWNSELL) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const { id } = step.attributes;
        let title = step.attributes.name;
        if (!title && step.variants) {
          title = templateNameFilter(
            step.variants[0].template,
            this.funnel.locale,
          );
        }
        if (!title) {
          // eslint-disable-next-line
          title = getStepTypeByNum(step.attributes.type).title;
        }
        result.push({ id, title });
        if (type !== UPSELL) {
          break;
        }
      }
      return result;
    },
  },
  methods: {
    ...mapActions('builder', [SAVE_STEP_FALLBACK, REMOVE_STEP_FALLBACK]),
    async changeFallbackStep() {
      const { step, fallback } = this;

      this.saving = true;
      try {
        await this[SAVE_STEP_FALLBACK]({ step, fallback });

        this.$notify({
          data: {
            type: 'success',
            content: this.$t('notify.success'),
          },
        });
      } catch (e) {
        this.$notify({
          data: {
            type: 'error',
            content: this.$t('notify.fail', {
              message: e.message,
            }),
          },
        });
      } finally {
        this.saving = false;
      }
    },
    async checkFallback() {
      const { step } = this;

      if (
        step.fallback
        && !this.options.some((option) => step.fallback === option.id)
      ) {
        await this[REMOVE_STEP_FALLBACK](step);
      }
      if (
        this.fallback
        && !this.options.some((option) => this.fallback === option.id)
      ) {
        this.fallback = undefined;
      }
    },
  },
  watch: {
    index() {
      return this.checkFallback();
    },
    // hack to update selected name
    // https://www.wrike.com/open.htm?id=331439816
    options: {
      handler() {
        const { fallback } = this;
        this.fallback = undefined;
        this.$nextTick(() => {
          this.fallback = fallback;
          this.checkFallback();
        });
      },
      deep: true,
      immediate: true,
    },
    'step.fallback': {
      handler(value, oldValue) {
        if (this.fallback === oldValue) {
          this.fallback = value;
        }
      },
    },
    immediate: true,
  },
};
</script>

<template>
  <div class="builder__group-item">
    <ez-builder-item-incomplete
      v-if="!step.fallback"
      :step="step"
      :selector="`.step-fallback-${step.attributes.id}`"
      :title="$t('builder.incomplete.fallbackNotSelected')"
    />

    <section class="page-object box incompatible" :class="`step-fallback-${step.attributes.id}`">
      <header class="page-object__header">
        <div class="page-object__header-text">
          <h3 class="page-object__title">{{ $t("builder.fallback.title") }}</h3>
        </div>
      </header>
      <div class="page-object__body">
        <ez-select
          class="no-dnd"
          v-model="fallback"
          :placeholder="$t('builder.fallback.placeholder')"
          track-by="id"
          item-label="title"
          :options="options"
          :searchable="false"
          :allow-empty="true"
          :prop="{ openDirection: 'top' }"
          is-single
        />
        <ez-button
          class="save"
          type="success"
          block
          size="small"
          :disabled="saving"
          :preloader="saving"
          @click="changeFallbackStep"
          v-if="fallback !== step.fallback"
        >{{ $t("builder.fallback.applyButton") }}</ez-button>
      </div>
    </section>
  </div>
</template>

<style scoped>
.save {
  margin-top: 15px;
}
</style>

<style lang="scss" src="../incompatible.scss" scoped></style>
