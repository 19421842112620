import { show } from '@/services/modal';

import NAME from './name.json';

export default function actionDialog(step, action) {
  const { id, type } = step.attributes;
  return new Promise((async (resolve) => { // eslint-disable-line no-async-promise-executor
    const component = await import(/* webpackChunkName: "actionDialog" */ './dialog.vue');
    show(
      component.default,
      { stepId: id, stepType: type, action },
      {
        name: NAME,
        height: 'auto',
        // width: 600 /* "is-xmedium" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'is-service-funnels', 'action-form'],
      },
      {
        'before-close': (e) => {
          resolve(e.params || {});
        },
      },
    );
  }));
}
