<script>
import {
  TRIGGER_TITLES,
  // ACTION_TITLES
} from '@/enums/actions';

import ActionImage from './image.vue';

export default {
  name: 'EzfBuilderAction',
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  components: {
    ActionImage,
  },
  computed: {
    name() {
      // action-type
      const { trigger } = this.action.attributes;
      return this.$t(`builder.actions.triggers.${TRIGGER_TITLES[trigger]}`);
    },
  },
};
</script>

<template>
  <ez-link
    class="builder-list-item"
    @click.prevent="$emit('edit')"
    :data-test="`action-${action.id}`"
  >
    <div class="builder-list-item__img">
      <action-image :action-type="action.attributes['action-type']" />
    </div>
    <div class="builder-list-item__title">
      <span>{{ name }}</span>
    </div>
    <ez-button
      class="builder-list-item__edit"
      icon="pen"
      type="text"
      :title="$t('Edit')"
    />
  </ez-link>
  <!-- <ez-icon
      v-if="action.attributes['is-active']"
      class="builder-list__item-icon"
      name="check-circle"
      color="#20a56e"
    /> -->
</template>

<style lang="scss">
.builder-list {
  &__header {
    padding: 0 8px !important;
  }
  &__hint {
    padding-right: 6px !important;
    flex-grow: 0 !important;
  }
  &__hint-question {
  }
  &__body {
  }
  .ez-collapse__wrap {
    padding-top: 0 !important;
  }
}
.builder-list-item {
  $this: ".builder-list-item";
  display: flex;
  align-items: center;
  border-radius: 3px;
  max-width: 100%;
  margin-bottom: 4px !important;
  width: 100%;
  background-color: #2c3e50;
  + .ez-button {
    margin-top: 4px;
  }
  &__img {
    flex-shrink: 0;
    width: 32px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  #{$this}__img + #{$this}__title {
    padding-left: 0;
  }
  &__title {
    flex-grow: 1;
    overflow: hidden;
    padding-left: 8px;
    font-size: 13px;
    color: #fff;
    text-overflow: ellipsis;
    span {
      white-space: nowrap;
    }
  }
  &__edit {
  }
}
</style>
