<script>
import isEqual from 'lodash/isEqual.js';
import { createNamespacedHelpers } from 'vuex';
import ezSplitTesting from '@/components/split-testing.js';
import { VARIATIONS_LIMIT } from '@/enums/variations.js';
import { GET_TEMPLATE_URL } from '@/store/builder/getters.js';
import { CHANGE_VARIATIONS_PERCENTS } from '@/store/builder/actions';
import ezSplitTestingCreateVariation from './create-variation.vue';
import ezfBuilderItemSelectTemplate from './select-template.vue';

import ezPageThumb from './thumb.vue';

const { mapActions, mapGetters } = createNamespacedHelpers('builder');

const COLORS = ['#1ebcee', '#f41271', '#febf1d', '#26C281'];
const DATA_TEST_ATTRS = ['multirange-blue', 'multirange-red'];

export default {
  name: 'EzfBuilderItemPageSplitTesting',
  components: {
    ezSplitTesting,
    ezSplitTestingCreateVariation,
    ezfBuilderItemSelectTemplate,
    ezPageThumb,
  },
  props: {
    step: Object,
  },
  data() {
    return {
      LIMIT: 4,
      splitData: [],
      showFullSplit: true,
      saving: false,
    };
  },
  computed: {
    ...mapGetters([GET_TEMPLATE_URL]),
    canCreateVariation() {
      return this.splitData.length < VARIATIONS_LIMIT;
    },
    percents() {
      return this.splitData.map((v) => v.value);
    },
    isChanged() {
      const { percents } = this;
      const { variations } = this.step;
      if (percents.length !== variations.length) {
        return false;
      }
      const currentPercents = variations.map((v) => v.attributes.percent);
      return !isEqual(currentPercents, percents);
    },
  },
  methods: {
    ...mapActions([CHANGE_VARIATIONS_PERCENTS]),
    getPreviewUrl(id) {
      return this[GET_TEMPLATE_URL](id);
    },
    updateStapData() {
      this.splitData = this.step.variations.map((variation, index) => ({
        color: COLORS[index],
        title: this.$t(`builder.splitTest.names.${index}`),
        // title: `${VARIATIONS[index].title} ${variation.attributes.index}`,
        value: variation.attributes.percent,
        variation,
        dataTestAttr: DATA_TEST_ATTRS[index],
      }));
    },
    async updateValues() {
      if (this.isChanged) {
        this.saving = true;
        try {
          const { step, percents } = this;
          await this[CHANGE_VARIATIONS_PERCENTS]({ step, percents });
          this.$notify({
            data: {
              type: 'success',
              content: this.$t('notify.success'),
            },
          });
        } catch (e) {
          this.$notify({
            data: {
              type: 'error',
              content: this.$t('notify.fail', {
                message: e.message,
              }),
            },
          });
        } finally {
          this.saving = false;
        }
      }
    },
  },
  created() {
    this.$watch('step.variations.length', this.updateStapData, {
      immediate: true,
    });
  },
};
</script>

<template>
  <div class="page-object__body">
    <div class="split-testing-group split-testing-group_long">
      <div class="split-testing-group__element">
        <ez-split-testing
          class="split-testing-group__element box box_bordered no-dnd"
          :title="$t('builder.splitTest.title')"
          v-model="splitData"
        >
          <template v-if="isChanged" slot="confirm">
            Do you want to save<br />the split test division?
          </template>
          <ez-button-group v-if="isChanged" slot="buttons" justify="between">
            <ez-button
              block
              type="secondary"
              :disabled="saving"
              data-test="no"
              @click="updateStapData"
            >
              {{ $t("builder.splitTest.change.no") }}
            </ez-button>
            <ez-button
              block
              :preloader="saving"
              :disabled="saving"
              type="success"
              data-test="yes"
              @click="updateValues"
            >
              {{ $t("builder.splitTest.change.yes") }}
            </ez-button>
          </ez-button-group>
        </ez-split-testing>

        <ez-collapse :time="0.3" v-model="showFullSplit">
          <template slot="content">
            <div class="split-testing-elements">
              <section
                class="test-item box box_bordered"
                v-for="(item, index) in splitData"
                :key="index"
              >
                <header class="test-item__header">
                  <div
                    class="test-item__circle"
                    :style="{ backgroundColor: item.color }"
                  ></div>
                  <h3
                    :data-test="`split${index + 1}`"
                    class="test-item__title"
                  >{{ item.title }}</h3>
                  <ez-link
                    class="test-item__header-link"
                    target="_blank"
                    :href="
                      getPreviewUrl(item.variation.attributes['template-id'])
                    "
                    :title="$t('builder.page.….preview')"
                    :icon="'eye'"
                  />
                </header>
                <div class="test-item__body">
                  <div class="test-item__info" v-if="item.variation.statistic">
                    <div class="test-item__info-value">
                      {{
                        $tc(
                          "builder.splitTest.conversionRate",
                          item.variation.statistic.rate
                        )
                      }}
                    </div>
                    <div class="test-item__info-hint">
                      {{
                        $tc(
                          "builder.splitTest.basedOn",
                          item.variation.statistic.sessions
                        )
                      }}
                    </div>
                  </div>
                  <ez-page-thumb
                    v-if="item.variation.template"
                    :thumb="
                      (item.variation.template.meta &&
                        item.variation.template.meta.preview) ||
                        ''
                    "
                    @edit="
                      $emit('edit-template', {
                        variation: item.variation,
                        index,
                        withDetach: false,
                        archiveOnly: true
                      })
                    "
                  />
                  <ezf-builder-item-select-template
                    v-else
                    @select="
                      $emit('select-template', {
                        variation: item.variation,
                        index,
                        withDetach: false
                      })
                    "
                    :step-type="step.attributes.type"
                  />
                </div>
                <footer class="test-item__footer">
                  <ez-link
                    class="test-item__set-winner"
                    v-if="item.variation.template"
                    @click.prevent="
                      $emit('set-winner', { variation: item.variation, index })
                    "
                  >
                    {{ $t("builder.splitTest.setAsAWinner") }}
                  </ez-link>
                </footer>
              </section>
            </div>
            <ez-split-testing-create-variation
              v-if="canCreateVariation"
              :step="step"
            />
          </template>
        </ez-collapse>
      </div>
      <ez-link
        :data-test="showFullSplit ? 'show-less' : 'show-more'"
        class="split-testing-collapse-trigger box box_bordered"
        @click.prevent="showFullSplit = !showFullSplit"
      >
        {{
          showFullSplit
            ? $t("builder.splitTest.showLess")
            : $t("builder.splitTest.showMore")
        }}
      </ez-link>
    </div>
  </div>
</template>
