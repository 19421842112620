<script>
import { mapState, mapGetters } from 'vuex';
import EzfSelectSmtp from '@/components/select-smtp.vue';
import { HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE } from '@/store/funnel/getters';
import settingsSectionFactory from '../../mixins/settings-section-factory';

export default {
  name: 'EzfSettingsSectionSmtpSettings',
  components: {
    EzfSelectSmtp,
  },
  mixins: [
    settingsSectionFactory(
      'isCustomSmtpOn',
      'customSmtpId',
      'fromName',
      'fromEmail',
      'domainId',
    ),
  ],
  computed: {
    ...mapState({
      domains: 'domains',
    }),
    ...mapGetters({ hasAnyChangedAttribute: `funnel/${HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE}` }),
    selectedDomain() {
      return this.domains.find((domain) => domain.id === this.domainId);
    },
    selectedDomainHost() {
      const { host } = this.selectedDomain;
      return `@${host || ''}`;
    },
    showSenderEmailForm() {
      const domainWithMXRecord = 2;
      const { type } = this.selectedDomain || {};
      return !this.isCustomSmtpOn && type === domainWithMXRecord;
    },
    hasChangedAttributes() {
      return this.hasAnyChangedAttribute([
        'isCustomSmtpOn',
        'customSmtpId',
        'fromName',
        'fromEmail',
      ]);
    },
  },

  created() {
    this.$validator.extend(
      'customEmailValidator',
      (value, { domain }) => this.$validator.verify(`${value}${domain}`, 'email'),
      { paramNames: ['domain'] },
    );
  },

  methods: {
    async validateAndSave() {
      if (this.showSenderEmailForm) {
        const valid = await this.$validator.validate();
        if (valid) {
          this.save();
        }
      } else if (this.isCustomSmtpOn && !this.customSmtpId) {
        this.isCustomSmtpOn = false;
      } else {
        this.save();
      }
    },
    onSmtpChange(value) {
      if (!value) {
        this.isCustomSmtpOn = false;
      }
    },
  },
};
</script>

<template>
  <ez-settings-fieldset
    :legend="$t('settings.smtpSettings')"
    :is-changed="hasChangedAttributes"
    @discard="discard"
    @save="validateAndSave"
  >
    <ez-row :gutter="16">
      <ez-col>
        <div class="panel-collapse">
          <div class="panel-collapse__content">
            <ez-collapse v-model="isCustomSmtpOn" :time="0.3">
              <template slot="header">
                <ez-row align="start">
                  <ez-col>
                    <div class="panel-collapse__info">
                      <div class="panel-collapse__title">
                        {{ $t("settings.SMTP.title") }}
                      </div>
                      <div class="panel-collapse__description">
                        {{
                          isCustomSmtpOn
                            ? $t("settings.SMTP.text.on")
                            : $t("settings.SMTP.text.off")
                        }}
                      </div>
                    </div>
                  </ez-col>
                  <ez-col size="7">
                    <div class="panel-collapse__switch">
                      <ez-switch
                        v-model="isCustomSmtpOn"
                        inactive-color="#ff4949"
                        :width="50"
                      ></ez-switch>
                    </div>
                  </ez-col>
                </ez-row>

                <ez-row v-if="showSenderEmailForm" gutter="16" class="mt-4">
                  <ez-col>
                    <ez-form-item
                      :label="$t('settings.SMTP.sender-name')"
                      :type="errors.first($t('settings.SMTP.sender-name')) ? 'error' : ''"
                      :message="errors.first($t('settings.SMTP.sender-name')) || ''"
                    >
                      <ez-input
                        v-model="fromName"
                        v-validate="fromEmail && 'required'"
                        :name="$t('settings.SMTP.sender-name')"
                        key="fromName"
                      />
                    </ez-form-item>
                  </ez-col>
                  <ez-col>
                    <ez-form-item
                      :label="$t('settings.SMTP.sender-email')"
                      :type="errors.first($t('settings.SMTP.sender-email')) ? 'error' : ''"
                      :message="errors.first($t('settings.SMTP.sender-email')) || ''"
                      solid
                    >
                      <ez-input
                        v-model="fromEmail"
                        v-validate="fromName
                          && `required|customEmailValidator:${selectedDomainHost}`"
                        :name="$t('settings.SMTP.sender-email')"
                        key="fromEmail"
                        style="min-width: 50%;"
                      />
                      <ez-input :value="selectedDomainHost" style="min-width: 50%;" disabled />
                    </ez-form-item>
                  </ez-col>
                </ez-row>

              </template>
              <template slot="content">
                <ez-row>
                  <ez-col :size="24">
                    <ezf-select-smtp
                      v-model="customSmtpId"
                      @input="onSmtpChange"
                    />
                  </ez-col>
                </ez-row>
              </template>
            </ez-collapse>
          </div>
        </div>
      </ez-col>
    </ez-row>
  </ez-settings-fieldset>
</template>

<style scoped>
.panel-collapse__switch {
  text-align: right;
}
</style>
