<script>
import { createNamespacedHelpers } from 'vuex';
import { WELCOME, LAST_MINUTE } from '@/enums/webinar_notifications';

import { BY_TYPE } from '@/store/notifications/getters.js';
import showFunnelWebinarNotificationDialog from './dialog';
import EzfFunnelWebinarNotification from './notification';

const { mapGetters } = createNamespacedHelpers('notifications');

export default {
  name: 'EzfFunnelWebinarNotificationsType',
  props: {
    type: {
      type: Number,
      required: true,
    },
    descending: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
    },
  },
  components: { EzfFunnelWebinarNotification },
  computed: {
    ...mapGetters([BY_TYPE]),
    notifications() {
      return this.BY_TYPE(this.type, this.descending);
    },
    canAddMore() {
      return (
        ![LAST_MINUTE, WELCOME].includes(this.type)
        || this.notifications.length < 1
      );
    },
  },
  methods: {
    showModalAddEmail() {
      const { type, time } = this;
      showFunnelWebinarNotificationDialog({
        notification: {
          attributes: {
            type,
            time,
            subject: '',
            cc: '',
            bc: '',
            'template-id': undefined,
            'smtp-id': undefined,
            'visit-time': undefined,
          },
        },
      });
    },
  },
};
</script>

<template>
  <section class="notification-step">
    <header class="notification-step__header">
      <div class="notification-step__circle"></div>
      <span class="notification-step__title"><slot name="title"/></span>
      <span class="notification-step__hint"><slot name="subtitle"/></span>
      <ez-button
        v-if="canAddMore"
        class="notification-step__add-email"
        outline
        size="small"
        @click.prevent="showModalAddEmail"
      >
        {{ $t("builder.notification.addEmailButton") }}
      </ez-button>
    </header>
    <div class="notification-step__emails">
      <ezf-funnel-webinar-notification
        v-for="notification of notifications"
        :key="notification.id"
        :notification="notification"
      >
        <slot
          name="time"
          slot="time"
          slot-scope="{ timeText }"
          :timeText="timeText"
        />
      </ezf-funnel-webinar-notification>
    </div>
  </section>
</template>
