<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import confirmDelete from '@/components/dialogs/confirmDelete';
import { WELCOME, LAST_MINUTE } from '@/enums/webinar_notifications';
import i18n from '@/i18n';

import { REMOVE } from '@/store/notifications/actions.js';
import showFunnelWebinarNotificationDialog from './dialog';

const { mapActions } = createNamespacedHelpers('notifications');

export default {
  name: 'EzfFunnelWebinarNotification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      removing: false,
    };
  },
  computed: {
    timeText() {
      const { time } = this.notification.attributes;
      return moment.duration(time).humanize();
    },
    canBeDeleted() {
      return ![LAST_MINUTE, WELCOME].includes(
        this.notification.attributes.type,
      );
    },
  },
  methods: {
    ...mapActions([REMOVE]),

    showModalEditEmail() {
      const { notification } = this;
      showFunnelWebinarNotificationDialog({
        notification,
      });
    },

    async remove() {
      const { subject } = this.notification.attributes;
      const isDelete = await confirmDelete('notification', subject);
      if (isDelete) {
        try {
          this.removing = true;
          await this[REMOVE](this.notification.id);
          this.$notify({
            data: {
              type: 'success',
              content: i18n.t('builder.notification.notify.removed', {
                subject,
              }),
            },
          });
        } catch (e) {
          this.$notify({
            data: {
              type: 'error',
              content: i18n.t('builder.notification.notify.failToRemove', {
                subject,
                message: e.message,
              }),
            },
          });
        } finally {
          this.removing = false;
        }
      }
    },
  },
};
</script>

<template>
  <div class="notification-step__email">
    <div class="notification-step__email-border"></div>
    <div class="notification-step__email-relation"></div>
    <ez-panel
      class="notification-step__email-data"
      color-area="#e4e9ed"
      :square="true"
    >
      <template slot="checkbox">
        <ez-icon name="envelope" size="18"></ez-icon>
      </template>
      <template slot="wrap">
        <div class="panel__title">
          {{ notification.attributes.subject }}
        </div>
        <div class="panel__status is-progress">
          <ez-icon name="clock"></ez-icon>
          <span>
            <slot name="time" :timeText="timeText" />
          </span>
        </div>
      </template>
      <template slot="buttons">
        <ez-button-group justify="end">
          <ez-button
            icon="pen"
            size="small"
            outline
            @click.prevent="showModalEditEmail"
          />
          <ez-button
            v-if="canBeDeleted"
            icon="trash"
            size="small"
            type="danger"
            outline
            :preloader="removing"
            :disabled="removing"
            @click="remove"
          />
        </ez-button-group>
      </template>
    </ez-panel></div
></template>
