<script>
import { CONFIRMED } from '@/enums/contactsSubscriptionStatus';
import { getContactUrl } from '@/services/urls';

export default {
  name: 'EzfContactsDropdown',
  props: ['row'],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    editUrl() {
      return getContactUrl(this.row.id);
    },
    subscribed() {
      return this.row.attributes['subscribe-status'] === CONFIRMED;
    },
  },
};
</script>

<template>
  <ez-dropdown v-model="open">
    <ez-icon name="ellipsis-v" size="20" color="#8095AA" />
    <template slot="menu">
      <ez-dropdown-menu v-if="open">
        <ez-dropdown-item icon="pen" :href="editUrl" target="_blank">
          {{ $t("contacts.edit-button") }}
        </ez-dropdown-item>

        <ez-dropdown-item
          v-if="subscribed"
          icon="user-slash"
          @click="$emit('unsubscribe-single-user-click')"
        >
          {{ $t("contacts.unsubscribe-button") }}
        </ez-dropdown-item>

        <ez-dropdown-item
          v-else
          icon="user"
          @click="$emit('subscribe-single-user-click')"
        >
          {{ $t("contacts.subscribe-button") }}
        </ez-dropdown-item>

        <ez-dropdown-item
          icon="trash"
          type="danger"
          iconColor="#EE5253"
          @click="$emit('delete-single-user-click')"
        >
          {{ $t("contacts.delete-button") }}
        </ez-dropdown-item>
      </ez-dropdown-menu>
    </template>
  </ez-dropdown>
</template>
