<script>
export default {
  name: 'BuilderItemPageThumb',
  props: {
    thumb: {
      type: String,
      required: true,
    },
    editTitle: {
      type: String,
      default: '',
    },
    isWebinarRooms: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="test-item__thumb">
    <img class="test-item__thumb-img" v-if="thumb" :src="thumb" alt />
    <div class="test-item__thumb-placeholder" v-else></div>
    <div
      v-if="isWebinarRooms"
      :style="{cursor: 'not-allowed'}"
      class="test-item__edit-link"
    >
      <span class="test-item__edit-link-text color-white">
        {{ editTitle || $t("builder.page.thumb.noedit") }}
      </span>
    </div>
    <div
      v-else class="test-item__edit-link"
      data-test="edit"
    >
      <EzButton
        type="light"
        icon="edit"
        @click.prevent="$emit('edit')"
      >
        {{ editTitle || $t("builder.page.thumb.edit") }}
      </EzButton>
    </div>
  </div>
</template>

<style scoped>
.test-item__thumb-img {
  max-width: 100%;
  min-height: 180px;
  height: auto;
}
</style>
