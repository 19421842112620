<script>
import { OPTINPAGE, WEBINAR_REGISTRATION } from '@/enums/stepTypes';

export default {
  name: 'ezfBuilderArchiveVariation',
  props: {
    index: {
      type: Number,
      required: true,
    },
    restorable: {
      type: Boolean,
    },
    variation: {
      type: Object,
      required: true,
    },
    stepType: {
      type: Number,
      required: true,
    },
  },
  computed: {
    stat() {
      return this.variation.statistic;
    },
    hideRate() {
      return [OPTINPAGE, WEBINAR_REGISTRATION].includes(this.stepType);
    },
  },
};
</script>

<template>
  <div class="archive-item">
    <div class="archive-item__main">
      <div class="archive-item__row">
        <span class="archive-item__hint">
          {{ $t("builder.archive.sessions") }}
        </span>
        <span class="archive-item__value">
          {{ stat ? stat.sessions : '0' }}
        </span>
      </div>
      <div class="archive-item__row" v-if="hideRate">
        <span class="archive-item__hint">
          {{ $t("builder.archive.conversion") }}
        </span>
        <span class="archive-item__value">{{ stat ? stat.rate : '0' }}%</span>
      </div>
    </div>
    <div class="archive-item__footer" v-if="restorable">
      <ez-link
        class="archive-item__restore"
        data-test="restore-variation"
        @click.prevent="$emit('restore')"
      >
        {{ $t("builder.archive.restoreVariation") }}
      </ez-link>
    </div>
  </div>
</template>
