<template>
  <img :src="src" alt="" />
</template>

<script>
import {
  ACTIVE_CAMPAGIN,
  CONTACTS,
  GET_RESPONSE,
  KLICK_TIPP,
  MAIL_CHIMP,
  QUENTIN,
  FOUR_LEADS,
} from '@/enums/actionTypes';

const IMAGES_MAP = new Map([
  [ACTIVE_CAMPAGIN, 'activecampaign.png'],
  [CONTACTS, 'ezcontacts.png'],
  [GET_RESPONSE, 'getresponse.png'],
  [KLICK_TIPP, 'klick-tipp.png'],
  [MAIL_CHIMP, 'mailchimp.png'],
  [QUENTIN, 'quentn.png'],
  [FOUR_LEADS, '4leads.png'],
]);

const images = require.context('@/assets/actions', false, /\.png$/);

export default {
  name: 'EzfBuilderActionImage',
  props: {
    actionType: {
      type: Number,
      required: true,
      validator: (type) => IMAGES_MAP.has(type),
    },
  },
  computed: {
    src() {
      const image = IMAGES_MAP.get(this.actionType);
      return image && images(`./${image}`);
    },
  },
};
</script>
