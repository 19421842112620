<script>
import { mapState, mapMutations } from 'vuex';
import funnelsManager from '@/services/funnelsManager';
import { SET_FUNNEL_ATTRIBUTE } from '@/store/funnel/mutations';

export default {
  name: 'EzfTemplateResetButton',
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState('funnel', ['funnel']),
  },
  methods: {
    ...mapMutations('funnel', { setAttr: SET_FUNNEL_ATTRIBUTE }),
    async reset() {
      try {
        this.loading = true;
        const data = await funnelsManager.resetWebinarLoginTemplate(this.funnel.id);
        if (data?.id) {
          this.setAttr({
            attr: 'webinarLoginPageTemplateId',
            value: data.id,
          });
        }
      } catch (e) {
        this.$notify({
          data: {
            type: 'danger',
            content: e.message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <ez-button
    :preloader="loading"
    icon="trash"
    @click="reset"
  />
</template>
