import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import getApiAxios from './lib/apiAxios';

/**
 * Class for manupulate contacts with ez-builder API.
 * @module ContactsRepository
 */
class ContactsRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   */
  constructor(axiosInstance) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${URL_API_CONTACTS}/v1/` });
  }

  /**
   * get all active contacts by funnel id
   *
   * @param {*} params
   */
  async getFunnelContacts({
    funnelId, order, sort, page, limit, filter, search,
  }) {
    const response = await this.axios.get('contacts', {
      params: {
        'filter[contacts.funnels][and][funnel][eq]': funnelId,
        'page[number]': page,
        'page[size]': limit,
        include: 'funnels',
        sort: `${order === 'ascending' ? '' : '-'}${sort}`,
        ...filter,
        search,
      },
    });
    return response.data;
  }

  /**
   * get all active contacts by funnel id
   * @param {String} contactId
   */
  async getStatisticPurchases({ contactId, funnelId }) {
    const { data } = await this.axios.get('statistic/purchases', {
      params: {
        'filter[purchases][and][contact][eq]': contactId,
        'filter[purchases][and][funnel][eq]': funnelId,
      },
    });
    return data;
  }

  async deleteContact(id) {
    const result = await this.axios.delete(`/contacts/${id}`);
    return result.data;
  }

  async subscribeContacts(contactsIds = []) {
    const response = await this.axios.post('bulk/subscribe-contacts', {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((id) => ({ type: 'contacts', id })),
          },
        },
        type: 'bulk-subscribe-contacts',
      },
    });

    return response.data;
  }

  async unsubscribeContacts(contactsIds = []) {
    const response = await this.axios.post('bulk/unsubscribe-contacts', {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((id) => ({ type: 'contacts', id })),
          },
        },
        type: 'bulk-unsubscribe-contacts',
      },
    });

    return response.data;
  }

  async getTags(userId) {
    const { data } = await this.axios.get('tags', {
      params: {
        'filter[user][eq]': userId,
      },
    });
    return data;
  }

  async createExport({
    filters = {},
    format = 'xlsx',
    tags = false,
    fields = ['contact_id', 'email', 'first_name', 'last_name'],
    funnel = null,
  }) {
    const { data } = await this.axios.post('/contacts/export', {
      data: {
        format,
        filters,
        fields,
        tags,
        funnel,
      },
    });
    return data.data;
  }

  async checkExport(exportId) {
    const { data } = await this.axios.get(`/contacts/export/${exportId}`);
    return data.data;
  }
}

export default ContactsRepository;
