export default {
  name: String,
  canCreateVariation: {
    default: true,
  },
  isOptionalStep: {
    default: false,
  },
  step: {
    type: Object,
    required: true,
  },
  withProduct: {
    type: Boolean,
    default: false,
  },
};
