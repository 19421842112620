<script>
import { createNamespacedHelpers } from 'vuex';
import { UPSELL, DOWNSELL } from '@/enums/stepTypes';
import ezBuilderGroup from './group.vue';

// steps
import ezSortableSteps from '../steps/sortable.vue';
import ezStepOrderForm from '../steps/order-form.vue';
import ezStepUpsell from '../steps/upsell.vue';
import ezStepCreateUpsell from '../steps/create-upsell.vue';

const { mapState } = createNamespacedHelpers('builder');

//  vuex

export default {
  name: 'EzfBuilderGroupSales',
  inject: ['isBuilder', 'isArchive'],
  components: {
    ezBuilderGroup,
    ezSortableSteps,
    ezStepOrderForm,
    ezStepCreateUpsell,
    ezStepUpsell,
  },
  data() {
    return { ezStepUpsell };
  },
  computed: {
    ...mapState(['steps']),
    upsellPages() {
      const result = [];
      let row;
      this.steps.forEach((step, index) => {
        switch (step.attributes.type) {
          case UPSELL:
            row = { step, index };
            result.push(row);
            break;
          case DOWNSELL:
            row.downsell = { step, index };
            break;
          default:
        }
      });
      return result;
    },
  },
};
</script>

<template>
  <ez-builder-group :name="$t('builder.groups.sales')" color="yellow">
    <ez-step-order-form />

    <template v-if="upsellPages.length">
      <ez-sortable-steps
        v-if="isBuilder"
        :steps="upsellPages"
        :component="ezStepUpsell"
      />
      <ez-step-upsell
        v-else
        v-for="props in upsellPages"
        :key="props.step.attributes.id"
        v-bind="props"
      />
    </template>

    <div v-if="isBuilder || isArchive" class="builder-col_with-button">
      <div class="builder-col__side"></div>
    </div>

    <ez-step-create-upsell v-if="isBuilder" style="margin-right: 0" />
    <div v-else-if="isArchive" class="builder-col" style="margin-right: 0">
      <div class="builder-col__items"></div>
    </div>
  </ez-builder-group>
</template>
