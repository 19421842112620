<script>
import { createNamespacedHelpers } from 'vuex';
import { DELETE_STEP } from '@/store/builder/actions';
import ezBuilderStep from './step.vue';
import ezBuilderStepPlus from './plus.vue';
import ezBuilderItemPage from '../items/page/page.vue';
import ezBuilderItemActions from '../items/actions/actions.vue';

// import { BRIDGE } from "@/enums/stepTypes";

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderStepBridge',
  inject: ['isBuilder', 'isArchive'],
  props: {
    index: {
      type: Number,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    ezBuilderStep,
    ezBuilderStepPlus,
    ezBuilderItemPage,
    ezBuilderItemActions,
  },
  methods: {
    ...mapActions([DELETE_STEP]),
    deleteStep() {
      const { index, step } = this;
      this[DELETE_STEP]({ index, step });
    },
  },
};
</script>

<template>
  <ez-builder-step
    :class="{
      'builder-col_with-button': isBuilder || isArchive
    }"
    :step-id="step.attributes.id"
  >
    <template slot="plus">
      <ez-builder-step-plus v-if="isBuilder" :index="index" />
      <div v-else-if="isArchive" class="builder-col__side" />
    </template>
    <ez-builder-item-page
      name="bridge"
      :is-optional-step="true"
      :step="step"
      @delete="deleteStep"
    />
    <ez-builder-item-actions
      v-if="isBuilder"
      :step="step"
      :index="index"
    />
  </ez-builder-step>
</template>
