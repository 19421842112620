<script>
import { createNamespacedHelpers, mapMutations } from 'vuex';
import { COUNT } from '@/store/notifications/getters.js';
import { ADD_ERROR, REMOVE_ERROR } from '@/store/builder/mutations';
import webinarsManager from '@/services/webinarsManager';
import { getWebinarUrl } from '@/services/urls';
import showWebinarLinksDialog from './links-dialog';

const { mapState } = createNamespacedHelpers('funnel');
const { mapGetters } = createNamespacedHelpers('notifications');

export default {
  name: 'EzfFunnelWebinarHeader',
  props: ['loading'],
  data() {
    return {
      webinar: null,
      name: '',
      isWebinarLoading: true,
    };
  },
  computed: {
    ...mapGetters({ count: COUNT }),
    ...mapState(['funnel']),
    errorId() {
      return `${this.funnel.id}:webinar`;
    },
  },
  methods: {
    showWebinarLinksDialog,
    ...mapMutations('builder', {
      addError: ADD_ERROR,
      removeError: REMOVE_ERROR,
    }),
    setWebinarIsNotConnected() {
      this.addError({
        message: this.$t('builder.noConnectedWebinarError'),
        type: 'webinar',
        id: this.funnel.id,
        errorId: this.errorId,
        selector: '.funnel-webinar-box',
      });
    },
  },
  watch: {
    'funnel.webinarId': {
      async handler(webinarId) {
        this.removeError(this.errorId);
        this.webinar = null;
        this.url = '';
        if (!webinarId) {
          this.isWebinarLoading = false;
          this.setWebinarIsNotConnected();
          return null;
        }
        try {
          this.isWebinarLoading = true;
          const webinar = await webinarsManager.get(webinarId);
          if (webinar.archive) {
            this.setWebinarIsNotConnected();
            return null;
          }
          this.url = getWebinarUrl(webinarId);
          this.webinar = webinar;
        } catch (e) {
          if (e.response?.status === 404) {
            this.setWebinarIsNotConnected();
            return;
          }
          this.$notify({
            data: {
              type: 'error',
              content: `Failed to load webinar: ${e.message}`,
            },
          });
        } finally {
          this.isWebinarLoading = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-row class="webinar__header box" :gutter="0">
    <ez-col
      v-if="isWebinarLoading"
      class="webinar__header-hint"
      size="18"
    >
      <div class="webinar__header-hint-text loading">
        {{ $t("Loading…") }}
      </div>
    </ez-col>
    <template v-else-if="webinar">
      <ez-col class="webinar__header-hint" style="border-right: none" size="14">
        <div class="webinar__header-hint-text">
          <i18n path="builder.connectedWebinar" tag="span">
            <ez-link
              class="webinar__header-hint-link"
              :href="url"
              target="_blank"
            >
              {{ webinar.name }}
            </ez-link>
          </i18n>
        </div>
      </ez-col>
      <ez-col size="4" class="webinar__header-hint">
        <ez-button
          type="primary"
          native-type="button"
          :disabled="!webinar"
          @click.prevent="webinar && showWebinarLinksDialog({ webinar })"
        >
          {{ $t("webinarLinksDialog.openButton") }}
        </ez-button>
      </ez-col>
    </template>
    <ez-col v-else size="18" class="webinar__header-hint">
      <div class="webinar__header-hint-text no-connected-webinar">
        {{ $t("builder.noConnectedWebinar") }}
      </div>
    </ez-col>
    <ez-col class="webinar__notifications" size="5">
      <div class="webinar__notifications-text">
        <ez-icon
          class="webinar__notifications-bell"
          name="bell"
          size="22"
        ></ez-icon>
        <ez-icon
          class="webinar__notifications-new"
          name="circle"
          size="10"
        ></ez-icon>
        <span class="webinar__notifications-hint" v-if="!loading">
          {{ $tc("builder.notification.count", count) }}
        </span>
      </div>
    </ez-col>
    <ez-col class="webinar__toggle-button" size="1">
      <a
        class="webinar__toggle-button-link"
        href="#"
        @click.prevent="$emit('toggle')"
      >
        <ez-icon
          class="webinar__toggle-button-icon"
          name="chevron-down"
        ></ez-icon>
      </a>
    </ez-col>
  </ez-row>
</template>

<style lang="scss" scoped>
@import "@dmant/ez-ui-components/src/components/core/src/_variables.scss";
.no-connected-webinar {
  color: $color-danger;
}
.loading {
  color: $color-secondary;
}
</style>
