import { show } from '@/services/modal';
import ezfFunnelWebinarLinksDialog from './dialog.vue';

import NAME from './name.json';

export default function webinarLinksDialog(props) {
  return new Promise(((resolve) => {
    show(
      ezfFunnelWebinarLinksDialog,
      props,
      {
        name: NAME,
        height: 'auto',
        width: 680 /* "big" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'dialog-links', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
