<script>
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { GET_STEP_URL } from '@/store/builder/getters';
import {
  WEBINAR_COUNTDOWN,
  WEBINAR_ROOM_CARD,
  WEBINAR_REPLAY,
} from '@/enums/stepTypes';
import ezBuilderStepWithBridges from './step-with-bridges.vue';
import ezBuilderItemPage from '../items/page/page.vue';
import ezBuilderItemActions from '../items/actions/actions.vue';
import ezBuilderItemIncomplete from '../items/incomplete.vue';

const { mapGetters, mapState } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderStepWebinar',
  inject: ['isBuilder', 'withBuilderGroupHeaders'],
  components: {
    ezBuilderStepWithBridges,
    ezBuilderItemIncomplete,
    ezBuilderItemActions,
    ezBuilderItemPage,
  },
  computed: {
    ...mapFields('builder', ['webinarBoxExpanded']),
    ...mapState([
      'steps',
    ]),
    webinarItemsFull() {
      const steps = [
        {
          type: WEBINAR_COUNTDOWN,
          name: 'countdown',
          withActions: false,
        },
        {
          type: WEBINAR_ROOM_CARD,
          name: 'webinar',
          withActions: true,
        },
        {
          type: WEBINAR_REPLAY,
          name: 'replay',
          withActions: true,
        },
      ];
      for (const item of steps) {
        item.index = this.steps.findIndex(
          (step) => step.attributes.type === item.type,
        );
        item.step = this.steps[item.index];
      }
      return steps;
    },
    incomplete() {
      return this.isBuilder && this.webinarItemsFull.find((item) => {
        const { variations } = item.step;
        if (variations.length === 0) {
          return true;
        }
        return variations.some((variation) => !variation.template);
      })?.step;
    },
    webinarBoxStyle() {
      const width = this.webinarItems.length * 255 + 16;

      return {
        width: `${width}px`,
      };
    },
    webinarItems() {
      if (this.webinarBoxExpanded) {
        return this.webinarItemsFull;
      }
      return this.webinarItemsFull.slice(1, 2);
    },
    webinarRoomStep() {
      return this.webinarItemsFull[1].step;
    },
    ...mapGetters([GET_STEP_URL]),
    previewUrl() {
      return this[GET_STEP_URL](this.webinarRoomStep);
    },
  },
  methods: {
    toggle() {
      this.webinarBoxExpanded = !this.webinarBoxExpanded;
    },
    scrollIntoView(/* e */) {
      // if (this.webinarBoxExpanded) {
      //   e.target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      // }
    },
  },
};
</script>

<template>
  <ez-builder-step-with-bridges
    :index="webinarItemsFull[0].index"
    class="builder-col_expandable"
  >
    <ez-builder-item-incomplete
      v-if="incomplete"
      :step="incomplete"
      :title="$t('builder.incomplete.noTemplateConnected')"
      data-test="no-template-connected"
    />
    <div :class="{ 'builder__group-item': withBuilderGroupHeaders }">
      <div
        :class="{ 'webinar-box': withBuilderGroupHeaders }"
        :style="webinarBoxStyle"
        @transitionend="scrollIntoView"
      >
        <div class="webinar-box__header" v-if="withBuilderGroupHeaders">
          <h3 class="webinar-box__title">Webinar</h3>
          <div class="webinar-box__actions">
            <ez-link class="webinar-box__action" @click.prevent="toggle">
              <ez-icon name="compress" v-if="webinarBoxExpanded"></ez-icon>
              <ez-icon name="expand" v-else></ez-icon>
            </ez-link>
            <ez-dropdown v-if="isBuilder">
              <ez-link class="webinar-box__action" @click.prevent>
                <ez-icon name="ellipsis-h"></ez-icon>
              </ez-link>
              <ez-dropdown-menu slot="menu">
                <ez-dropdown-item
                  class="no-break"
                  icon="eye"
                  :href="previewUrl"
                  target="_blank"
                >
                  {{ $t("View") }}
                </ez-dropdown-item>
                <!-- <ez-dropdown-item icon="edit">Edit</ez-dropdown-item> -->
              </ez-dropdown-menu>
            </ez-dropdown>
          </div>
        </div>
        <div
          :class="
            withBuilderGroupHeaders ? 'webinar-box__objects' : 'container-wrap'
          "
        >
          <div
            :class="
              withBuilderGroupHeaders ? 'webinar-box__object' : 'builder-col'
            "
            v-for="(item, index) in webinarItems"
            :key="item.type"
            :data-id="item.step.attributes.id"
          >
            <ez-builder-item-page
              :step="item.step"
              :title="item.title"
              :subtitle="item.subtitle"
              :can-create-variation="webinarBoxExpanded"
            />
            <ez-builder-item-actions
              v-if="isBuilder && webinarBoxExpanded && item.withActions"
              :step="item.step"
              :index="(webinarItemsFull[0].index - 1) + index"
            />
          </div>
        </div>
      </div>
    </div>
  </ez-builder-step-with-bridges>
</template>

<style>
.builder-col_expandable > .builder-col_with-bridges,
.is-service-funnels .builder-col_expandable .builder-col_with-bridges .builder-col__main {
  width: auto;
}
</style>

<style scoped>
.container-wrap {
  display: flex;
}
</style>
