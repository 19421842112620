<script>
import templatePreview from '@/helpers/templatePreview';
import builderManager from '@/services/builderManager';
import EzfSpecialPage from '@/components/special-page.vue';
import settingsSectionFactory from '../../mixins/settings-section-factory';

export default {
  name: 'EzfSettingsSectionImprintAndPrivacyPolicy',
  mixins: [
    settingsSectionFactory(
      'useImprintFromProfile',
      'usePrivacyPolicyFromProfile',
      'imprintTemplateId',
      'privacyTemplateId',
      'imprint',
      'privacyPolicy',
      'webinarLoginPageTemplateId',
    ),
  ],
  data() {
    return {
      templates: [],
    };
  },
  components: { EzfSpecialPage },
  computed: {
    profileImprint() {
      return this.$store.state.mainUser.attributes.imprint;
    },
    profilePrivacyPolicy() {
      return this.$store.state.mainUser.attributes['privacy-policy'];
    },
    attrs() {
      const result = ['useImprintFromProfile', 'usePrivacyPolicyFromProfile'];
      if (!this.useImprintFromProfile) {
        result.push('imprint');
      }
      if (!this.usePrivacyPolicyFromProfile) {
        result.push('privacyPolicy');
      }
      return result;
    },
    templateIds() {
      return [
        { type: 'imprintTemplate', id: this.imprintTemplateId },
        { type: 'privacyTemplate', id: this.privacyTemplateId },
        { type: 'webinarLoginTemplate', id: this.webinarLoginPageTemplateId },
      ];
    },
    pages() {
      return this.templates.map(({
        type, templateId, date, attributes,
      }) => ({
        type,
        templateId,
        title: this.$t(`settings.${type}.title`),
        description: this.$t(`settings.${type}.description`),
        name: this.$t(`settings.${type}.name`),
        date: this.$t('settings.lastEdited', { date }),
        image: templatePreview(attributes, 500, date, this.funnel && this.funnel.locale),
      }));
    },
  },
  watch: {
    templateIds: {
      async handler(ids) {
        const result = await builderManager.getTemplatesByIds(ids.map(({ id }) => id));
        this.templates = [];
        for (const { type, id: templateId } of ids) {
          const template = result.data.find(({ id }) => id === templateId);
          if (template) {
            this.templates.push({
              type,
              templateId,
              date: template.attributes && template.attributes['dt-update'],
              attributes: template.attributes,
            });
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-settings-fieldset
    :legend="$t('settings.specialPages')"
    :is-changed="isChanged"
    @discard="discard"
    @save="save"
  >
    <ez-row class="mb-4" gutter="16">
      <ez-col
        v-for="(page, index) in pages"
        :key="`template-${index}`"
        :size="{ md: 12, lg: 8 }"
      >
        <EzfSpecialPage class="mb-4" v-bind="page"/>
      </ez-col>
    </ez-row>
  </ez-settings-fieldset>
</template>

<style scoped>
.panel-collapse__switch {
  text-align: right;
}
</style>
