<script>
import EzfTooltip from '@/components/tooltip.vue';
import showActionDialog from './dialog';
import EzfBuilderAction from './action.vue';

export default {
  name: 'EzfBuilderItemActions',
  props: {
    step: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    EzfTooltip,
    EzfBuilderAction,
  },
  data() {
    return {
      actions: [].concat(this.step.actions || []),
    };
  },
  methods: {

    async addAction() {
      const { response } = await showActionDialog(this.step, {
        attributes: {
          'is-active': true,
          trigger: null,
          'action-type': null,
          time: '00:00:00',
        },
      });
      if (response) {
        this.actions.push(response.data);
      }
    },

    async editAction(action) {
      const { result, response } = await showActionDialog(this.step, action);
      if (!result) {
        return;
      }
      const index = this.actions.indexOf(action);
      if (index !== -1) {
        switch (result) {
          case 'updated':
            this.$set(this.actions, index, response.data);
            break;
          case 'removed':
            this.actions.splice(index, 1);
            break;
          default:
        }
      }
    },
  },
};
</script>

<template>
  <div class="builder__group-item">
    <div class="builder-list">
      <div class="builder-list__header">
        <ez-link class="builder-list__hint" @click.prevent="toggle">{{
          $t("builder.actions.item")
        }}</ez-link>
        <span class="builder-list__hint-question">
          <ezf-tooltip theme="dark" :html="$t('builder.actions.tooltip')" />
        </span>
      </div>
      <div class="builder-list__body">
        <div class="builder-list__container">
          <ezf-builder-action
            v-for="action in actions"
            :key="action.id"
            :action="action"
            :index="index"
            @edit="editAction(action)"
          />

          <ez-button
            class="builder-list__btn-add"
            type="light"
            outline
            block
            @click.prevent="addAction"
            :data-test="`actions-add-${index}`"
          >
            {{ $t("builder.actions.add") }}
          </ez-button>
        </div>
      </div>
    </div>
  </div>
</template>
