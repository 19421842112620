import { show } from '@/services/modal.js';
import EzStepDialog from './dialog.vue';

export const NAME = 'step';

export default function selectTemplateDialog(params) {
  return new Promise(((resolve) => {
    show(
      EzStepDialog,
      params,
      {
        name: NAME,
        height: 'auto',
        // width: 820 /* "is-xbig" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
