import { show } from '@/services/modal';
import ezfFunnelWebinarNotificationDialog from './dialog.vue';

import NAME from './name.json';

export default function webinarNotificationDialog(props) {
  return new Promise(((resolve) => {
    show(
      ezfFunnelWebinarNotificationDialog,
      props,
      {
        name: NAME,
        height: 'auto',
        // width: 600px /* "xmedium" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'add-email', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
