import { URL_BUILDER, URL_CONTACTS, URL_WEBINAR } from '@dmant/ez-env-common';

export function getWebinarUrl(webinarId) {
  return `${URL_WEBINAR}/wizard/${webinarId}/general`;
}

export function getEditTemplateUrl(templateId) {
  return `${URL_BUILDER}/template/${templateId}`;
}

export function getBuilderUrl(funnelId, templateId) {
  return `${URL_BUILDER}/funnel/${funnelId}/${templateId}`;
}

export function getContactUrl(contactId) {
  return `${URL_CONTACTS}/contact/${contactId}`;
}
