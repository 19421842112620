<script>
import { WEBINAR_REGISTRATION } from '@/enums/stepTypes';
import ezBuilderStepWithBridges from './step-with-bridges.vue';
import ezBuilderItemPage from '../items/page/page.vue';
import ezBuilderItemActions from '../items/actions/actions.vue';
import ezBuilderItemConfirmation from '../items/confirmation.vue';
import stepMixinFactory from './mixins/step-factory';

export default {
  name: 'EzfBuilderStepWebinarRegistration',
  mixins: [stepMixinFactory(WEBINAR_REGISTRATION)],
  components: {
    ezBuilderStepWithBridges,
    ezBuilderItemPage,
    ezBuilderItemActions,
    ezBuilderItemConfirmation,
  },
};
</script>

<template>
  <ez-builder-step-with-bridges
    :index="index"
    :step-id="step.attributes.id"
  >
    <ez-builder-item-page name="webinarRegistration" :step="step" />
    <ez-builder-item-actions
      v-if="isBuilder"
      :step="step"
      :index="index"
    />
    <ez-builder-item-confirmation :parent-index="index" />
  </ez-builder-step-with-bridges>
</template>
