<script>
import formatters from '@/services/formatters';
import allFields from './fields';
import EzfContactsOrderedProductsButton from './ordered-products/button.vue';
import EzfContactsDropdown from './dropdown';

export default {
  name: 'EzfFunnelContactsTable',
  components: {
    EzfContactsOrderedProductsButton,
    EzfContactsDropdown,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    sort: {
      type: Object,
    },
  },
  computed: {
    sortProp() {
      const { sort } = this.sort;
      const field = this.allFields.find((item) => item.sort === sort);
      return field ? field.name : sort;
    },
    activeFields() {
      return this.allFields.filter((item) => item.visible);
    },
  },
  data() {
    return {
      allFields,
      multipleSelection: [],
      localizedFieldNames: {
        'first-name': this.$t('contact-fields.first-name'),
        'last-name': this.$t('contact-fields.last-name'),
        email: this.$t('contact-fields.e-mail'),
        phone: this.$t('contact-fields.phone'),
        country: this.$t('contact-fields.country'),
        'page-visits': this.$t('contact-fields.page-visits'),
        'unique-page-visits': this.$t('contact-fields.unique-page-visits'),
        'webinars-registered': this.$t('contact-fields.webinars-registered'),
        'funnel-purchases': this.$t('contact-fields.ordered-products'),
        memberships: this.$t('contact-fields.memberships'),
        'completed-lessons': this.$t('contact-fields.completed-lessons'),
        'completed-courses': this.$t('contact-fields.completed-courses'),
        'activity-level': this.$t('contact-fields.activity-level'),
        type: this.$t('contact-fields.type'),
        logins: this.$t('contact-fields.number-of-logins'),
        sessions: this.$t('contact-fields.number-of-sessions'),
        'mails-received': this.$t('contact-fields.mails-received'),
        'webinars-attended': this.$t('contact-fields.memberships'),
        revenue: this.$t('contact-fields.revenue'),
        'optin-date': this.$t('contact-fields.optin-date'),
        'funnel-completed': this.$t('contact-fields.funnel-completed'),
        'dt-create': this.$t('contact-fields.dt-create'),
        'empty-search': this.$t('contact-fields.empty-search'),
      },
      settings: false,
      tempFields: [],
    };
  },
  filters: {
    normalText(value) {
      const name = value
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
        .toLowerCase();

      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
  methods: {
    sortChange(column) {
      const field = this.allFields.find((item) => item.name === column.prop);
      if (field) {
        column.sort = field.sort;
      }
      this.$emit('sort-change', column);
    },
    rowClassName({ row }) {
      let classes = [];
      if (this.multipleSelection.some((selected) => selected === row)) {
        classes.push('is-selected');
      }

      if (row.attributes.activityStatus === 'disable') {
        classes.push('is-disabled');
      }

      if (row.attributes.limited) {
        classes.push('is-limited');
        classes = classes.filter((item) => item !== 'is-selected');
      }

      return classes.join(' ');
    },

    handleSelectionChange(value) {
      this.multipleSelection = value;
    },

    saveFields() {
      this.activeFields = this.tempActiveFields;
    },

    saveTempFields() {
      this.settings = false;
      let updatedFieldsCount = 0;
      for (let i = 0; i < this.tempFields.length; i++) {
        const actualField = this.allFields[i];
        const tempField = this.tempFields[i];
        if (tempField != null && actualField != null) {
          if (actualField.visible !== tempField.visible) {
            updatedFieldsCount++;
          }
          actualField.visible = tempField.visible;
        }
      }
      if (updatedFieldsCount) {
        this.saveFields();
      }
    },

    getContactFieldValue(contact, field) {
      if (field.name === 'funnel-completed') {
        if (!contact.funnel) {
          return 'N/A';
        }
        return contact.funnel.attributes.completed ? 'Yes' : 'No';
      }
      if (field.formatter && formatters[field.formatter]) {
        const formatter = formatters[field.formatter];
        return formatter(contact.attributes[field.name]);
      }
      return contact.attributes[field.name];
    },
  },
};
</script>

<template>
  <ez-table
    class="table"
    :data="contacts"
    row-key="id"
    :empty-text="localizedFieldNames['empty-search']"
    stripe
    :default-sort="{ prop: sortProp, order: sort.order }"
    @selection-change="handleSelectionChange"
    @sort-change="sortChange"
    :row-class-name="rowClassName"
  >
    <ez-table-column type="selection" fixed :width="40" />

    <ez-table-column
      prop="contact"
      fixed
      sortable="custom"
      :label="$t('contact-fields.contact')"
      :min-width="135"
    >
      <template slot-scope="scope">
        <div>
          {{ scope.row.attributes["first-name"] }}
          {{ scope.row.attributes["last-name"] }}
        </div>
        <span>{{ scope.row.attributes.email }}</span>
      </template>
    </ez-table-column>

    <ez-table-column
      v-for="(item, index) in activeFields"
      :sortable="item.sortable ? 'custom' : null"
      :width="155"
      :key="index"
      :prop="item.name"
      :label="localizedFieldNames[item.name] || item.displayName"
    >
      <template slot-scope="scope">
        <template v-if="item.name === 'funnel-purchases'">
          <ezf-contacts-ordered-products-button
            v-if="scope.row.funnel.attributes.purchases"
            :value="scope.row.funnel.attributes.purchases"
            :id="scope.row.id"
          />
          <template v-else>
            -
          </template>
        </template>
        <template v-else>
          {{ getContactFieldValue(scope.row, item) }}
        </template>
      </template>
    </ez-table-column>

    <ez-table-column prop="firstName" fixed="right" :width="40">
      <!--
      <template slot="header" slot-scope="{}">
        <div class="settings">
          <ez-dropdown @input="$emit('onColumnsMenuDisplayChange')">
            <ez-icon name="cog" size="20" color="#8095AA" />
            <ez-dropdown-menu slot="menu">
              <template slot="header">{{
                $t("contacts.columns-dropdown-label")
              }}</template>
              <ez-dropdown-item
                v-for="(item, index) in tempFields"
                :key="index"
                outer-event
              >
                <template slot="custom">
                  <ez-checkbox v-model="item.visible">
                    {{ localizedFieldNames[item.name] || item.displayName }}
                  </ez-checkbox>
                </template>
              </ez-dropdown-item>
              <template slot="controls" slot-scope="{ active }">
                <ez-button-group justify="center">
                  <ez-button
                    type="primary"
                    size="small"
                    @click="
                      saveTempFields();
                      active(false);
                    "
                    >{{
                      $t("contacts.columns-save-selection-button")
                    }}</ez-button
                  >
                </ez-button-group>
              </template>
            </ez-dropdown-menu>
          </ez-dropdown>
        </div>
      </template>
      -->
      <template slot-scope="scope">
        <div class="settings">
          <ezf-contacts-dropdown
            :row="scope.row"
            @subscribe-single-user-click="
              $emit('subscribe-single-user-click', scope.row)
            "
            @unsubscribe-single-user-click="
              $emit('unsubscribe-single-user-click', scope.row)
            "
            @delete-single-user-click="
              $emit('delete-single-user-click', scope.row)
            "
          />
        </div>
      </template>
    </ez-table-column>
  </ez-table>
</template>
