<script>
import EzfSelectWebinar from '@/components/select-webinar.vue';
import settingsSectionFactory from '@/pages/funnel/settings/mixins/settings-section-factory.js';

export default {
  name: 'EzfFunnelWebinarChange',
  components: {
    EzfSelectWebinar,
  },
  mixins: [settingsSectionFactory('webinarId')],
};
</script>

<template>
  <div class="webinar__select box box_bordered">
    <ezf-select-webinar
      :label="$t('builder.changeWebinar')"
      v-model="webinarId"
      :readonly="isSaving"
    />
    <ez-button-group v-if="isChanged">
      <ez-button type="secondary mr-2" @click="discard" :disabled="isSaving">
        {{ $t("builder.buttons.discard") }}
      </ez-button>
      <ez-button
        type="success"
        @click="save"
        :disabled="isSaving"
        :preloader="isSaving"
      >
        {{ $t("builder.buttons.apply") }}
      </ez-button>
    </ez-button-group>
  </div>
</template>
