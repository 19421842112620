<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ezTable from '@dmant/ez-ui-components/src/components/table';
import ezTableColumn from '@dmant/ez-ui-components/src/components/table/table-column';

import { GET_FUNNEL } from '@/store/funnel/getters';
import {
  LOAD_GEOLOCATION_DATA,
  LOAD_WIDGETS_DATA,
  LOAD_REVENUE_DATA,
  LOAD_USERS_VIEWS_DATA,
  LOAD_WEBINAR_USERS_VIEWS_DATA,
  LOAD_STEPS_DATA,
} from '@/store/funnelStatistic/actions';
import {
  GET_WIDGETS_DATA,
  GET_USERS_VIEWS_DATA,
  GET_WEBINAR_USERS_VIEWS_DATA,
  GET_GEOLOCATION_DATA,
  GET_REVENUE_DATA,
} from '@/store/funnelStatistic/getters';

import EzfChart from '@/components/chart/chart.vue';
import EzfChartWebinar from '@/components/chart/chartWebinar.vue';
import EzfGroups from '@/components/chart/groups.vue';
import ezfTable from '@/components/table/table';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';
import geolocationMixin from '@/components/table/mixins/geolocation';
import statisticsStepComponent from './statistics-step.vue';
import stepComponents from './step-components.js';
import EzfBuilderMap from './builder/components/map.vue';

stepComponents.set('statistics', statisticsStepComponent);

export default {
  name: 'EzfPageStatistics',
  mixins: [geolocationMixin],

  components: {
    ezLoadingBlock,
    EzfChart,
    EzfChartWebinar,
    EzfGroups,
    ezfTable,
    ezTable,
    ezTableColumn,
    EzfBuilderMap,
  },

  data() {
    return {
      isChartLoading: true,
      isWebinarChartLoading: true,
      iswidgetsLoading: true,
      isRevenueLoading: true,
      part: 'week',
      pageChartData: [],
      activeFilterArray: [],
      allFilters: [
        {
          name: this.$t('timeline.chat'),
          type: 'live-chats',
          active: false,
          color: '#ccc',
        },
        {
          name: this.$t('timeline.feedbackFlow'),
          type: 'feedbacks',
          active: false,
          color: '#3D82CF',
        },
        {
          name: this.$t('timeline.stickyMessage'),
          type: 'sticky-messages',
          active: false,
          color: '#FFF275',
        },
        {
          name: this.$t('timeline.salesAlert'),
          type: 'sales',
          active: false,
          color: '#EE5253',
        },
        {
          name: this.$t('timeline.file'),
          type: 'files',
          active: false,
          color: '#26C281',
        },
        {
          name: this.$t('timeline.poll'),
          type: 'polls',
          active: false,
          color: '#00ADAB',
        },
        {
          name: this.$t('timeline.offer'),
          type: 'offers',
          active: false,
          color: '#6546AF',
        },
      ],
      filters: [],
    };
  },

  computed: {
    ...mapFields('builder', ['webinarBoxExpanded']),
    ...mapState('webinar', ['timeline', 'duration']),
    ...mapGetters('funnel', { funnel: GET_FUNNEL }),
    ...mapGetters('funnelStatistic', {
      getWidgetsData: GET_WIDGETS_DATA,
      getChartData: GET_USERS_VIEWS_DATA,
      getWebinarChartData: GET_WEBINAR_USERS_VIEWS_DATA,
      getGeolocation: GET_GEOLOCATION_DATA,
      getRevenueData: GET_REVENUE_DATA,
    }),

    webinarMaxValue() {
      let max = 0;
      this.getWebinarChartData.forEach((item) => {
        if (item.value > max) max = item.value;
      });
      return max + 10;
    },

    combinedWebinarData() {
      function getLinearDots(dots, timelineDot) {
        const x = timelineDot.time;// * 1500; // fixme
        const index = dots
          .findIndex((item, i) => dots[i + 1] && x >= item[0] && x <= dots[i + 1][0]);
        if (index > -1) {
          const bigX = dots[index + 1][0] - dots[index][0];
          const bigY = dots[index + 1][1] - dots[index][1];
          const smallX = x - dots[index][0];
          const smallY = (bigY / bigX) * smallX;
          return dots[index][1] + ((Number.isNaN(smallY)) ? 0 : smallY);
        }
        return dots[dots.length - 1][1];
      }

      const lineDots = [...this.getWebinarChartData];
      const { timeline } = this.$store.state.webinar;
      const timelineDots = [];

      timeline.filter(({ time }) => time <= this.duration).forEach((item) => {
        const filter = this.filters.find((i) => i.type === item.type);
        if (filter) {
          const text = `<span style="color:${filter.color}">${filter.name}:</span><br><p>${item.message}</p>`;
          timelineDots.push([
            item.time, // * 1500, // fixme
            +getLinearDots(lineDots, item).toFixed(1),
            text,
            this.activeFilterArray.length === 0 ? true : this.activeFilterArray.includes(item.type),
            [item.type, filter.color],
          ]);
        }
      });

      const desc = (a, b) => a[0] - b[0];
      return {
        line: lineDots.sort(desc),
        timeline: timelineDots.sort(desc),
      };
    },

    revisionId() {
      return this.funnel && this.funnel.revisionId;
    },

    usersInfo() {
      return ['views', 'unique', 'leads', 'mobile', 'desktop', 'customers'].map(
        (id) => this.getWidgetData(id, this.getWidgetsData),
      );
    },

    revenueInfo() {
      return ['sales', 'revenueMain', 'revenueUpSell', 'revenue'].map(
        (id) => this.getWidgetData(id, this.getWidgetsData),
      );
    },
    pageChartOptions() {
      return {
        tooltip: {
          trigger: 'item',
          position: 'top',
          formatter: `<span>${this.$t('Page Views')}</span><br/><span>{c0}</span>`,
        },
        yAxis: {
          axisPointer: {
            show: false,
          },
        },
        series: [{
          areaStyle: {},
          // itemStyle: {
          //   normal: {
          //     opacity: 0,
          //   },
          //   emphasis: {
          //     opacity: 1,
          //   },
          // },
          symbolSize: 8,
        }],
        color: ['#3C82CF'],
      };
    },
    isZeroYgraph() {
      const condition = (d) => d[1] === 0;
      const { line } = this.combinedWebinarData;
      return line.every(condition);
    },
  },

  watch: {
    revisionId: {
      handler(value) {
        if (value) {
          this.getStatistic();
        }
      },
      immediate: true,
    },
    part() {
      this.pageChartData = this.getChartData(this.part);
    },
  },

  created() {
    this.images = require.context('@/assets/stats/funnel-widgets', false, /\.svg$/);
    this.webinarBoxExpanded = false;
    this.webinarChartOptions = {
      color: ['#3C82CF'],
    };
    this.filters = this.allFilters;// .filter((item) => this.$ability.can('view', item.type));
  },

  methods: {
    ...mapActions('funnelStatistic', {
      loadWidgets: LOAD_WIDGETS_DATA,
      loadChart: LOAD_USERS_VIEWS_DATA,
      loadWebinarChart: LOAD_WEBINAR_USERS_VIEWS_DATA,
      loadGeolocation: LOAD_GEOLOCATION_DATA,
      loadRevenue: LOAD_REVENUE_DATA,
      loadStepsData: LOAD_STEPS_DATA,
    }),

    async onStepsLoaded() {
      await this.loadStepsData();
      this.isRevenueLoading = true;
      await this.loadRevenue();
      this.isRevenueLoading = false;
    },

    getStatistic() {
      this.loadWidgetsData();
      this.loadChartData();
      this.loadGeolocationData();
      if (this.funnel.webinarId) this.loadWebinarChartData();
    },

    async loadWidgetsData() {
      this.iswidgetsLoading = true;
      await this.loadWidgets();
      this.iswidgetsLoading = false;
    },

    async loadChartData() {
      this.isChartLoading = true;
      await this.loadChart();
      this.pageChartData = this.getChartData(this.part);
      this.isChartLoading = false;
    },

    async loadWebinarChartData() {
      this.isWebinarChartLoading = true;
      await this.loadWebinarChart();
      const include = this.filters.map((filter) => filter.type);

      await this.$store.dispatch('webinar/GET_TIMELINE', { include });
      this.isWebinarChartLoading = false;
      this.filters = this.filters
        .filter((i) => this.timeline.some((t) => t.type === i.type));
    },

    async loadGeolocationData() {
      this.beforeLoadGeolocationData();
      await this.loadGeolocation();
      this.afterLoadGeolocationData();
    },

    formatValue(value) {
      return value.toString().replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
    },

    formatNumber(value) {
      return this.formatValue(Math.round(value));
    },

    formatCost(value) {
      const formated = this.formatValue(value.toFixed(2));
      return `${formated}\u00A0${this.currency.symbol}`;
    },

    getWidgetData(id, source) {
      let value = source && source[id] !== undefined ? source[id] : null;
      if (value) {
        const currency = ['revenueMain', 'revenueUpSell', 'revenue'];
        value = currency.includes(id)
          ? this.formatCost(value)
          : this.formatNumber(value);
      }
      return {
        id,
        value,
        text: this.$t(`funnel_widgets.${id}`),
      };
    },

    image(id) {
      return this.images(`./${id}.svg`);
    },

    onFiltersChange(newFilters) {
      this.activeFilterArray = newFilters.filter((i) => i.active).map((i) => i.type);
      this.filters = newFilters;
    },
  },
};
</script>

<template>
  <ez-content>
    <ez-container>
      <div class="statistics">
        <section class="statistics__section users">
          <header class="statistics__section-header">
            <h2 class="statistics__section-title">
              {{ $t("Users") }}
            </h2>
          </header>
          <div class="statistics__section-body">
            <ez-loading-block :loading="iswidgetsLoading">
              <ez-row :gutter="16">
                <ez-col v-for="(item, index) in usersInfo" :key="index">
                  <div class="info-box info-box_big box">
                    <img class="info-box__thumb" :src="image(item.id)" alt />
                    <div class="info-box__text">
                      <span class="info-box__value">{{ item.value }}</span>
                      <span class="info-box__hint">{{ item.text }}</span>
                    </div>
                  </div>
                </ez-col>
              </ez-row>
            </ez-loading-block>

            <ez-row class="users__charts" :gutter="16">
              <ez-col :size="24">
                <ezf-chart
                  :options="pageChartOptions"
                  :data="pageChartData"
                  :loading="isChartLoading"
                  yfield="views"
                >
                  <div :class="$style['chart-page-header']" slot="header">
                    <div :class="$style['title']">{{ $t("Page Views") }}</div>
                    <ezf-groups v-if="pageChartData.length" v-model="part" />
                  </div>
                </ezf-chart>
              </ez-col>
            </ez-row>
          </div>
        </section>

        <section class="statistics__section revenue">
          <header class="statistics__section-header">
            <h2 class="statistics__section-title">
              {{ $t("Revenue") }}
            </h2>
          </header>
          <div class="statistics__section-body">
            <ez-loading-block :loading="iswidgetsLoading">
              <ez-row :gutter="16">
                <ez-col v-for="(item, index) in revenueInfo" :key="index">
                  <div class="info-box info-box_big box">
                    <img class="info-box__thumb" :src="image(item.id)" alt />
                    <div class="info-box__text">
                      <span class="info-box__value">{{ item.value }}</span>
                      <span class="info-box__hint">{{ item.text }}</span>
                    </div>
                  </div>
                </ez-col>
              </ez-row>
            </ez-loading-block>
            <div class="revenue__overview box">
              <h2 class="revenue__overview-title">
                {{ $t("Revenue Overview") }}
              </h2>
              <ez-loading-block :loading="isRevenueLoading">
                <ez-table
                  class="revenue__overview-table"
                  :data="getRevenueData"
                  stripe
                  v-if="getRevenueData.length"
                >
                  <ez-table-column
                    prop="contact"
                    sortable
                    :label="$t('revenue.steps')"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.name }} - {{ scope.row.type }}
                    </template>
                  </ez-table-column>
                  <ez-table-column
                    prop="salesRate"
                    sortable
                    :label="$t('revenue.revenue')"
                  >
                    <template slot-scope="scope">{{
                      scope.row.revenue
                    }}</template>
                  </ez-table-column>
                  <ez-table-column
                    prop="revenue"
                    sortable
                    :label="$t('revenue.sales')"
                  >
                    <template slot-scope="scope">{{
                      scope.row.sales
                    }}</template>
                  </ez-table-column>
                </ez-table>
              </ez-loading-block>
            </div>
          </div>
        </section>

        <section id="steps" class="statistics__section builder">
          <header class="statistics__section-header">
            <h2 class="statistics__section-title">
              Steps
            </h2>
          </header>

          <div class="statistics__section-body">
            <ez-scrollable
              class="builder__scrollable"
              type="horizontal"
              bar-position="top"
              update-on-resize
            >
              <ezf-builder-map step-component="statistics" @loaded="onStepsLoaded" />
            </ez-scrollable>
          </div>
        </section>

        <section class="statistics__section geo" v-if="funnel.webinarId">
          <ez-row class="users__charts" :gutter="16">
            <ez-col :size="24">
              <ezf-chart-webinar
                style="margin-top: -5px;"
                :options="webinarChartOptions"
                :data="combinedWebinarData"
                :loading="isChartLoading"
                :isZeroYgraph="isZeroYgraph"
              >
                <div
                  slot="header"
                  :class="[$style['chart-page-header'], $style['webinar']]"
                >
                  <div :class="$style['title']">
                    {{ $t("Inside you webinar") }}
                  </div>
                  <ez-filter-control
                    v-if="combinedWebinarData.timeline.length && !this.isZeroYgraph"
                    :class="$style['filter']"
                    :filters="filters"
                    @change="onFiltersChange"
                  />
                </div>
              </ezf-chart-webinar>
            </ez-col>
          </ez-row>
        </section>

        <section class="statistics__section geo">
          <header class="statistics__section-header">
            <h2 class="statistics__section-title">
              {{ $t("Geolocation Data") }}
            </h2>
          </header>
          <div class="statistics__section-body box">
            <ez-loading-block :loading="isGeolocatonLoading">
              <ezf-table :meta="meta" :data="getTableData" />
            </ez-loading-block>
          </div>
        </section>
      </div>
    </ez-container>
  </ez-content>
</template>

<style lang="scss" module>
.chart-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  padding-top: 14px;

  &.webinar {
    flex-direction: column;
    align-items: flex-start;

    .filter {
      padding-top: 20px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 7.5px;
      background-color: #3c82cf;
      margin-right: 8px;
    }
  }
}
</style>

<style>
.is-service-funnels .webinar-box {
  width: auto;
}
.is-service-funnels .builder-col.is-width-auto {
  width: auto;
}
.is-service-funnels .graphs__body .webinar__notifications-list {
  margin-top: 0;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.layout.is-service-funnels .info-box_big .info-box__thumb {
  background: none;
}
</style>
