<script>
// groups
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
// import ezBuilderGroupSales from "../groups/sales.vue";

// steps
import ezBuilderStepWebinarRegistration from '../steps/webinar-registration.vue';
import ezBuilderStepOptinThankYou from '../steps/optin-thank-you.vue';
import ezBuilderStepWebinar from '../steps/webinar.vue';

export default {
  name: 'EzfBuilderFreeWebinarAffiliate',
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  components: {
    ezBuilderGroupFunnelCore,
    // ezBuilderGroupSales,
    ezBuilderStepWebinarRegistration,
    ezBuilderStepOptinThankYou,
    ezBuilderStepWebinar,
  },
};
</script>

<template>
  <div>
    <template>
      <ez-builder-group-funnel-core>
        <ez-builder-step-webinar-registration />
        <ez-builder-step-optin-thank-you />
        <ez-builder-step-webinar />
      </ez-builder-group-funnel-core>
    </template>
  </div>
</template>
