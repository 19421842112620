import { getStepTypeByNum } from '@/enums/stepTypes';

export default function stepFactory(type) {
  return {
    inject: ['isBuilder'],
    props: {
      byType: {
        type: Number,
        default: type,
      },
    },
    computed: {
      type() {
        return this.byType;
      },
      index() {
        return this.$store.state.builder.steps.findIndex(
          (step) => Number(step.attributes.type) === this.type,
        );
      },
      step() {
        if (this.index === -1) {
          const { title } = getStepTypeByNum(this.type);
          throw new Error(`step type ${this.type} (${title}) is not exists`);
        }
        return this.$store.state.builder.steps[this.index];
      },
    },
  };
}
