<script>
import settingsSectionFactory from '../../mixins/settings-section-factory';

export default {
  name: 'EzfSettingsSectionTrackingSettings',
  mixins: [
    settingsSectionFactory(
      'isDsgvo',
      'dsgvoIsSimple',
      'headerAlways',
      'headerStatistics',
      'headerGeneral',
      'headerMarketing',
      'footerAlways',
      'footerStatistics',
      'footerGeneral',
      'footerMarketing',
    ),
  ],
  computed: {
    isAdvanced() {
      return !this.dsgvoIsSimple;
    },
    attrs() {
      // attrs to save / discard depend on isAdvanced
      const result = [
        'isDsgvo',
        'dsgvoIsSimple',
        'headerAlways',
        'headerGeneral',
        'footerAlways',
        'footerGeneral',
      ];
      if (this.isAdvanced) {
        result.push(
          'headerStatistics',
          'headerMarketing',
          'footerStatistics',
          'footerMarketing',
        );
      }
      return result;
    },
  },
};
</script>

<template>
  <ez-settings-fieldset
    :legend="$t('settings.trackingSettings')"
    :is-changed="isChanged"
    @discard="discard"
    @save="save"
  >
    <ez-row :gutter="16">
      <ez-col>
        <div class="panel-collapse">
          <div class="panel-collapse__content">
            <ez-collapse v-model="isDsgvo" :time="0.3">
              <template slot="header">
                <ez-row align="start">
                  <ez-col>
                    <div class="panel-collapse__info">
                      <div class="panel-collapse__title">
                        {{ $t("settings.GDPR.title") }}
                      </div>
                      <div class="panel-collapse__description">
                        <template v-if="!isDsgvo">
                          {{ $t("settings.GDPR.off.description") }}
                        </template>
                        <template v-else-if="dsgvoIsSimple">
                          {{ $t("settings.GDPR.simple.description") }}
                        </template>
                        <template v-else>
                          {{ $t("settings.GDPR.advanced.description") }}
                        </template>
                      </div>
                    </div>
                  </ez-col>
                  <ez-col size="7">
                    <div class="panel-collapse__switch">
                      <ez-switch
                        v-model="isDsgvo"
                        inactive-color="#ff4949"
                        :width="50"
                      ></ez-switch>
                    </div>
                  </ez-col>
                </ez-row>
              </template>
              <template slot="content">
                <ez-row class="mb-4">
                  <ez-col size="auto" class="mr-4">
                    <ez-radio v-model="dsgvoIsSimple" :value="true">{{
                      $t("settings.GDPR.simple.label")
                    }}</ez-radio>
                  </ez-col>
                  <ez-col size="auto">
                    <ez-radio v-model="dsgvoIsSimple" :value="false">{{
                      $t("settings.GDPR.advanced.label")
                    }}</ez-radio>
                  </ez-col>
                </ez-row>

                <ez-row class="mb-4">
                  <ez-col>
                    <div class="group-title">
                      {{ $t("settings.headerAnalytics") }}
                    </div>
                  </ez-col>
                </ez-row>

                <ez-row class="mb-4" :gutter="16">
                  <ez-col>
                    <ez-form-item :label="$t('settings.always')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="headerAlways"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                  <ez-col>
                    <ez-form-item :label="$t('settings.generalAfterAgree')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="headerGeneral"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                </ez-row>
                <ez-row :gutter="16" class="mb-4" v-if="isAdvanced">
                  <ez-col>
                    <ez-form-item :label="$t('settings.statisticsAfterAgree')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="headerStatistics"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                  <ez-col>
                    <ez-form-item :label="$t('settings.marketingAfterAgree')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="headerMarketing"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                </ez-row>

                <ez-row class="mb-4">
                  <ez-col>
                    <div class="group-title">
                      {{ $t("settings.footerAnalytics") }}
                    </div>
                  </ez-col>
                </ez-row>

                <ez-row class="mb-4" :gutter="16">
                  <ez-col>
                    <ez-form-item :label="$t('settings.always')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="footerAlways"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                  <ez-col>
                    <ez-form-item :label="$t('settings.generalAfterAgree')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="footerGeneral"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                </ez-row>
                <ez-row :gutter="16" class="mb-4" v-if="isAdvanced">
                  <ez-col>
                    <ez-form-item :label="$t('settings.statisticsAfterAgree')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="footerStatistics"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                  <ez-col>
                    <ez-form-item :label="$t('settings.marketingAfterAgree')">
                      <ez-textarea
                        :placeholder="$t('settings.trackingCodePlaceholder')"
                        v-model="footerMarketing"
                      ></ez-textarea>
                    </ez-form-item>
                  </ez-col>
                </ez-row>
              </template>
            </ez-collapse>
            <template v-if="!isDsgvo">
              <br />
              <ez-row gutter="20" justify="between">
                <ez-col size="12">
                  <ez-form-item :label="$t('Header')">
                    <ez-textarea
                      v-model="headerAlways"
                      :placeholder="$t('Enter tracking code')"
                    />
                  </ez-form-item>
                </ez-col>
                <ez-col size="12">
                  <ez-form-item :label="$t('Footer')">
                    <ez-textarea
                      v-model="footerAlways"
                      :placeholder="$t('Enter tracking code')"
                    />
                  </ez-form-item>
                </ez-col>
              </ez-row>
            </template>
          </div>
        </div>
      </ez-col>
    </ez-row>
  </ez-settings-fieldset>
</template>

<style scoped>
.panel-collapse__switch {
  text-align: right;
}
</style>
