<script>
import { mapActions } from 'vuex';
import { RESTORE_VARIATION } from '@/store/builder/actions';
import { VARIATIONS_LIMIT } from '@/enums/variations';
import props from '../items/page/page-props';
import ezfBuilderArchiveVariation from './variation.vue';

export default {
  name: 'ezfBuilderArchiveStep',
  components: { ezfBuilderArchiveVariation },
  props,
  computed: {
    restorable() {
      return this.step.variations.length < VARIATIONS_LIMIT;
    },
    stepType() {
      return this.step.attributes.type || 0;
    },
  },
  methods: {
    ...mapActions('builder', { restore: RESTORE_VARIATION }),
  },
};
</script>

<template>
  <div class="builder-archive__col">
    <ezf-builder-archive-variation
      v-for="(variation, index) in step.archive"
      :key="`item-${index}`"
      :variation="variation"
      :index="index"
      :stepType="stepType"
      :restorable="restorable"
      @restore="restore({ step, variation, index })"
    />
  </div>
</template>
