<script>
// groups
import { OPTIN_THANK_YOU } from '@/enums/stepTypes';
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';
import ezBuilderGroupThankYou from '../groups/thank-you.vue';

// steps
import ezBuilderStepThankYou from '../steps/thank-you.vue';
import ezBuilderStepCanditatureform from '../steps/canditatureform.vue';

export default {
  name: 'EzfBuilderOptinApplicationform',
  data() {
    return {
      OPTIN_THANK_YOU,
    };
  },
  components: {
    ezBuilderGroupFunnelCore,
    ezBuilderGroupThankYou,
    ezBuilderStepCanditatureform,
    ezBuilderStepThankYou,
  },
};
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-builder-step-canditatureform />
    </ez-builder-group-funnel-core>

    <ez-builder-group-thank-you>
      <ez-builder-step-thank-you :by-type="OPTIN_THANK_YOU" />
    </ez-builder-group-thank-you>
  </div>
</template>
