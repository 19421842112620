export const WELCOME = 1;
export const PRE_WEBINAR = 2;
export const LAST_MINUTE = 3;
export const POST_WEBINAR = 4;
export const LAST_CONNECTION = 5;

const TYPES = {
  WELCOME,
  PRE_WEBINAR,
  LAST_MINUTE,
  POST_WEBINAR,
  LAST_CONNECTION,
};

export default TYPES;
