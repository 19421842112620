<script>
import { mapState } from 'vuex';
import { hide } from '@/services/modal';
import builderManager from '@/services/builderManager';
import templatePreview from '@/helpers/templatePreview';
import EzfChangeTemplateButton from '@/components/change-template-button.vue';
import EzfEmailTemplateSelect from '@/components/select-template/email-template-select.vue';
import { CONFIRMATION as emailType } from '@/enums/emailTypes';
import { getBuilderUrl } from '@/services/urls';
import NAME from './name.json';

export default {
  name: 'EzfConfirmationMailDialog',
  inject: ['$validator'],
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    EzfEmailTemplateSelect,
    EzfChangeTemplateButton,
  },
  props: {
    subject: {
      type: String,
      default: '',
    },
    template: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    const { subject, template } = this;
    return {
      emailType,
      templateImage: null,
      changeTemplate: false,
      form: {
        subject,
        template,
      },
      saving: false,
    };
  },
  computed: {
    ...mapState(['currentUserId']),
    ...mapState('funnel', ['funnel']),
    editTemplateHref() {
      return getBuilderUrl(this.funnel.id, this.template);
    },
    templateStyle() {
      return (
        this.templateImage && {
          'background-image': `url(${this.templateImage})`,
          'background-size': 'cover',
          'background-position': 'center top',
          'background-repeat': 'no-repeat',
        }
      );
    },
  },
  methods: {
    close() {
      hide(NAME);
    },
    async submit() {
      const valid = await this.$validator.validate();
      if (valid) {
        try {
          this.saving = true;
          await this.callback(this.form);
          hide(NAME);
          const { subject } = this.form;
          this.$notify({
            data: {
              type: 'success',
              content: this.$t('builder.notification.notify.updated', { subject }),
            },
          });
        } catch (e) {
          this.$notify({
            data: {
              type: 'error',
              content: e.message || e,
            },
          });
        } finally {
          this.saving = false;
        }
      }
    },
  },
  watch: {
    template: {
      async handler(id) {
        this.templateImage = null;
        if (id) {
          const result = await builderManager.getTemplatesByIds([id]);
          for (const template of result.data) {
            if (id === template.id) {
              this.templateImage = templatePreview(
                template.attributes,
                500,
                template.attributes && template.attributes['dt-update'],
                this.funnel && this.funnel.locale,
              );
            }
          }
        } else {
          this.changeTemplate = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-form @submit.prevent="submit">
    <ez-dialog-header>
      {{ $t("builder.confirmationMail.dialogTitle") }}
    </ez-dialog-header>

    <ez-dialog-body @close="close">
      <ez-form-item
        :label="$t('builder.confirmationMail.subject.label')"
        :type="errors.has('m-subject') ? 'error' : null"
        :message="errors.first('m-subject')"
      >
        <ez-input
          name="m-subject"
          :placeholder="$t('builder.confirmationMail.subject.placeholder')"
          v-model="form.subject"
          v-validate="'required|min:2'"
          data-test="subject"
          :data-vv-as="$t('builder.confirmationMail.subject.label')"
        ></ez-input>
      </ez-form-item>
      <ez-form-item
        :label="$t('builder.confirmationMail.template.label')"
        :type="errors.has('m-template-id') ? 'error' : null"
        :message="errors.first('m-template-id')"
      >
        <template v-if="!changeTemplate">
          <div class="add-email__change-template" :style="templateStyle">
            <a
              :href="editTemplateHref"
              target="_blank"
              style="text-decoration: none"
            >
              <ez-button data-test="edit-template">
                {{ $t("builder.buttons.editTemplate") }}
              </ez-button>
            </a>
          </div>
          <ezf-change-template-button
            data-test="change-template"
            @click.prevent="changeTemplate = true"
          />
        </template>
        <ezf-email-template-select
          v-else
          name="m-template-id"
          v-model="form['template']"
          v-validate="'required'"
          :type="emailType"
          :data-vv-as="$t('builder.confirmationMail.template.label')"
        />
      </ez-form-item>
    </ez-dialog-body>

    <ez-dialog-footer>
      <ez-button-group justify="end">
        <ez-button
          type="secondary"
          :disabled="saving"
          native-type="button"
          data-test="discard"
          @click="close"
        >
          {{ $t("builder.buttons.discard") }}
        </ez-button>
        <ez-button
          type="success"
          :disabled="saving"
          :preloader="saving"
          data-test="save-changes"
          native-type="submit"
          @click.prevent="submit"
        >
          {{ $t("builder.buttons.saveChanges") }}
        </ez-button>
      </ez-button-group>
    </ez-dialog-footer>
  </ez-form>
</template>
