<script>
import { createNamespacedHelpers } from 'vuex';
import EzMessage from '@dmant/ez-ui-components/src/components/message';
import { INIT } from '@/store/builder/actions';

const { mapActions } = createNamespacedHelpers('builder');

export default {
  name: 'EzfBuilderLoadingSteps',
  inject: ['stepComponent'],
  data() {
    return { error: null };
  },
  components: {
    EzMessage,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions([INIT]),
    async load() {
      const { funnel, stepComponent } = this;
      try {
        await this[INIT]({
          funnel,
          stepComponent,
          init: this.$route.path,
        });
        // eslint-disable-next-line no-unused-expressions
        this.$listeners.loaded?.();
      } catch (error) {
        this.error = error.message || error.toString();
        if (error.response) {
          /* eslint-disable no-console */
          console.error('error.response', error.response);
          console.error(error.response.status);
          /* eslint-enable no-console */
        } else {
          console.error(error); // eslint-disable-line no-console
        }
      }
    },
  },
  created() {
    this.load();
  },
};
</script>

<template>
  <ez-row>
    <ez-col v-if="error">
      <ez-message type="error" :text="error" />
    </ez-col>
    <ez-preloader v-else :value="true" :stop="false" />
  </ez-row>
</template>
