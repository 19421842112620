<script>
// groups
import ezBuilderGroupFunnelCore from '../groups/funnel-core.vue';

// steps
import ezStepSales from '../steps/sales.vue';

export default {
  name: 'EzfBuilderSalespageAffiliate',
  components: {
    ezBuilderGroupFunnelCore,
    ezStepSales,
  },
};
</script>

<template>
  <div>
    <ez-builder-group-funnel-core>
      <ez-step-sales />
    </ez-builder-group-funnel-core>
  </div>
</template>
