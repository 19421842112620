<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import CloudPopUp from '@dmant/ez-cloud-common';
import ezSelectDomain from '@/components/select-domain.vue';
import ezSelectGroup from '@/components/select-group.vue';
import localesList from '@/mixins/localesList';
import cloudManager from '@/services/cloudManager';
import EditGroupDialog from '@/components/dialogs/changeGroup';
import * as funnelsActions from '@/store/funnels/actions';
import settingsSectionFactory from '../../mixins/settings-section-factory';

const { mapActions } = createNamespacedHelpers(
  'funnels',
);
export default {
  name: 'EzfSettingsSectionBasicInformation',
  components: {
    ezSelectDomain,
    ezSelectGroup,
    CloudPopUp,
  },
  mixins: [
    settingsSectionFactory('name', 'slug', 'locale', 'groupId', 'domainId', 'favicon'),
    localesList,
  ],
  data() {
    return {
      faviconUrl: '',
      selectFileLoader: false,
      isAddGroup: false,
    };
  },
  computed: {
    localeEx: {
      get() {
        return this.locale ? this.locale : this.mainUserLocale;
      },
      set(newLocale) {
        this.locale = newLocale;
      },
    },
    ...mapState('funnel', ['user']),
  },
  methods: {
    ...mapActions({
      createGroup: funnelsActions.CREATE_GROUP,
    }),
    async selectFile() {
      const { cloud } = this.$refs;
      cloud.toggleOpen({
        userId: this.$store.state.currentUserId,
        allowTypes: 'image',
      }, (data) => {
        if (data && data.previews.length) {
          const [preview] = data.previews;
          this.faviconUrl = preview.url;
          this.favicon = data.id;
        }
      });
    },
    async onAddGroup() {
      try {
        const result = await EditGroupDialog();
        if (result) {
          this.groupId = await this.createGroup(result);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    favicon: {
      async handler(fileUUID) {
        if (!fileUUID || this.faviconUrl) {
          return;
        }
        const { data } = await cloudManager.getFile(fileUUID);
        const { previews } = data.attributes;
        const p240 = previews.find((ff) => ff.resolution === '240');
        this.faviconUrl = p240 ? p240.url : previews[0] && previews[0].url;
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-settings-fieldset
    :legend="$t('settings.basicInformation')"
    :is-changed="isChanged"
    :is-saving="isSaving"
    @discard="discard"
    @save="save"
  >
    <ez-row :gutter="16">
      <ez-col>
        <ez-form-item :label="$t('settings.funnelName')">
          <ez-input
            :placeholder="$t('settings.funnelNamePlaceholder')"
            v-model="name"
            data-test="name"
          ></ez-input>
        </ez-form-item>

        <ez-select-domain v-model="domainId" :user="user" />

        <ez-form-item label="Favicon">
          <template v-if="favicon">
            <div
              v-if="faviconUrl"
              alt=""
              class="favicon"
              :style="{ backgroundImage: `url(${faviconUrl})` }"
            />
            <ez-link class="input-clean-link" @click.prevent="favicon = null">{{
              $t("settings.deleteFile")
            }}</ez-link>
          </template>
          <ez-input
            v-else
            :placeholder="$t('settings.selectFavicon')"
            type="text"
            icon="image"
            :disabled="selectFileLoader"
            :value="favicon"
            @click.prevent="selectFile"
            @focus.prevent="selectFile"
          />
        </ez-form-item>
      </ez-col>
      <ez-col>
        <div class="mb-3">
          <ez-select-group
            v-model="groupId"
            class="mb-1"
          />

          <a
            class="form-field-link text-right"
            @click="onAddGroup"
          >
            {{ $t("settings.addGroup") }}
          </a>
        </div>

        <ez-form-item :label="$t('settings.slug')">
          <ez-input
            :placeholder="$t('settings.slugPlaceholder')"
            v-model="slug"
            data-test="slug"
          ></ez-input>
        </ez-form-item>

        <ez-form-item :label="$t('settings.locale')">
          <ez-select
            v-model="localeEx"
            :options="localesList"
            :is-single="true"
            item-label="label"
            track-by="value"
            :allow-empty="false"
            :searchable="false"
          />
        </ez-form-item>
      </ez-col>
    </ez-row>
    <cloud-pop-up ref="cloud"/>
  </ez-settings-fieldset>
</template>

<style lang="scss" scoped>
  .favicon {
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .form-field-link {
    padding-top: 4px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
</style>
